import React from "react";
import styled, { css } from "styled-components";
import { IInputField } from "./elementTypes";

export const InputBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background: white;
  min-height: 35px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
`;

export const FormDropdownBody = styled.select`
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
`;

export const FormDropdownItem = styled.option`
  width: 100%;
  height: 25px;
`;

/*+export const InputHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`*/

export const QuickSearchBody = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.7rem;
  background: white;
  min-height: 20px;
  border-radius: 25px;
  border: ${({ theme }) => theme.color.mainText} solid 2px;
`;

export const InputField = styled.input<IInputField>(
  ({ theme }) => css`
    border: none;
    box-shadow: none;
    font-family: "OpenSans-Light";
    font-size: 1.25em;
    color: ${theme.color.mainText};
  `
);

export const FooterMessageBody = styled.div(
  ({ theme }) => css`
    min-height: 40px;
    border: solid 2px ${theme.color.midMute};
    background: ${theme.color.mainText};
    padding: 0.5rem;
  `
);
