import React, { SyntheticEvent, useState } from "react";
import { ModuleFrame as CardBase } from "../../elements";
import {
  SummaryParagraph,
  CardLock,
  StatList,
  isModuleType,
  CardControls,
  AnnotatedSlider,
  IInteractiveCard,
  IStat,
  RequireBadgeOverlay,
} from "../../components";
import { ModuleCardHeader as CardHeader } from "../../components";
import {
  createRenderableDateStats,
  createRenderableStatArr,
} from "../../../boilerplate/utils/staticHelpers";

export const ModuleCard = (props: IInteractiveCard) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleArrowClick = (incrementBy: number): void => {
    if (!props.isLocked) {
      setCurrentPage(currentPage + incrementBy);
    }
  };

  // creation of stat arrays that will be mapped to renderable react elements
  const baseStatsArr: IStat[] =
    props.statData === undefined
      ? []
      : isModuleType(props.statData)
      ? createRenderableDateStats(props.dateStarted, props.dateFinished)
      : createRenderableDateStats(props.dateStarted, props.dateFinished).map(
          (stat) => {
            const iconlessStat = { ...stat };
            iconlessStat.icon = undefined;
            return iconlessStat;
          }
        );
  const dynamicStatsArr: IStat[] =
    props.statData === undefined
      ? []
      : isModuleType(props.statData)
      ? createRenderableStatArr(props.statData)
      : createRenderableStatArr(props.statData).map((stat) => {
          const iconlessStat = { ...stat };
          iconlessStat.icon = undefined;
          return iconlessStat;
        });

  return (
    <CardBase>
      {props.isRequired ? <RequireBadgeOverlay /> : null}
      {props.isLocked === true ? (
        <CardLock
          lockMessage={
            props.lockedMessage !== undefined
              ? props.lockedMessage
              : "This Content is Locked"
          }
        />
      ) : null}
      {currentPage >= 2 && props.statData !== undefined ? (
        <div style={{ padding: "1.5rem" }}>
          <h3 style={{ marginBottom: "1rem" }}>
            Your {isModuleType(props.statData) ? "Module" : "Section"} Stats
          </h3>
          {isModuleType(props.statData) ? (
            <>
              <StatList statsArr={[...baseStatsArr, ...dynamicStatsArr]} />
            </>
          ) : (
            <>
              <div style={{ marginBottom: "1rem" }}>
                <AnnotatedSlider
                  progressPercent={props.statData.sectionProgress}
                  sliderName={"Section Progress"}
                  style={{ marginBottom: "1rem" }}
                />
                <AnnotatedSlider
                  progressPercent={props.statData.averageQuizScore}
                  sliderName={"Avg. Quiz Score"}
                  style={{}}
                />
              </div>
              <StatList statsArr={[...baseStatsArr, ...dynamicStatsArr]} />
            </>
          )}
        </div>
      ) : (
        <>
          <CardHeader title={props.title} subTitle={props.subTitle} />
          <div style={{ padding: "1.5rem" }}>
            <SummaryParagraph
              style={{ overflowWrap: "anywhere" }}
              color={"dark"}
              title={"Summary"}
              description={props.summary}
            />
          </div>
        </>
      )}
      <CardControls
        pageCount={props.statData ? 2 : 0}
        currentPage={currentPage}
        clickLeft={(e: SyntheticEvent) => handleArrowClick(-1)}
        clickRight={(e: SyntheticEvent) => handleArrowClick(1)}
        buttonData={{ ...props.buttonData }}
      />
    </CardBase>
  );
};
