import {
  BannerBody,
  OrganizationIcon,
  ProfileAside,
  ProfileMain,
  ProfileStats,
  SmallTitle,
  SolidBoxButton,
} from "../../elements";
import { IOrgProfileOverview } from "./types";
// @ts-ignore
import React, { CSSProperties, SyntheticEvent } from "react";
import { StandardParagraph } from "../../components/misc";
import { TLDRCard } from "../cards";
import { ImageCollection } from "../../components/lists";

export const ProfileOverview = (
  props: IOrgProfileOverview & { style?: CSSProperties }
) => (
  <BannerBody
    style={{
      ...props.style,
      height: "300px",
      justifyContent: "space-between",
      alignItems: "stretch",
    }}
  >
    <ProfileMain>
      <OrganizationIcon src={props.iconURL} style={{ marginRight: "2rem" }} />
      <div>
        <StandardParagraph
          title={props.title}
          description={props.description}
          style={{ color: "white" }}
        />
        <SolidBoxButton
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            props.onMembershipSubscribe();
          }}
          to={""}
        >
          {props.buttonText}
        </SolidBoxButton>
      </div>
    </ProfileMain>
    <ProfileAside>
      <SmallTitle color={"light"}>Members</SmallTitle>
      <ImageCollection
        items={props.members}
        textColor={"light"}
        style={{ padding: 0 }}
      />
      <ProfileStats>
        <TLDRCard {...props.stats} />
      </ProfileStats>
    </ProfileAside>
  </BannerBody>
);
