import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore-types";
import { EntryCollection } from "contentful";
import lodash from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  contentFiltersIds,
  ContentfulHelper,
  GenericThunkSearchFilterParams,
  IFirebaseHelper,
  IOrganization,
  ISectionTagFields,
  OrganizationSortPayload,
  RootState,
  sortIds,
} from "./internal";

const parseOrganizations = (
  orgDocs: QueryDocumentSnapshot<DocumentData>[]
): IOrganization[] =>
  orgDocs.map((doc) => ({ ...doc.data(), id: doc.id } as IOrganization));

const applyGeneralSort = (
  sortID: string,
  organizations: IOrganization[]
): IOrganization[] => {
  const { POPULAR, OLDEST, NEWEST, HIGHEST_RATED } = sortIds;
  if (sortID === POPULAR) {
    // order based on member count
    return [...organizations].sort(
      (itemOne, itemTwo) => itemTwo.members.length - itemOne.members.length
    );
  } else if (sortID === OLDEST) {
    // order based on join date (lowest values first)
    return [...organizations].sort(
      (itemOne, itemTwo) =>
        (itemOne.joinDate as any).seconds * 1000 -
        (itemTwo.joinDate as any).seconds * 1000
    );
  } else if (sortID === NEWEST) {
    // order based on join date (highest values first)
    return [...organizations].sort(
      (itemOne, itemTwo) =>
        (itemTwo.joinDate as any).seconds * 1000 -
        (itemOne.joinDate as any).seconds * 1000
    );
  } else if (sortID === HIGHEST_RATED) {
    // todo: implement highest rated sort
    return organizations;
  } else {
    return organizations;
  }
};

const applyGeneralFilter = (
  filterID: string,
  organizations: IOrganization[],
  userMemberships: string[]
): IOrganization[] => {
  const { MY_ORGANIZATIONS } = contentFiltersIds;
  if (filterID === MY_ORGANIZATIONS) {
    return [...organizations].sort((itemOne, itemTwo) => {
      // loop through user memberships
      for (const memebershipID of userMemberships) {
        // return -1 if item two includes the user's membership ID
        // item two will be swapped with item one
        if (itemTwo.members.includes(memebershipID)) return -1;
      }
      return 1;
    });
  }
  return organizations;
};

const searchOrganizations = async (
  firebaseHelper: IFirebaseHelper,
  searchTerm: string,
  startAt: number,
  itemLimit: number
): Promise<IOrganization[]> => {
  // get profile docs by name
  const organizationQuery = await firebaseHelper
    .organizations()
    .orderBy("name")
    .startAt(startAt)
    .limit(itemLimit)
    //.where("name", "==", searchTerm)
    .get();
  const parsedOrgs = parseOrganizations(organizationQuery.docs || []);
  const tagTasks: Promise<EntryCollection<ISectionTagFields>>[] = [];
  for (const org of parsedOrgs) {
    // fetch tags foreach org
    const client = new ContentfulHelper(
      org.externalSpaceID,
      process.env.REACT_APP_CDA_TOKEN || ""
    );
    tagTasks.push(client.sectionTags());
  }
  const parsedTags = await Promise.all(tagTasks);
  // filter based on name and/or tag
  const foundOrg: IOrganization[] = [];
  parsedOrgs.forEach((org, index) => {
    let matchesCriteria = false;
    // search on name
    for (const word of searchTerm.split(" ")) {
      if (org.name.toUpperCase().includes(word.toUpperCase()))
        matchesCriteria = true;
    }
    // continue to search on tags if no name matches
    if (!matchesCriteria) {
      for (const tag of parsedTags[index].items) {
        for (const word of searchTerm.split(" ")) {
          if (tag.fields.tagline.toUpperCase().includes(word.toUpperCase()))
            matchesCriteria = true;
        }
      }
    }
    if (matchesCriteria) foundOrg.push(org);
  });
  return foundOrg;
};

export const sortOrganizations = createAsyncThunk(
  "organizationsSlice/sortOrganizations",
  async (
    params: { firebaseHelper: IFirebaseHelper; sortId: string },
    thunkAPI
  ) => {
    const {
      organizations,
    } = (thunkAPI.getState() as RootState).organizationSearch;
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { memberships } = userData;
    // sort organizations
    const sortedOrganizations = applyGeneralSort(
      params.sortId,
      organizations
      //(memberships || []).map((item) => item.id)
    );
    return {
      sortId: params.sortId,
      sortedOrganizations,
    } as OrganizationSortPayload;
  }
);

export const filterOrganizations = createAsyncThunk(
  "organizationsSlice/filterOrganizations",
  async (
    params: { firebaseHelper: IFirebaseHelper; filterId: string },
    thunkAPI
  ) => {
    const {
      organizations,
    } = (thunkAPI.getState() as RootState).organizationSearch;
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { memberships } = userData;
    // filter and return
    const filteredOrganizations = applyGeneralFilter(
      params.filterId,
      organizations,
      (memberships || []).map((member) => member.id)
    );
    return {
      filterId: params.filterId,
      filteredOrganizations,
    };
  }
);

export const fetchOrganizations = createAsyncThunk(
  "organizationsSlice/fetchOrganizationsByMembership",
  async (firebaseHelper: IFirebaseHelper, thunkAPI) => {
    // get memberships from user
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { memberships } = userData;
    const {
      itemSortID,
      itemFilterID,
    } = (thunkAPI.getState() as RootState).organizationSearch;
    let organizations: IOrganization[] = [];
    if (memberships !== undefined) {
      // chunk memberships into array of sub-arrays with 10 items (due to limitations of firestore 'in' query)
      // loop through the user memberships, fetch the corresponding profile of each
      const chunkedMemberships = lodash.chunk(memberships, 10);
      for (const memberships of chunkedMemberships) {
        try {
          // compile member ids into array
          const memberIDs: string[] = memberships.map(
            (membership) => membership.id
          );
          // filter organizations that contains memberIDs
          const organizationDocs = await firebaseHelper
            .organizations()
            .where("members", "array-contains-any", memberIDs)
            .get();
          if (
            organizationDocs !== undefined &&
            organizationDocs.docs.length > 0
          ) {
            // sort and filter organizations
            // append to organizations array
            const sortedOrganizations = applyGeneralSort(
              itemSortID,
              parseOrganizations(organizationDocs.docs)
            );
            const filteredOrganizations = applyGeneralFilter(
              itemFilterID,
              sortedOrganizations,
              (memberships || []).map((member) => member.id)
            );
            organizations = organizations.concat(filteredOrganizations);
          }
        } catch (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
      return organizations;
    }
    return thunkAPI.rejectWithValue({
      error: "User memberships",
      message:
        "Unable to retrieve user memberships, ensure that user data is initialized",
    });
  }
);

export const fetchOrganizationsBySearch = createAsyncThunk(
  "organizationsSlice/fetchOrganizationsBySearch",
  async (params: GenericThunkSearchFilterParams, thunkAPI) => {
    // parse user memberships and filterID/firebase from params
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { memberships } = userData;
    const {
      searchTerm,
      itemSortID,
      itemFilterID,
    } = (thunkAPI.getState() as RootState).organizationSearch;
    const { firebaseHelper } = params;
    if (searchTerm === "")
      return thunkAPI.rejectWithValue({
        error: "Search Term",
        message: "Cannot be empty",
      });
    try {
      // search organizations
      const fetchedOrganizations = await searchOrganizations(
        firebaseHelper,
        searchTerm,
        0,
        50
      );
      // apply sort to organizations
      const sortedOrganizations = applyGeneralSort(
        itemSortID,
        fetchedOrganizations
      );
      const filteredOrganizations = applyGeneralFilter(
        itemFilterID,
        sortedOrganizations,
        (memberships || []).map((item) => item.id)
      );
      return {
        itemLimit: 50,
        limitReached: fetchedOrganizations.length < 50,
        filteredOrganizations,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const loadMoreSearchedOrganizations = createAsyncThunk(
  "organizationsSlice/loadMoreOrganizations",
  async (firebaseHelper: IFirebaseHelper, thunkAPI) => {
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { memberships } = userData;
    const {
      itemLimit: oldLimit,
      organizations: oldOrgs,
      searchTerm,
      itemSortID,
      itemFilterID,
    } = (thunkAPI.getState() as RootState).organizationSearch;
    // increase item limit
    const newItemLimit = oldLimit + 50;
    try {
      // search organizations
      const organizations = await searchOrganizations(
        firebaseHelper,
        searchTerm,
        oldLimit + 1,
        newItemLimit
      );
      // apply sort to organizations
      const sortedOrganziations = applyGeneralSort(itemSortID, organizations);
      const filteredOrganizations = applyGeneralFilter(
        itemFilterID,
        sortedOrganziations,
        (memberships || []).map((item) => item.id)
      );
      return {
        // append to existing state
        organizationSearch: oldOrgs.concat(filteredOrganizations),
        newItemLimit,
        // item limit is reached if another search if performed and no extra results are returned
        limitReached: organizations.length === 0,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: "Organization query",
        message: "Error fetching and filtering organizations",
      });
    }
  }
);
