import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/setup";
import { WorkspaceSlice } from "../redux/reduxTypes";
import { IContentfulHelper } from "../config";
import { ContentfulHelper } from "../clients";

const ContentfulContext = createContext<IContentfulHelper | null>(null);

export default (props: { children: ReactNode }) => {
  const [client, setClient] = useState<IContentfulHelper | null>(null);
  const workspace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;

  useEffect(() => {
    const { REACT_APP_CDA_TOKEN } = process.env;
    (async () => {
      if (workspace !== null) {
        const { organization } = workspace;
        // create client and initialize
        const client = new ContentfulHelper(
          organization.externalSpaceID,
          REACT_APP_CDA_TOKEN || ""
        );
        await client.init();
        setClient(client);
      } else if (client !== null)
        // remove client if workspace was cleared
        setClient(null);
    })();
  }, [workspace]);

  return (
    <ContentfulContext.Provider value={client}>
      {props.children}
    </ContentfulContext.Provider>
  );
};

export const useContentful = () => useContext(ContentfulContext);
