import React, { SyntheticEvent } from "react";
import {
  LightBoxButton,
  PageContainer,
  RedirectFrame,
  RedirectText,
} from "../../elements";
import { IInviteCard } from "../types";

export const InviteCard = (props: IInviteCard) => (
  <PageContainer>
    <RedirectFrame>
      <h2> {props.title} </h2>
      <p style={{ marginBottom: "2rem" }}>
        {props.subHeader}{" "}
        <RedirectText to={props.redirect.redirectRoute}>
          {props.redirect.text}
        </RedirectText>{" "}
      </p>
      <LightBoxButton
        onClick={(e: SyntheticEvent) => {
          props.onSubmit();
        }}
        to={""}
      >
        {props.buttonText}
      </LightBoxButton>
    </RedirectFrame>
  </PageContainer>
);
