import { IFirebaseHelper, IMember } from "../config/types/firebaseTypes";
import { IContentfulHelper } from "../config/types/contentfulTypes";
import {
  fetchQuizResponses,
  fetchQuizSessionsByExternalId,
  highestQuizScore,
  SectionStats,
} from "./index";
import { IModule, IQuiz, ISection } from "../../../@types/generated/contentful";
import { userPointsValues } from "../config/constants/userPoints";

export const getSectionsFromModules = (modules: IModule[]) =>
  modules.flatMap((module) => getSectionsFromModule(module));

export const getSectionsFromModule = (module: IModule): ISection[] =>
  module.fields.sections || [];

export const getNonQuizzableSections = (sections: ISection[]): ISection[] =>
  sections.filter((section) => section.fields.quizzes === undefined);

export const getQuizzableSections = (sections: ISection[]): ISection[] =>
  sections.filter((section) => section.fields.quizzes);

export const getRequiredSections = (sections: ISection[]): ISection[] =>
  sections.filter((section) => section.fields.isRequired);

export const getNonRequiredSections = (sections: ISection[]): ISection[] =>
  sections.filter((section) => !section.fields.isRequired);

export const calcUserPointsBySections = (sections: ISection[]): number => {
  // check if section is required and quizzes are available
  // get question count from each section
  // multiply question count by question point value
  if (sections.length <= 0) return 0;
  const questionCount = sections
    .map((section) => {
      if (section.fields.quizzes !== undefined)
        return (
          (section.fields.quizzes || [])[0].fields.quizQuestions.length *
          userPointsValues.CORRECT_QUESTION
        );
      else return userPointsValues.FINISHED_READING;
    })
    .reduce((prev, current) => prev + current, 0);
  return questionCount;
};

export const generateSectionStats = async (
  sectionId: string,
  firebaseHelper: IFirebaseHelper,
  contentfulClient: IContentfulHelper,
  sectionPosition: number
): Promise<SectionStats> => {
  // fetch section data from contentful
  const sectionContent = await contentfulClient.getSectionById(sectionId);
  // set stats
  const { time, units } = calcTimeToComplete([sectionContent]);
  return {
    isRequired: sectionContent.fields.isRequired,
    title: sectionContent.fields.sectionHeader,
    subTitle: `Section ${sectionPosition}`,
    summary: sectionContent.fields.contentSummary,
    totalTime: time,
    totalTimeUnits: units,
    isLocked: false,
  };
};

// returns a boolean flag representing lock status
export const isSectionComplete = async (
  contentfulSectionId: string,
  firebaseHelper: IFirebaseHelper,
  contentfulClient: IContentfulHelper
): Promise<boolean> => {
  // fetch previous section, retrieving associated quiz
  // fetch firestore quiz sessions related to quiz
  // return true if any of the quiz sessions have a passing grade
  const contentfulSection = await contentfulClient.getSectionById(
    contentfulSectionId
  );
  const quizSessions = await fetchQuizSessionsByExternalId(
    firebaseHelper,
    contentfulSection.sys.id
  );
  if (contentfulSection.fields.quizzes !== undefined) {
    // parse out quiz data
    const totalQuestions =
      contentfulSection.fields.quizzes[0].fields.quizQuestions.length;
    const passingGrade =
      contentfulSection.fields.quizzes[0].fields.passingGrade;
    // fetch responses and check if passed
    const quizResponses = await fetchQuizResponses(
      firebaseHelper,
      quizSessions
    );
    return highestQuizScore(quizResponses, totalQuestions) >= passingGrade;
  }
  return false;
};

export const calcTimeToComplete = (
  sections: ISection[]
  //timeUnits: "hrs" | "mins"
): { time: number; units: "hrs" | "mins" } => {
  // filter sections by quiz availability
  // determine time by question count and amount of sections
  const requiredSections = getRequiredSections(sections);
  const quizableSections = getQuizzableSections(requiredSections);
  const nonQuizableSections = getNonQuizzableSections(requiredSections);
  // average three minutes to complete each question
  // average 5 minutes per content block
  const quizTimeInMinutes = quizableSections.map((section) =>
    section.fields.quizzes
      ? section.fields.quizzes[0].fields.quizQuestions.length * 3
      : 0
  );
  const readingTimeInMinutes = requiredSections.map(
    (section) => section.fields.contentBlocks.length * 5
  );
  const totalTime =
    quizTimeInMinutes.reduce((prev, curr) => prev + curr, 0) +
    readingTimeInMinutes.reduce((prev, curr) => prev + curr, 0);
  return totalTime <= 90
    ? { time: totalTime, units: "mins" }
    : { time: totalTime / 60, units: "hrs" };
};
