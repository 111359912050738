import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  endQuiz,
  FetchError,
  fetchQuizData,
  ICompletionCertificate,
  IQuizSession,
  markResponse,
  QuizSlice,
  ResponsePayload,
} from "./internal";

const initialState: QuizSlice = null as QuizSlice;

const quizSlice = createSlice({
  name: "quizSlice",
  initialState,
  reducers: {
    // clear state
    clearState(state) {
      return null;
    },
    // question index
    setQuestionIndex(state, action: PayloadAction<number>) {
      if (state) {
        state.currentQuestionIndex = action.payload;
      }
    },
    incrementCurrentQuestion(state) {
      if (state) {
        const { currentQuestionIndex, totalQuestionCount } = state;
        state.currentQuestionIndex =
          currentQuestionIndex < totalQuestionCount - 1
            ? currentQuestionIndex + 1
            : currentQuestionIndex;
      }
    },
    decrementCurrentQuestion(state) {
      if (state) {
        const { currentQuestionIndex, totalQuestionCount } = state;
        state.currentQuestionIndex =
          currentQuestionIndex > 0
            ? currentQuestionIndex - 1
            : currentQuestionIndex;
      }
    },
    setQuizReview(state, action: PayloadAction<boolean>) {
      if (state) {
        state.inReview = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // mark response
      .addCase(
        markResponse.fulfilled.type,
        (state: QuizSlice, action: PayloadAction<ResponsePayload>) => {
          if (state && action.payload) {
            const { response, index } = action.payload;
            const { responses } = state;
            // set response
            // update session response id list
            responses[index] = response;
            state.responses = responses;
            state.session = {
              ...state.session,
              responses: [...state.session.responses, response.id].filter(
                (item, indx, arr) => arr.indexOf(item) === indx
              ),
            };
          }
        }
      )
      .addCase(
        markResponse.rejected.type,
        (state: QuizSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          // log error and reset state
          console.log(`Error ${error}: ${message}`);
          //return initialState;
        }
      )
      // end quiz
      .addCase(
        endQuiz.fulfilled.type,
        (
          state: QuizSlice,
          action: PayloadAction<{
            updatedSession: IQuizSession;
            updatedCertificate: ICompletionCertificate;
          }>
        ) => {
          const { updatedCertificate, updatedSession } = action.payload;
          if (state) {
            state.certificate = updatedCertificate;
            state.session = updatedSession;
          }
        }
      )
      .addCase(
        endQuiz.rejected.type,
        (state: QuizSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          // log error and reset state
          console.log(`Error ${error}: ${message}`);
          return initialState;
        }
      )
      // fetch quiz data
      .addCase(
        fetchQuizData.fulfilled.type,
        (state: QuizSlice, action: PayloadAction<QuizSlice>) => {
          return action.payload;
        }
      )
      .addCase(
        fetchQuizData.rejected.type,
        (state: QuizSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          // log error and reset state
          console.log(`Error ${error}: ${message}`);
          return initialState;
        }
      );
  },
});

const quizReducer = quizSlice.reducer;
const {
  clearState,
  setQuestionIndex,
  incrementCurrentQuestion,
  decrementCurrentQuestion,
  setQuizReview,
} = quizSlice.actions;

export {
  quizReducer,
  clearState,
  setQuestionIndex,
  incrementCurrentQuestion,
  decrementCurrentQuestion,
  setQuizReview,
};
