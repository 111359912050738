import { IMember, IRole } from "../../config/types/firebaseTypes";
import { useFirebaseContext } from "../../providers/FirebaseProvider";
import { useEffect, useState } from "react";
import { generateRoleStats, getRoleById } from "../../utils";
import { IRoleStats } from "../types";
import { useContentful } from "../../providers/ContentfulProvider";

export const useRole = (roleId: string): [IRole | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  // role state
  const [role, setRole] = useState<IRole | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    if (firebaseHelper !== null) {
      setLoadingStatus(true);
      // fetch role by id
      (async () => {
        const roleResult = await getRoleById(firebaseHelper, roleId);
        setRole(roleResult);
        setLoadingStatus(false);
      })();
    }
  }, [firebaseHelper, roleId]);

  return [role, isLoading];
};

export const useRoleStat = (
  role: IRole | null,
  membership: IMember | null
): [IRoleStats | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  const contentfulHelper = useContentful();
  // stats state
  const [roleStats, setRoleStats] = useState<IRoleStats | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    if (
      firebaseHelper !== null &&
      role !== null &&
      contentfulHelper !== null &&
      membership !== null
    ) {
      setLoadingStatus(true);
      (async () => {
        // fetch stats for role
        const stats = await generateRoleStats(
          contentfulHelper,
          firebaseHelper,
          membership,
          role
        );
        // set state
        setRoleStats(stats);
        setLoadingStatus(false);
      })();
    }
  }, [role, membership, firebaseHelper, contentfulHelper]);

  return [roleStats, isLoading];
};

export const useRoleStats = (
  roles: IRole[] | null,
  membership: IMember | null
): [IRoleStats[] | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  const contentfulHelper = useContentful();
  // stats state
  const [roleStats, setRoleStats] = useState<IRoleStats[] | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    let cancelTask = false;
    if (
      firebaseHelper !== null &&
      roles !== null &&
      contentfulHelper !== null &&
      membership !== null
    ) {
      setLoadingStatus(true);
      (async () => {
        // fetch stats for role
        const stats = await Promise.all(
          roles.map((role) =>
            generateRoleStats(
              contentfulHelper,
              firebaseHelper,
              membership,
              role
            )
          )
        );
        if (!cancelTask)
          setRoleStats(
            stats
              .filter((item) => item !== null)
              .map((item) => item as IRoleStats)
          );
        setLoadingStatus(false);
      })();
    }

    return () => {
      cancelTask = true;
    };
  }, [roles, membership, firebaseHelper, contentfulHelper]);

  return [roleStats, isLoading];
};

export const useRoles = (roleIds: string[]): [IRole[] | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  // role state
  const [roles, setRoles] = useState<IRole[] | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    let cancelOperation = false;
    let statePayload: IRole[] | null = null;
    if (firebaseHelper) {
      setLoadingStatus(true);
      // only filter/fetch roles if user membership data is available
      if (roleIds.length <= 0) {
        setLoadingStatus(false);
        setRoles(null);
      } else {
        (async () => {
          // fetch roles in firebase
          const roleDocs = await Promise.all(
            roleIds.map((id) => firebaseHelper.roles().doc(id).get())
          );
          statePayload = roleDocs.map(
            (doc) => ({ ...doc.data(), id: doc.id } as IRole)
          );
          if (!cancelOperation) {
            // set payload and loading to false
            setLoadingStatus(false);
            setRoles(statePayload);
          }
        })();
      }
    }

    return () => {
      cancelOperation = true;
    };
  }, [firebaseHelper, roleIds]);

  return [roles, isLoading];
};
