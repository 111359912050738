import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  calcPointsEarned,
  didChoiceChange,
  fetchCertificate,
  fetchQuizResponses,
  fetchQuizSession,
  ICompletionCertificate,
  IContentfulHelper,
  IFirebaseHelper,
  IQuizSession,
  IResponse,
  QuizSlice,
  ResponsePayload,
  RootState,
} from "./internal";

export const markResponse = createAsyncThunk(
  "quizSlice/markResponse",
  async (
    params: {
      contentfulHelper: IContentfulHelper;
      firebaseHelper: IFirebaseHelper;
      externalQuestionId: string;
      externalAnswerId: string;
      responseId?: string;
    },
    thunkAPI
  ) => {
    const {
      contentfulHelper,
      firebaseHelper,
      externalAnswerId,
      externalQuestionId,
      responseId,
    } = params;
    const quizState = (thunkAPI.getState() as RootState).quiz;
    if (quizState && !quizState.inReview) {
      // fetch contentful data
      const [answerData, questionData] = await Promise.all([
        contentfulHelper.getAnswerChoiceById(externalAnswerId),
        contentfulHelper.getQuestionById(externalQuestionId),
      ]);
      // check if response id exists in redux
      // if not, create new response
      const foundResponse = quizState?.responses.find(
        (item) => item.id === responseId
      );
      let response: IResponse | null = null;
      if (foundResponse) {
        const responseChange = didChoiceChange(
          quizState.currentQuestionIndex,
          quizState.responses,
          externalAnswerId
        );
        if (responseChange) {
          // update existing response
          const updatedResponse: IResponse = {
            ...foundResponse,
            responseDate: new Date(),
            isCorrect: answerData.fields.correctAnswer,
            answerText: answerData.fields.answer,
            externalAnswerId: answerData.sys.id,
          };
          await firebaseHelper
            .responses()
            .doc(foundResponse.id)
            .update(updatedResponse);
          // set response data
          response = updatedResponse;
        }
      } else {
        // begin new transaction
        const newResponseDoc = firebaseHelper.responses().doc();
        const sessionDoc = firebaseHelper
          .quizSessions()
          .doc(quizState.session.id);
        await firebaseHelper.firestore.runTransaction(async (transaction) => {
          // create response data
          const newResponse = {
            responseDate: new Date(),
            isCorrect: answerData.fields.correctAnswer,
            questionText: questionData.fields.question,
            answerText: answerData.fields.answer,
            externalQuestionId,
            externalAnswerId,
          } as IResponse;

          // parse out and update session data
          const { id, ...oldSessionData } = quizState.session;
          const sessionData = {
            ...oldSessionData,
            responses: [...oldSessionData.responses, newResponseDoc.id],
          } as IQuizSession;

          // set new response in firestore
          // update session
          await Promise.all([
            transaction.set(newResponseDoc, newResponse),
            transaction.update(sessionDoc, sessionData),
          ]);
          // set response data
          response = newResponse;
        });

        if (!response)
          thunkAPI.rejectWithValue({
            error: "Response Null",
            message: "Cannot mark response when null",
          });
        else {
          return {
            response: {
              ...(response as IResponse),
              id: newResponseDoc.id,
            },
            index: quizState.currentQuestionIndex,
          } as ResponsePayload;
        }
      }
    }
    thunkAPI.rejectWithValue({
      error: "Quiz State Null",
      message: "State cannot be null when marking a response",
    });
  }
);

export const endQuiz = createAsyncThunk(
  "quizSlice/endQuiz",
  async (
    params: {
      contentfulHelper: IContentfulHelper;
      firebaseHelper: IFirebaseHelper;
    },
    thunkAPI
  ) => {
    const { contentfulHelper, firebaseHelper } = params;
    const quizState = (thunkAPI.getState() as RootState).quiz;
    if (quizState) {
      // ensure all questions are answered
      if (quizState.totalQuestionCount === quizState.responses.length) {
        // object rest data
        const { id: certId, ...certificateData } = quizState.certificate;
        const { id: sessionId, ...sessionData } = quizState.session;

        // update certificate
        const updatedCertificate = {
          ...certificateData,
          isComplete: true,
          completionDate: new Date(),
          pointsAwarded: calcPointsEarned(quizState.responses),
        } as ICompletionCertificate;

        // update session
        const updatedSession = {
          ...sessionData,
          endDate: new Date(),
        } as IQuizSession;

        // update session and certificate in firebase
        await Promise.all([
          firebaseHelper
            .completionCertificates()
            .doc(quizState.certificate.id)
            .update(updatedCertificate),
          firebaseHelper
            .quizSessions()
            .doc(quizState.session.id)
            .update(updatedSession),
        ]);

        return {
          updatedSession: { ...updatedSession, id: sessionId } as IQuizSession,
          updatedCertificate: {
            ...updatedCertificate,
            id: certId,
          } as ICompletionCertificate,
        };
      }
      return thunkAPI.rejectWithValue({
        error: "End Quiz Error",
        message:
          "Cannot end quiz when response count is lower than total number of questions",
      });
    }
    return thunkAPI.rejectWithValue({
      error: "Quiz State Null",
      message: "State cannot be null when ending a quiz",
    });
  }
);

export const fetchQuizData = createAsyncThunk(
  "quizSlice/fetchQuizData",
  async (
    params: {
      contentfulHelper: IContentfulHelper;
      firebaseHelper: IFirebaseHelper;
      certificateId: string;
      sessionId: string;
    },
    thunkAPI
  ) => {
    const {
      contentfulHelper,
      firebaseHelper,
      certificateId,
      sessionId,
    } = params;
    const { workspace, userData } = thunkAPI.getState() as RootState;

    // check that certificate is in active membership
    const activeMembership = userData?.memberships?.find(
      (membership) => membership.id === workspace?.associatedMembership
    );
    if (activeMembership) {
      // fetch cert and session
      const [certificate, session] = await Promise.all([
        fetchCertificate(firebaseHelper, certificateId),
        fetchQuizSession(firebaseHelper, sessionId),
      ]);
      // check data
      if (certificate) {
        if (session) {
          // ensure session is in certificates
          if (certificate.quizSessions?.includes(sessionId)) {
            // fetch session responses and quiz content
            const [reponseSets, quiz] = await Promise.all([
              fetchQuizResponses(firebaseHelper, [session]),
              contentfulHelper.getQuizById(session.externalQuizId),
            ]);
            if (reponseSets) {
              const responses = reponseSets[0];
              // create state
              const quizState: QuizSlice = {
                inReview: false,
                session,
                responses: responses,
                certificate,
                totalQuestionCount: quiz.fields.quizQuestions.length,
                currentQuestionIndex:
                  responses.length === 0 ? 0 : responses.length - 1,
              };
              return quizState;
            }
            return thunkAPI.rejectWithValue({
              error: "Responses Unavailable",
              message: `Failed to fetch responses for session with ID of ${sessionId}`,
            });
          }
          return thunkAPI.rejectWithValue({
            error: "Certificate/Session Match Invalid",
            message: `Session with ID of ${sessionId} not in certificate ${certificateId}`,
          });
        }
        return thunkAPI.rejectWithValue({
          error: "Session Invalid",
          message: `Cannot fetch session with ID of ${sessionId}`,
        });
      }
      return thunkAPI.rejectWithValue({
        error: "Certificate Invalid",
        message: `Cannot fetch certificate with ID of ${certificateId}`,
      });
    }
    return thunkAPI.rejectWithValue({
      error: "Non-active Membership",
      message:
        "Cannot fetch quiz data when workspace and/or user data is unavailable",
    });
  }
);
