import React, { SyntheticEvent } from "react";
import { LightBoxButton, QuizFrame } from "../../elements";
import { IQuizOverivew } from "../types";
import { VerticalStatList } from "../../components/lists";
import {
  PassedBadgeOverlay,
  RequireBadgeOverlay,
} from "../../components/overlays";

export const QuizOverview = (props: IQuizOverivew) => (
  <QuizFrame>
    {props.isRequired ? (
      props.isCompleted ? (
        <PassedBadgeOverlay didPass={props.didPass} />
      ) : (
        <RequireBadgeOverlay />
      )
    ) : null}
    <h2>{props.title}</h2>
    <p style={{ marginBottom: "2rem" }}>{props.subTitle}</p>
    <VerticalStatList stats={props.stats} />
    <LightBoxButton
      {...props.buttonData}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    />
  </QuizFrame>
);
