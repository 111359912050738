import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ICardButton, IGenericButton } from "./elementTypes";

// provides structure and basic styling to all other buttons
const VanillaButton = styled(Link)`
  display: inline-block;
  box-shadow: none;
  border: none;
  text-transform: uppercase;
  font-size: 1.25em;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 0.5rem 2rem;
  text-decoration: none;
`;
const VanillaBoxButton = styled(VanillaButton)`
  font-family: "OpenSans-Bold";
  min-width: 100px;
`;

export const LightBoxButton = styled(VanillaBoxButton)<IGenericButton>(
  ({ theme }) => css`
    background-color: transparent;
    border: solid ${theme.color.mainHighlight} 2px;
    color: ${theme.color.mainHighlight};
    &:hover {
      background-color: ${theme.color.mainHighlight};
      color: ${theme.color.mainText};
    }
  `
);

export const SolidBoxButton = styled(VanillaBoxButton)<IGenericButton>(
  ({ theme }) => css`
    background-color: ${theme.color.mainHighlight};
    border: none;
    color: ${theme.color.mainText};
    &:hover {
      background-color: ${theme.color.mainText};
      color: ${theme.color.mainHighlight};
    }
  `
);

export const CardButton = styled(VanillaButton)<ICardButton>(
  ({ buttonType, theme }) => css`
    font-family: "OpenSans-Bold";
    color: white;
    padding: 1rem;
    background-color: ${buttonType === "review"
      ? theme.color.mainHighlight
      : buttonType === "begin"
      ? theme.color.beginHighlight
      : theme.color.continueHighlight};
  `
);

export const TagButton = styled(VanillaButton)<IGenericButton>(
  ({ theme }) => css`
    font-family: OpenSans-Regular;
    color: ${theme.color.mainText};
    border: solid ${theme.color.mainText} 2px;
    min-width: 150px;
    border-radius: 15px;
    padding: 0.25rem 1.75rem;
    &:hover {
      background: ${theme.color.mainHighlight};
      cursor: pointer;
      font-family: OpenSans-Bold;
    }
  `
);
