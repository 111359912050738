import React from "react";
import { LightBoxButton } from "../../elements";
import { IQuizControls } from "../index";
import styled from "styled-components";

const Controls = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
`;
export const QuizControls = (props: IQuizControls) => (
  <Controls>
    {props.buttonOne.showButton ? (
      <LightBoxButton
        to={""}
        onClick={props.buttonOne.onClick}
        style={{ marginRight: "2rem" }}
      >
        {props.buttonOne.children}
      </LightBoxButton>
    ) : null}
    {props.buttonTwo.showButton ? (
      <LightBoxButton to={""} onClick={props.buttonTwo.onClick}>
        {props.buttonTwo.children}
      </LightBoxButton>
    ) : null}
  </Controls>
);
