import React, { CSSProperties } from "react";
import { ItemList, TagButton } from "../../elements";
import { ITagList } from "../componentTypes";

export const TagList = (props: ITagList & { style?: CSSProperties }) => (
  <ItemList style={{ ...props.style, justifyContent: "flex-start" }}>
    {props.tags.map((item, index) => (
      <TagButton
        {...item}
        key={index}
        style={{ marginRight: "2rem", marginBottom: "1rem" }}
      />
    ))}
  </ItemList>
);
