// @ts-nocheck
import { createGlobalStyle } from "styled-components";
import openBold from "../../assets/fonts/OpenSans-Bold.ttf";
import openBoldItalic from "../../assets/fonts/OpenSans-BoldItalic.ttf";
import openExtraBold from "../../assets/fonts/OpenSans-ExtraBold.ttf";
import openExtraBoldItalic from "../../assets/fonts/OpenSans-ExtraBoldItalic.ttf";
import openItalic from "../../assets/fonts/OpenSans-Italic.ttf";
import openLight from "../../assets/fonts/OpenSans-Light.ttf";
import openLightItalic from "../../assets/fonts/OpenSans-LightItalic.ttf";
import openSemiBold from "../../assets/fonts/OpenSans-SemiBold.ttf";
import openSemiBoldItalic from "../../assets/fonts/OpenSans-SemiBoldItalic.ttf";
import openRegular from "../../assets/fonts/OpenSans-Regular.ttf";

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: OpenSans-Bold;
        src: url(${openBold});
    }
    @font-face {
        font-family: OpenSans-BoldItalic;
        src: url(${openBoldItalic});
    }
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url(${openExtraBold});
    }
    @font-face {
        font-family: OpenSans-ExtraBoldItalic;
        src: url(${openExtraBoldItalic});
    }
    @font-face {
        font-family: OpenSans-Italic;
        src: url(${openItalic});
    }
    @font-face {
        font-family: OpenSans-Light;
        src: url(${openLight});
    }
    @font-face {
        font-family: OpenSans-LightItalic;
        src: url(${openLightItalic});
    }
    @font-face {
        font-family: OpenSans-Regular;
        src: url(${openRegular});
    }
    @font-face {
        font-family: OpenSans-SemiBold;
        src: url(${openSemiBold});
    }
    @font-face {
        font-family: OpenSans-SemiBoldItalic;
        src: url(${openSemiBoldItalic});
    }
    
    body {
        margin: 0;
        padding: 0;
        background-color: ${({ theme }) => theme.color.mainBackground};
        color: ${({ theme }) => theme.color.mainText};
        font-family: 'OpenSans-Regular';
        font-size: 10pt;
        width: 100%;
    }
    h1, h2, h3, h4 {
        margin: 0 0 0.5rem 0;
    }
    h1 {
        font-family: 'OpenSans-Bold';
        font-size: 3.5em;
        color: white;
    }
    h2 {
        font-family: 'OpenSans-Bold';
        font-size: 2.5em;
    }
    h3 {
        font-family: 'OpenSans-Bold';
        font-size: 2em;
        color: ${({ theme }) => theme.color.mainText};
    }
    h4 {
        font-family: 'OpenSans-Bold';
        font-size: 1.4em;
    }
    p {
        font-family: 'OpenSans-Regular';
        line-height: 1.75;
        margin: 0 0 0.5rem 0;
    }
    footer {
        color: ${({ theme }) => theme.color.midMute};
        background-color: ${({ theme }) => theme.color.mainText};
    }
    nav {
        background-color: white;
    }
`;
