import { IContentfulHelper } from "../../config/types/contentfulTypes";
import { ISection } from "../../../../@types/generated/contentful";
import { SyntheticEvent, useEffect, useState } from "react";
import { IMember, IModule } from "../../config/types/firebaseTypes";
import {
  IInteractiveCard,
  ISectionCard,
} from "../../../UIComponents/components";
import { useFirebaseContext } from "../../providers/FirebaseProvider";
import { ContentfulHelper } from "../../clients/contentful";
import {
  averageResponseTime,
  calcAverageQuizScore,
  fetchCertificates,
  fetchQuizResponses,
  fetchQuizSessionsByExternalId,
  filterCertificatesBySections,
  generateSectionStats,
} from "../../utils";

export const useSection = (
  sectionId: string,
  contentfulHelper: IContentfulHelper | null
): [ISection | null, boolean] => {
  const [section, setSection] = useState<ISection | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    if (contentfulHelper && sectionId) {
      setLoadingStatus(true);
      (async () => {
        const fetchedSection = await contentfulHelper.getSectionById(sectionId);
        setSection(fetchedSection);
        setLoadingStatus(false);
      })();
    }
  }, [contentfulHelper, sectionId]);

  return [section, isLoading];
};

export const useSectionStats = (
  externalSpaceId: string | undefined,
  firebaseParentModule: IModule | null,
  membership: IMember | null
): [IInteractiveCard[] | null, boolean] => {
  //const navigate = useNavigate();
  const firebaseHelper = useFirebaseContext();
  // section state
  const [cards, setCards] = useState<IInteractiveCard[] | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    setLoadingStatus(true);
    if (
      membership &&
      firebaseHelper !== null &&
      firebaseParentModule !== null &&
      externalSpaceId !== undefined
    ) {
      (async () => {
        // create contentful client
        // get contentful module
        const contentfulClient = new ContentfulHelper(
          externalSpaceId,
          process.env.REACT_APP_CDA_TOKEN || ""
        );
        const contentfulModule = await contentfulClient.getModuleById(
          firebaseParentModule.externalModuleId
        );
        // parse out sections and fetch certificates
        const { sections } = contentfulModule.fields;
        const certificates = filterCertificatesBySections(
          (await fetchCertificates(
            firebaseHelper,
            membership?.contentCertificates || []
          )) || [],
          sections || []
        );
        if (sections) {
          // map sections to stats
          const stats = await Promise.all(
            sections.map(async (section, index) => {
              // find certificate, based on current section in map
              // calc progress
              const currentCertificate = certificates.find(
                (cert) => cert.externalSectionId === section.sys.id
              );
              const sectionProgress = currentCertificate
                ? currentCertificate?.completionDate
                  ? 100
                  : 50
                : 0;
              let averageScore = 0;
              let avgResponseTime: undefined | number = undefined;
              if (section.fields.quizzes) {
                // fetch section certificate, quiz sessions, quiz responses
                const parentQuiz = section.fields.quizzes[0];
                const quizSessions = await fetchQuizSessionsByExternalId(
                  firebaseHelper,
                  section.sys.id
                );
                const quizResponses = await fetchQuizResponses(
                  firebaseHelper,
                  quizSessions
                );
                // get base quiz constants
                // calc stat data
                const totalQuestions = parentQuiz.fields.quizQuestions.length;
                averageScore = calcAverageQuizScore(
                  quizResponses,
                  totalQuestions
                );
                avgResponseTime =
                  quizSessions.length <= 0
                    ? undefined
                    : averageResponseTime(quizSessions, "mins");
              }
              // fetch section stats
              const {
                title,
                subTitle,
                summary,
                isLocked,
                isRequired,
                ...stats
              } = await generateSectionStats(
                section.sys.id,
                firebaseHelper,
                contentfulClient,
                index
              );
              // determine button type and text to be displayed
              const buttonType =
                currentCertificate && currentCertificate?.startDate
                  ? currentCertificate?.completionDate
                    ? "review"
                    : "continue"
                  : "begin";
              return {
                entityId: section.sys.id,
                isLocked: false,
                isRequired,
                title,
                subTitle,
                summary,
                dateStarted: currentCertificate?.startDate,
                dateFinished: currentCertificate?.completionDate,
                statData: {
                  ...stats,
                  sectionProgress,
                  averageQuizScore: averageScore,
                  passed: currentCertificate?.completionDate ? true : false,
                  retakes: currentCertificate?.quizSessions?.length || 0,
                  averageCompletionTime: avgResponseTime,
                  completionTimeUnits: "mins",
                } as ISectionCard,
                buttonData: {
                  children: buttonType,
                  onClick: (e: SyntheticEvent) => {},
                  to: `/module/${firebaseParentModule.id}/section/${section.sys.id}`,
                  buttonType,
                },
              } as IInteractiveCard;
            })
          );
          // set state
          setCards(stats);
        } else setCards(null);
        // stop loading and set nothing to state
        setLoadingStatus(false);
      })();
    } else setLoadingStatus(false);
  }, [firebaseParentModule, externalSpaceId, firebaseHelper]);

  return [cards, isLoading];
};
