import React, { CSSProperties } from "react";
import { NavItem, ItemList, FooterNavItem } from "../../elements";
import { INavItemList } from "../index";

export const NavItemList = (
  props: INavItemList & { style?: CSSProperties }
) => (
  <ItemList style={{ justifyContent: "flex-start", ...props.style }}>
    {props.navItems.map((item, index) => (
      <NavItem key={index} {...item} style={{ marginRight: "2rem" }} />
    ))}
  </ItemList>
);

export const FooterNavItemList = (
  props: INavItemList & { style?: CSSProperties }
) => (
  <ItemList style={{ width: "250px", ...props.style }}>
    {props.navItems.map((item, index) => (
      <FooterNavItem
        key={index}
        {...item}
        style={{ marginBottom: "0.75rem" }}
      />
    ))}
  </ItemList>
);
