import React, { ReactNode } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, Document, Block } from "@contentful/rich-text-types";

export const richTextToReact = (richText: Document): ReactNode => {
  // parsing options
  const options = {
    renderText: (text: string) => {
      return text.split("\n").reduce(
        (children: any, textSegment: string, index: number) => {
          return [...children, textSegment];
        },
        [""]
      );
    },
  };
  // generate JSX
  return documentToReactComponents(richText, options);
};
