import React from "react";
import { FooterBody, FooterLogo } from "../../elements";
import {
  FooterNavItemList,
  MessagingInput,
  SummaryParagraph,
} from "../../components";

export const Footer = () => {
  return (
    <FooterBody>
      <FooterLogo />
      <SummaryParagraph
        style={{ textAlign: "left", width: "320px", overflowWrap: "anywhere" }}
        color={"mute"}
        title={"Job training"}
        description={
          "asfl;kjasfld;kjaslkdflkaslkdfl;kajsfdkjl;asjklfdjasjdfkjklasfjlk;jaskdf"
        }
      />
      <FooterNavItemList
        navItems={[
          {
            children: "Dashboard",
            to: "/test",
          },
          {
            children: "My Content",
            to: "/test",
          },
          {
            children: "About",
            to: "/test",
          },
        ]}
      />
      <MessagingInput
        inputTitle={"Got Question? Contact an Admin"}
        searchInput={{
          placeholder: "Send a Message...",
          type: "text",
          onChange: () => console.log("Message input change"),
          onKeyDown: () => console.log("Message keydown fired"),
          required: true,
          name: "AdminMsgInput",
          readOnly: false,
        }}
        buttonData={{
          children: "Send",
          onClick: () => console.log("Send button click fired"),
          to: "",
        }}
      />
    </FooterBody>
  );
};
