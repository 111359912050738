import React from "react";
import { CardHeading, CardSubTitle, CardHeaderBase } from "../../elements";
import { IGenericCardHeader } from "../index";

export const ModuleCardHeader = (props: IGenericCardHeader) => (
  <CardHeaderBase style={props.style}>
    <CardHeading>{props.title}</CardHeading>
    <CardSubTitle>{props.subTitle}</CardSubTitle>
  </CardHeaderBase>
);
