import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react";
import { FirebaseHelper } from "../clients/firebase";
import { IFirebaseHelper } from "../config/types/firebaseTypes";
import { hostname } from "os";
import firebase from "firebase/app";

const FirebaseContext = createContext<{
  firebaseInstance: IFirebaseHelper | null;
  resetFirebase: () => void;
}>({
  firebaseInstance: null,
  resetFirebase: () => {},
});

export default (props: { children: ReactNode }) => {
  const [firebaseHelper, setFirebaseHelper] = useState<IFirebaseHelper | null>(
    null
  );

  const generateNewApp = async () => {
    // set new firebase instance
    const firebaseHelper = new FirebaseHelper();
    setFirebaseHelper(firebaseHelper);
  };

  // on mount
  useEffect(() => {
    generateNewApp();
    console.log("Number of active firebase apps:", firebase.apps.length);
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        firebaseInstance: firebaseHelper,
        resetFirebase: async () => {
          await generateNewApp();
        },
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () =>
  useContext(FirebaseContext).firebaseInstance;
//export const useFirebaseReset = () => useContext(FirebaseContext).resetFirebase;
