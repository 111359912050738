import React, { useEffect, useMemo } from "react";
import { ProfileOverview, ProfileTags } from "../containers/profile";
import { Divider } from "../containers/content";
import { PageContainer } from "../elements";
import { useDispatch, useSelector } from "react-redux";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  useMembershipImages,
  useModuleStats,
  useOrganization,
  useOrganizationStats,
  useSubscription,
} from "../../boilerplate/hooks";
import { Loading } from "../components/misc";
import { useAuthContext } from "../../boilerplate/providers/AuthProvider";
import { AppDispatch } from "../../boilerplate/providers/StoreProvider";
import { addMembershipToUser } from "../../boilerplate/redux/userData";
import { ROUTES } from "../../boilerplate/config";
import { ContentGrid } from "../containers/grids/ContentGrid";
import { RootState } from "../../boilerplate/redux/setup";
import { UserDataSlice } from "../../boilerplate/redux/reduxTypes";

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const authUser = useAuthContext();
  const firebaseHelper = useFirebaseContext();
  const routeParams = useParams();
  // redux
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;
  // fetch org, memberships (2D array returned), and module stats
  const [organization, loadingOrg] = useOrganization(routeParams.id);
  //const [subscription] = useSubscription(organization);
  const [organizationStats, loadingOrgStats] = useOrganizationStats(
    organization
  );
  const memberOrg = useMemo(
    () => (organization === null ? null : [organization]),
    [organization]
  );
  const [memberArr, loadingMembers] = useMembershipImages(memberOrg);
  const activeMembership = useMemo(() => {
    if (userData && organization) {
      const membership = userData.memberships?.find((membership) =>
        organization.members?.includes(membership.id)
      );
      if (membership) return membership;
    }
    return null;
  }, [organization, userData]);
  const [statModules, loadingModules] = useModuleStats(
    organization?.externalSpaceID,
    organization === null ? [] : organization.modules,
    activeMembership,
    false
  );

  useEffect(() => {
    console.group("Organization Profile Data");
    console.log("Organization", organization);
    console.log("Stats", organizationStats);
    console.log("Modules", statModules);
    console.log("Stats loading", loadingOrgStats);
    console.log("Organization loading", loadingOrg);
    console.log("Modules loading", loadingModules);
    console.groupEnd();
  });

  return (
    <>
      {loadingOrg || loadingOrgStats || loadingMembers ? (
        <PageContainer
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading message={"Loading Organization"} />
        </PageContainer>
      ) : organization !== null ? (
        organization.isPublic || activeMembership ? (
          <>
            <ProfileOverview
              title={organization.name}
              description={organization.description}
              onMembershipSubscribe={() => {
                if (firebaseHelper !== null) {
                  // subscribe if user is signed in
                  if (authUser !== null) {
                    dispatch(
                      addMembershipToUser({
                        firebaseHelper,
                        organizationId: organization?.id || "",
                        userId: authUser?.uid,
                      })
                    );
                  } else {
                    // redirect to sign in before subscribing
                    navigate(ROUTES.LOGIN);
                  }
                }
              }}
              iconURL={organization.logo}
              members={memberArr ? memberArr[0] : []}
              stats={
                organizationStats === null
                  ? {
                      title: "At A Glance",
                      joinDate: new Date(),
                      quizCount: 0,
                      userPointCount: 0,
                      contentRating: 0,
                      profileType: "organization",
                    }
                  : {
                      ...organizationStats,
                      profileType: "organization",
                    }
              }
              buttonText={activeMembership ? "Unsubscribe" : "Subscribe"}
              style={{ marginBottom: "6rem" }}
            />
            <ProfileTags
              title={"Associated Tags"}
              description={
                "Choose any of your organizations to view its content. Alternatively, " +
                "you can search for, and join, any publicly available organizations."
              }
              tags={
                organization.tags.length !== 0
                  ? organization.tags.map((tag) => ({
                      children: tag,
                      onClick: (e) => {
                        e.preventDefault();
                        // todo: add search redirect later
                        console.log("profile tag clicked");
                      },
                      to: "",
                    }))
                  : []
              }
              style={{ marginBottom: "6rem" }}
            />
            <Divider
              title={"Learning Modules"}
              description={
                "Below is a sample of the content available through D&D. " +
                "To gain access to all the content, " +
                "become a member or request a membership"
              }
              infoCard={null}
            />
            {statModules?.length === 0 || statModules === null ? (
              <h3>No Content Found</h3>
            ) : (
              <ContentGrid
                content={statModules === null ? [] : statModules}
                isLoading={loadingModules}
                itemLimitReached={true}
                loadMoreClick={() => {}}
              />
            )}
          </>
        ) : (
          <h2>
            Error: Organization with ID {routeParams.id} unable for viewing (you
            don't have access).
          </h2>
        )
      ) : (
        <h2>Error: Organization with ID {routeParams.id} unavailable.</h2>
      )}
    </>
  );
};
