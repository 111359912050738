import React from "react";
import { LightPageHeader } from "../components/headers";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { ROUTES } from "../../boilerplate/config/routing/routes";

export default () => {
  const navigate = useNavigate();

  // set text and redirect buttons
  let buttons: {
      buttonText: string;
      buttonClick: () => void;
    }[] = [],
    title = "";
  const viewOrgs = {
    buttonText: "View Your Orgs.",
    buttonClick: () => {
      console.log("View orgs clicked");
      // navigate to orgs
      navigate(ROUTES.WORKSPACE_MAIN);
    },
  };
  const viewInvites = {
    buttonText: "View Invites",
    buttonClick: () => {
      console.log("View invites clicked");
      // navigate to invites
      navigate(ROUTES.WORKSPACE_SUB_INVITE);
    },
  };
  const searchOrgs = {
    buttonText: "Search For Orgs.",
    buttonClick: () => {
      console.log("Search for orgs clicked");
      // navigate to search
      navigate(ROUTES.WORKSPACE_SUB_SEARCH);
    },
  };
  if (
    //useMatch(ROUTES.WORKSPACE_SUB_SELECTION) ||
    useMatch(ROUTES.WORKSPACE_MAIN)
  ) {
    title = "Select an Organization";
    buttons = [searchOrgs, viewInvites];
  } else if (useMatch(ROUTES.WORKSPACE_SUB_SEARCH)) {
    title = "Search for an Organization";
    buttons = [viewOrgs, viewInvites];
  } else {
    title = "Accept an Invite";
    buttons = [viewOrgs, searchOrgs];
  }

  return (
    <>
      <LightPageHeader
        title={title}
        description={
          "Choose any of your profile to view its content. Alternatively, you\n" +
          "      can search for, and join, any publicly available organizations."
        }
        buttons={buttons}
        style={{ marginBottom: "8rem" }}
      />
      <Outlet />
    </>
  );
};
