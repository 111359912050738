import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorBanner } from "../containers/error";

export default () => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <ErrorBanner
      title={`Not Found`}
      message={`Path '${currentPath}' not available in application.`}
    />
  );
};
