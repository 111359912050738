import React, { CSSProperties } from "react";
import { IInputField, InputBody, InputField } from "../../elements";

export const FormInput = ({
  style,
  ...props
}: IInputField & { style?: CSSProperties }) => (
  <InputBody style={style}>
    <InputField {...props} />
  </InputBody>
);
