import React, { CSSProperties } from "react";
import { IQuizAnswer, IQuizQuestion } from "../index";
import {
  CancelCircleIcon,
  CheckCircleIcon,
  CircleIcon,
  QuizAnswerCard,
} from "../../elements";
import styled, { css } from "styled-components";

export const QuizQuestion = (
  props: IQuizQuestion & { style?: CSSProperties }
) => (
  <div style={props.style}>
    <p style={{ textTransform: "uppercase" }}>
      Question {props.questionNumber}
    </p>
    <h3>{props.question}</h3>
  </div>
);

const ReactiveCircle = styled(CircleIcon)(
  ({ theme }) => css`
    ${QuizAnswerCard}:hover & {
      color: ${theme.color.mainHighlight};
    }
  `
);
export const QuizAnswer = (props: IQuizAnswer & { style?: CSSProperties }) => (
  <QuizAnswerCard
    isSelected={props.isSelected}
    onClick={props.onClick}
    style={props.style}
  >
    {props.isSelected === true ? (
      <>
        {props.annotation && !props.annotation.isCorrect ? (
          <CancelCircleIcon
            isHighlightColor={true}
            style={{ marginRight: "1.5rem" }}
          />
        ) : (
          <CheckCircleIcon
            isHighlightColor={true}
            style={{ marginRight: "1.5rem" }}
          />
        )}
      </>
    ) : (
      <ReactiveCircle
        isHighlightColor={false}
        style={{ marginRight: "1.5rem" }}
      />
    )}
    <p style={{ marginBottom: 0, marginRight: 4 }}>{props.answer}</p>
    {props.annotation && (
      <b>{props.annotation.isCorrect ? "Correct." : "Incorrect."}</b>
    )}
  </QuizAnswerCard>
);
