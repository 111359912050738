import React, { useEffect } from "react";
import { parseReduxDate } from "../../boilerplate/utils";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { InviteCard } from "../containers/cards/InviteCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../boilerplate/providers/StoreProvider";
import { addMembershipToUser } from "../../boilerplate/redux/userData";
import { RootState } from "../../boilerplate/redux/setup";
import {
  InviteSlice,
  ReduxInvite,
  UserDataSlice,
} from "../../boilerplate/redux/reduxTypes";
import { clearInviteState, fetchInvites } from "../../boilerplate/redux/invite";

export default () => {
  const firebaseHelper = useFirebaseContext();
  const dispatch = useDispatch<AppDispatch>();

  // redux data
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;
  const invites = useSelector<RootState>(
    (state) => state.invites
  ) as InviteSlice;

  useEffect(() => {
    // fetch user invites
    if (firebaseHelper && invites === null)
      dispatch(fetchInvites({ firebaseHelper }));
    return () => {
      dispatch(clearInviteState());
    };
  }, [firebaseHelper]);

  const handleAccept = async (reduxInvite: ReduxInvite) => {
    //useCallback(() => {
    // add user to organization
    const { invite, organization } = reduxInvite;
    if (firebaseHelper && userData)
      dispatch(
        addMembershipToUser({
          firebaseHelper,
          organizationId: organization.id,
          userId: userData.appData.id,
        })
      );
  }; //, [invites, userData]);

  return (
    <>
      {invites !== null ? (
        invites.openInvites.length > 0 ? (
          <>
            {invites?.openInvites.map((invite) => (
              <InviteCard
                key={invite.invite.id}
                onSubmit={async () => {
                  handleAccept(invite);
                }}
                title={`${invite.organization.name} Has Invited You!`}
                subHeader={"Accept the invite to begin learning!"}
                redirect={{
                  text: `Invite expires ${parseReduxDate(
                    invite.invite.expirationDate
                  ).toLocaleDateString()}`,
                  redirectRoute: "",
                }}
                buttonText={"Accept Invite"}
              />
            ))}
          </>
        ) : (
          <h2>It appears you have no open invites available.</h2>
        )
      ) : (
        <h2>
          Error: Could not retrieve invites state. Please reload and try again.
        </h2>
      )}
    </>
  );
};
