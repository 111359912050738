import React, { CSSProperties } from "react";
import { ContentHeader } from "../../components";
import { IContentDivider } from "../types";
import { TLDRCard } from "../cards";

export const Divider = (props: IContentDivider & { style?: CSSProperties }) => {
  const { title, description, infoCard } = props;
  return (
    <ContentHeader title={title} description={description} style={props.style}>
      {infoCard !== null ? <TLDRCard {...infoCard} /> : null}
    </ContentHeader>
  );
};
