import React, { useState } from "react";
import { InputForm } from "../containers";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { IAuthResult } from "../../boilerplate/config";
import { ROUTES } from "../../boilerplate/config";
import {
  handleInput,
  initialSignupState,
  LoginState,
  SignupState,
  loginInputs,
  signupInputs,
  trimInput,
  initialLoginState,
} from "../../boilerplate/utils";

export default (props: { contentType: "login" | "signup" }) => {
  const { contentType } = props;
  // state
  const [error, setError] = useState<string | null>(null);
  const [formInput, setFormInput] = useState<LoginState | SignupState>(
    props.contentType === "login" ? initialLoginState : initialSignupState
  );

  // custom hooks
  const firebaseContext = useFirebaseContext();

  const handleSubmit = async (): Promise<void> => {
    if (firebaseContext) {
      // trim input
      const trimmedInput = trimInput(formInput);
      let result: IAuthResult;
      if (contentType === "signup") {
        const signUpState = trimmedInput as SignupState;
        // check for password match
        if (signUpState.password === signUpState.confirmPassword) {
          // create user
          result = await firebaseContext.createNewUser({
            ...signUpState,
            birthDate: new Date(signUpState.birthDate),
          });
          if (result.success) await firebaseContext.auth.signOut();
        } else result = { success: false, error: "Passwords do not match" };
      } else {
        // login user
        result = await firebaseContext.standardSignIn(
          trimmedInput.email,
          trimmedInput.password
        );
      }
      console.log("Authentication result is:", result);
      // determine if authentication was successful
      if (result.success) {
        console.log("Login was successful");
      } else setError(result.error || "");
    }
  };

  return (
    <InputForm
      title={contentType === "login" ? "Account Login" : "Create an Account"}
      subHeader={
        contentType === "login" ? "Don't have an account?" : "Already a User?"
      }
      redirect={{
        text: contentType === "login" ? "Create an Account." : "Login.",
        redirectRoute: contentType === "login" ? ROUTES.SIGNUP : ROUTES.LOGIN,
      }}
      buttonText={contentType === "login" ? "Login" : "Signup"}
      inputFields={contentType === "login" ? loginInputs : signupInputs}
      onSubmit={handleSubmit}
      onInput={(value, key) => {
        handleInput(value, key, setFormInput);
      }}
      formError={error}
    />
  );
};
