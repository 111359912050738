import React, { SyntheticEvent } from "react";
import { LightBoxButton, PageContainer } from "../../elements";
import { StandardParagraph } from "../../components";
import { richTextToReact } from "../../../boilerplate/config/contentful/richTextParser";
import { ISectionContent } from "../types";

export const SectionContent = (props: ISectionContent) => (
  <PageContainer style={{ padding: "3rem 5rem", background: "white" }}>
    <StandardParagraph
      {...props.overview}
      style={{ textAlign: "center", marginBottom: "2rem" }}
      headerStyles={{ fontFamily: "OpenSans-Regular" }}
    />
    <section
      style={{
        marginBottom: "2rem",
      }}
    >
      {props.content.map((block) => (
        <>
          {block.blockHeader && (
            <>
              <h4>{block.blockHeader}</h4>
              <br />
            </>
          )}
          <article>{richTextToReact(block.paragraph)}</article>
        </>
      ))}
    </section>
    {props.metaData.displayButton && (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <LightBoxButton
          onClick={(e: SyntheticEvent) => {
            // prevent default redirect
            e.preventDefault();
            // check and fire handler
            const { handleContentFinish } = props.metaData;
            if (handleContentFinish) handleContentFinish();
          }}
          to={""}
        >
          {props.metaData.buttonText}
        </LightBoxButton>
      </div>
    )}
  </PageContainer>
);
