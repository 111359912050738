import React from "react";
import {
  LockIcon,
  LockMessage,
  LockBody,
  LockBackground,
  LockContent,
} from "../../elements";
import { IGenericCardLock } from "../index";

export const CardLock = (props: IGenericCardLock) => (
  <LockBody>
    <LockBackground />
    <LockContent>
      <LockIcon isHighlightColor={false} style={{ marginBottom: "1rem" }} />
      <LockMessage>{props.lockMessage}</LockMessage>
    </LockContent>
  </LockBody>
);
