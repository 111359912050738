import React, { ChangeEvent } from "react";
import {
  NavCardBody,
  LockIcon,
  GroupIcon,
  UserIcon,
  NavbarLogo,
} from "../../elements";
import { QuickSearch, NavItemList, SortDropdown } from "../../components";
import { mappedRoutes } from "../../../boilerplate/config/routing";
import { INavbar } from "../types";

export const Navbar = (props: INavbar) => {
  const getGreetingTimeWord = (): "Morning" | "Afternoon" | "Evening" => {
    const currentTime = new Date().getHours();
    if (currentTime >= 0 && currentTime < 12) {
      return "Morning";
    } else if (currentTime >= 12 && currentTime < 17) {
      return "Afternoon";
    } else {
      return "Evening";
    }
  };

  return (
    <NavCardBody>
      <NavbarLogo src={props.navbarImgSRC} style={{ marginRight: "3rem" }} />
      <QuickSearch
        style={{ marginRight: "3rem", flexGrow: 3 }}
        searchField={{
          placeholder: "Search Content",
          type: "text",
          onChange: (e) =>
            props.primeSearch(
              (e as ChangeEvent<HTMLInputElement>).currentTarget.value
            ),
          onKeyDown: (e) => {
            if (e.key.toUpperCase() === "ENTER") props.beginContentSearch();
          },
          required: true,
          name: "QuickSearchInput",
          readOnly: false,
        }}
        children={null}
      />
      <NavItemList
        style={{ flexGrow: 2 }}
        navItems={mappedRoutes
          .filter((route) => route.ShowOnNavbar)
          .map((route) => ({
            children: route.DisplayName,
            to: route?.RouteName || "",
          }))}
      />
      <SortDropdown
        title={`${getGreetingTimeWord()}, ${props.username}`}
        items={[
          {
            icon: (
              <UserIcon
                isHighlightColor={false}
                style={{ width: "25px", color: "black" }}
              />
            ),
            text: "Reset Password",
            to: "",
            onClick: (e) => {
              e.preventDefault();
              props.resetPassword();
            },
          },
          {
            icon: (
              <GroupIcon
                isHighlightColor={false}
                style={{ width: "25px", color: "black" }}
              />
            ),
            text: "Change Workspace",
            to: "",
            onClick: (e) => {
              e.preventDefault();
              props.changeWorkspace();
            },
          },
          {
            icon: (
              <LockIcon
                style={{ width: "25px", color: "black" }}
                isHighlightColor={false}
              />
            ),
            text: "Sign Out",
            to: "",
            onClick: (e) => {
              e.preventDefault();
              props.signOut();
            },
          },
        ]}
      />
    </NavCardBody>
  );
};
