import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IFirebaseHelper,
  IOrganization,
  RootState,
  WorkspaceSlice,
} from "./internal";

export const refreshWorkspace = createAsyncThunk(
  "workspaceSlice/refreshWorkspace",
  async (
    params: {
      firebaseHelper: IFirebaseHelper;
    },
    thunkAPI
  ) => {
    const { firebaseHelper } = params;
    const workspace = (thunkAPI.getState() as RootState)
      .workspace as WorkspaceSlice;
    try {
      if (workspace) {
        // fetch organization by id
        const orgDoc = await firebaseHelper
          .organizations()
          .doc(workspace.organization.id)
          .get();
        return { ...orgDoc.data(), id: orgDoc.id } as IOrganization;
      }
      throw {
        error: "Workspace Null Reference",
        message: "Workspace redux state is null, cannot refresh workspace",
      };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
