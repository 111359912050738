import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { LightBoxButton, PageContainer, RedirectText } from "../../elements";
import { IAuthForm } from "../types";
import { FormDropdown, FormInput, IFormDropdownItem } from "../../components";

export const InputForm = (props: IAuthForm) => {
  const formRef = useRef<any>();
  const [disableButton, setDisable] = useState<boolean>(true);
  const {
    formError,
    inputFields,
    onInput,
    onSubmit,
    title,
    subHeader,
    redirect,
    buttonText,
  } = props;

  // handle input when button is disabled
  useEffect(() => {
    // submit form data, then re-enable button after
    if (disableButton) {
      (async () => {
        await handleSubmit();
      })();
      // re-enable button after processing finished
      setDisable(false);
    }
  }, [disableButton]);

  const handleSubmit = async () => {
    // check if input is valid
    if (formRef.current?.reportValidity()) await onSubmit();
  };

  const addListenerToDropdown = (
    dropdownName: string,
    items: IFormDropdownItem[]
  ): IFormDropdownItem[] => {
    // add the event listener to all dropdown items
    return items.map((dropdownItem) => ({
      ...dropdownItem,
      onClick: (e) => {
        // handle input
        onInput(dropdownItem.value, dropdownName);
      },
    }));
  };

  return (
    <PageContainer>
      <form ref={formRef}>
        <h2> {title} </h2>
        <p style={{ marginBottom: "2rem" }}>
          {subHeader}{" "}
          {redirect && (
            <>
              <RedirectText to={redirect.redirectRoute}>
                {redirect.text}
              </RedirectText>{" "}
            </>
          )}
        </p>
        {formError !== null && (
          <p style={{ fontSize: "1.2em", color: "red", marginBottom: "1rem" }}>
            {formError}
          </p>
        )}
        <div style={{ marginBottom: "2rem" }}>
          <>
            {inputFields.map((item) => (
              <div key={item.inputName} style={{ marginBottom: "2rem" }}>
                <h4
                  style={{
                    marginBottom: "1rem",
                    fontFamily: "OpenSans-Regular",
                    textTransform: "capitalize",
                  }}
                >
                  {item.inputName}
                </h4>
                {item.input ? (
                  <FormInput
                    {...item.input}
                    onChange={(e) => {
                      const event = e as ChangeEvent<HTMLInputElement>;
                      // handle input
                      onInput(event.target.value, item.stateKey);
                    }}
                  />
                ) : item.dropdown ? (
                  <FormDropdown
                    {...item.dropdown}
                    items={addListenerToDropdown(
                      item.stateKey,
                      item.dropdown.items
                    )}
                  />
                ) : null}
              </div>
            ))}
          </>
        </div>
        <LightBoxButton
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            if (!disableButton) setDisable(true);
          }}
          to={""}
        >
          {buttonText}
        </LightBoxButton>
      </form>
    </PageContainer>
  );
};
