import { InputItem } from "../../../UIComponents/containers/auth";

export const createInviteInputs: InputItem[] = [
  {
    inputName: "Email",
    stateKey: "email",
    input: {
      placeholder: "Enter Email",
      type: "email",
      required: true,
      name: "Email",
      readOnly: false,
    },
  },
  {
    inputName: "Admin Status",
    stateKey: "adminStatus",
    dropdown: {
      input: {
        placeholder: "Select Status",
        type: "text",
        required: true,
        name: "Email",
        readOnly: true,
      },
      items: [
        {
          onClick: () => {},
          text: "Is an Admin",
          value: "true",
        },
        {
          onClick: () => {},
          text: "Not an Admin",
          value: "false",
        },
      ],
    },
  },
  {
    inputName: "Assigned Role",
    stateKey: "roleId",
    dropdown: {
      input: {
        placeholder: "Select Role",
        type: "text",
        required: true,
        name: "Assigned Role",
        readOnly: true,
      },
      items: [],
    },
  },
];

export const signupInputs: InputItem[] = [
  {
    inputName: "Email",
    stateKey: "email",
    input: {
      placeholder: "Enter Email",
      type: "email",
      required: true,
      name: "Email",
      readOnly: false,
    },
  },
  {
    inputName: "Username",
    stateKey: "username",
    input: {
      placeholder: "Enter Username",
      type: "text",
      required: true,
      name: "Username",
      readOnly: false,
    },
  },
  {
    inputName: "Password",
    stateKey: "password",
    input: {
      placeholder: "Enter Password",
      type: "password",
      required: true,
      name: "Password",
      readOnly: false,
    },
  },
  {
    inputName: "Confirm Password",
    stateKey: "confirmPassword",
    input: {
      placeholder: "Re-enter Password",
      type: "password",
      required: true,
      name: "Confirm Password",
      readOnly: false,
    },
  },
  {
    inputName: "First Name",
    stateKey: "firstName",
    input: {
      placeholder: "Enter First Name",
      type: "text",
      required: true,
      name: "First Name",
      readOnly: false,
    },
  },
  {
    inputName: "Last Name",
    stateKey: "lastName",
    input: {
      placeholder: "Enter Last Name",
      type: "text",
      required: true,
      name: "Last Name",
      readOnly: false,
    },
  },
  {
    inputName: "Phone Number",
    stateKey: "phoneNumber",
    input: {
      placeholder: "Enter Phone Number",
      type: "tel",
      required: true,
      name: "Phone Number",
      readOnly: false,
    },
  },
  {
    inputName: "Verify Age",
    stateKey: "birthDate",
    input: {
      placeholder: "Enter Age",
      type: "date",
      required: true,
      name: "Verify Age",
      readOnly: false,
    },
  },
];

export const loginInputs: InputItem[] = [
  {
    inputName: "Email",
    stateKey: "email",
    input: {
      placeholder: "Enter Email",
      type: "email",
      required: true,
      name: "Email",
      readOnly: false,
    },
  },
  {
    inputName: "Password",
    stateKey: "password",
    input: {
      placeholder: "Enter Password",
      type: "password",
      required: true,
      name: "Password",
      readOnly: false,
    },
  },
];
