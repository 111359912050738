import {
  ICompletionCertificate,
  IFirebaseHelper,
  IQuizSession,
} from "../config/types/firebaseTypes";
import { IActivityCard } from "../../UIComponents/components";
import {
  fetchQuizResponses,
  fetchQuizSession,
  getCorrectResponseCount,
} from "./quizHelpers";
import { IContentfulHelper } from "../config/types/contentfulTypes";
import { ROUTES } from "../config/routing/routes";
import { fetchModuleById } from "./moduleHelpers";
import { IModule, ISection } from "../../../@types/generated/contentful";
import lodash from "lodash";
import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore-types";
import { daysToUnix, parseReduxDate, unixTimeSince } from "./staticHelpers";

export const sortCertificatesLowToHigh = (
  certificates: ICompletionCertificate[]
) =>
  certificates.sort(
    (certOne, certTwo) =>
      parseReduxDate(certTwo.startDate).getTime() - parseReduxDate(certOne.startDate).getTime()
  );

export const getBeginEndDatesFromCertificates = (
  certificates: ICompletionCertificate[],
  requiredSectionCount: number
): [startDate: Date | undefined, endDate: Date | undefined] => {
  const lowToHighCertificates = sortCertificatesLowToHigh(certificates);
  const startDate =
    lowToHighCertificates.length > 0
      ? lowToHighCertificates[0].startDate
      : undefined;
  const endDate =
    lowToHighCertificates.length >= requiredSectionCount &&
    lowToHighCertificates.length !== 0
      ? lowToHighCertificates[lowToHighCertificates.length - 1]?.completionDate
      : undefined;
  return [startDate, endDate];
};

export const fetchCertificates = async (
  firebaseHelper: IFirebaseHelper,
  certificateIds: string[],
  cuttoffInDays?: number
): Promise<ICompletionCertificate[] | null> => {
  // fetch and parse certificates
  const certificateDocs = await Promise.all(
    certificateIds.map((certId) =>
      firebaseHelper.completionCertificates().doc(certId).get()
    )
  );
  const certificates = certificateDocs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as ICompletionCertificate)
  );
  // check if data is unavailable
  if (certificates.length <= 0) return null;
  // filter out based on cuttoff time
  if (cuttoffInDays) {
    return certificates.filter((cert) => {
      const timeSinceStart = unixTimeSince(cert.startDate);
      const cuttoffInUnix = daysToUnix(cuttoffInDays);
      return timeSinceStart <= cuttoffInUnix;
    });
  }
  return certificates;
};

export const fetchCertificate = async (
  firebaseHelper: IFirebaseHelper,
  certificateId: string
): Promise<ICompletionCertificate | null> => {
  // fetch and parse certificate
  const certificateDoc = await firebaseHelper
    .completionCertificates()
    .doc(certificateId)
    .get();
  if (!certificateDoc.exists) return null;
  return {
    ...certificateDoc.data(),
    id: certificateDoc.id,
  } as ICompletionCertificate;
};

export const generateActivityStatsByCertificate = async (
  firebaseHelper: IFirebaseHelper,
  contentfulClient: IContentfulHelper,
  certificate: ICompletionCertificate
): Promise<IActivityCard | null> => {
  // check if quizzable
  if (certificate.quizSessions) {
    // fetch quiz session
    const quizSessions = await Promise.all(
      certificate.quizSessions.map((sessionId) =>
        fetchQuizSession(firebaseHelper, sessionId)
      )
    );
    // find most recent quiz session
    const sortedByRecent: IQuizSession[] = quizSessions
      .filter((session) => session !== null)
      .map((session) => session as IQuizSession)
      .sort(
        (sessionOne, sessionTwo) =>
          sessionOne.startDate.getTime() - sessionTwo.startDate.getTime()
      );
    if (sortedByRecent.length <= 0) return null;
    // fetch quiz content and responses
    const recentSession = sortedByRecent[0];
    const [quizContent, quizResponses] = await Promise.all([
      contentfulClient.getQuizById(recentSession.externalQuizId),
      fetchQuizResponses(firebaseHelper, [recentSession]),
    ]);
    const quizProgress =
      (getCorrectResponseCount(quizResponses[0]) / quizResponses[0].length) *
      100;
    // parse out session state
    const certStartDate = parseReduxDate(certificate.startDate);
    const certEndDate = certificate.completionDate
      ? parseReduxDate(certificate.completionDate)
      : null;
    const sessionStartDate = parseReduxDate(recentSession.startDate);
    const stats: IActivityCard = {
      startDate: sessionStartDate,
      headerData: {
        title: quizContent.fields.quizName,
        subTitle: "Quiz",
      },
      cardSummary: {
        title: `Started on ${certStartDate.toLocaleDateString()} at ${certStartDate.toLocaleTimeString()}`,
        description: certEndDate
          ? "Quiz Completed!"
          : "Quiz Requires Further Action",
      },
      buttonData: {
        buttonType: certEndDate ? "review" : "continue",
        children: certEndDate ? "Review Quiz" : "Finish Quiz",
        onClick: () => {},
        to: ROUTES.QUIZ.replace(":sessionId", recentSession.id).replace(
          ":certificateId",
          certificate.id
        ),
      },
      statData: {
        sliderName: "Progress",
        progressPercent: quizProgress,
        additionalStat: `${certEndDate ? certificate.pointsAwarded : 0} ${
          certEndDate ? "Earned Points" : "Earnable Points"
        }`,
      },
    };
    return stats;
  }
  /*else {
    // fetch parent module and section content
    const [sectionContent, parentModule] = await Promise.all([
      contentfulClient.getSectionById(certificate.externalSectionId),
      fetchModuleById(firebaseHelper, certificate.firebaseModuleId),
    ]);
    if (parentModule) {
      // parse out certificate data
      const stats: IActivityCard = {
        startDate: certificate.startDate,
        headerData: {
          title: sectionContent.fields.sectionHeader,
          subTitle: "Section",
        },
        cardSummary: {
          title: `Started on ${certificate.startDate.toLocaleDateString()} at ${certificate.startDate.toLocaleTimeString()}`,
          description: certificate.completionDate
            ? "Section Completed!"
            : "Section Requires Further Action",
        },
        buttonData: {
          buttonType: "review",
          children: certificate.completionDate
            ? "Review Section"
            : "Finish Section",
          onClick: () => {},
          to: ROUTES.SECTION.replace(
            ":moduleId",
            certificate.firebaseModuleId
          ).replace(":sectionId", sectionContent.sys.id),
        },
        statData: {
          sliderName: "Progress",
          progressPercent: certificate.completionDate ? 100 : 0,
          additionalStat: `${
            certificate.completionDate ? certificate.pointsAwarded : 0
          } ${
            certificate.completionDate ? "Earned Points" : "Earnable Points"
          }`,
        },
      };
      return stats;
    }
  }*/
  return null;
};

export const filterCertificatesByModule = (
  contentfulModule: IModule,
  certificates: ICompletionCertificate[]
): ICompletionCertificate[] => {
  // get all section Ids from contentful module
  const sectionIds = contentfulModule.fields?.sections?.map(
    (section) => section.sys.id
  );
  // filter out certificates that aren't included in id list
  return certificates.filter(
    (certificate) =>
      sectionIds?.includes(certificate.externalSectionId) || false
  );
};

export const fetchCertificatesBySection = async (
  firebaseHelper: IFirebaseHelper,
  sectionId: string
): Promise<ICompletionCertificate | null> => {
  const certDoc = await firebaseHelper
    .completionCertificates()
    .where("externalSectionId", "==", sectionId)
    .get();
  if (certDoc.docs.length === 0) return null;
  else {
    const doc = certDoc.docs[0];
    return { ...doc.data(), id: doc.id } as ICompletionCertificate;
  }
};

export const fetchCertificatesByModule = async (
  firebaseHelper: IFirebaseHelper,
  contentModule: IModule
): Promise<ICompletionCertificate[] | null> => {
  const sections = contentModule.fields.sections;
  if (sections) {
    // chunk sections due to firebase query limits
    const chunkedSections = lodash.chunk(sections, 10);
    const certificateDocTasks = [];
    for (const sections of chunkedSections) {
      // push fetch calls to tasks array
      certificateDocTasks.push(
        firebaseHelper
          .modules()
          .where("externalModuleId", "in", [
            sections.map((section) => section.sys.id),
          ])
          .get()
      );
    }
    // await and flat map fetched documents
    const certificateCollections = await Promise.all(certificateDocTasks);
    const certificates = certificateCollections
      .map((collection) => (collection.empty ? null : collection.docs))
      .filter((docSet) => docSet !== null)
      .flatMap((docSet) => docSet as QueryDocumentSnapshot<DocumentData>[]);
    if (certificates.length > 0)
      return certificates.map(
        (doc) => ({ ...doc.data(), id: doc.id } as ICompletionCertificate)
      );
  }
  return null;
};

export const getCompletedCertificates = (
  certificates: ICompletionCertificate[]
): ICompletionCertificate[] => certificates.filter((cert) => cert.isComplete);

export const getRequiredCertificates = (
  certificates: ICompletionCertificate[],
  sections: ISection[]
): ICompletionCertificate[] =>
  certificates.filter(
    (cert) =>
      sections.find((section) => section.sys.id === cert.externalSectionId)
        ?.fields.isRequired || false
  );

export const filterCertificatesBySections = (
  certificates: ICompletionCertificate[],
  sections: ISection[]
): ICompletionCertificate[] =>
  certificates.filter((cert) =>
    sections.map((section) => section.sys.id).includes(cert.externalSectionId)
  );
