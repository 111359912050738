import React from "react";
import { ISlider } from "../index";
import { SliderHeaderBody, ProgressSlider } from "../../elements";

export const AnnotatedSlider = (props: ISlider) => (
  <div style={props.style}>
    <SliderHeaderBody
      style={{ color: props.style === undefined ? "" : props.style.color }}
    >
      <p>{props.sliderName}</p>
      <p>{props.progressPercent ? `${props.progressPercent}%` : "N/A"}</p>
    </SliderHeaderBody>
    <ProgressSlider progressPercent={props.progressPercent} />
  </div>
);
