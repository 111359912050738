import React, { CSSProperties, useState } from "react";
import { DropdownIcon } from "../../elements";
import { IDropdown } from "../componentTypes";
import { DropdownListFrame as DropdownList } from "../../elements";
import { ActionableDropdownItem } from "../listItems";
import { SmallTitle, Dropdown, DropdownHeader } from "../../elements";

export const SortDropdown = (props: IDropdown & { style?: CSSProperties }) => {
  const [isCollapsed, setCollapse] = useState<boolean>(true);
  return (
    <Dropdown style={props.style}>
      <DropdownHeader onClick={() => setCollapse(!isCollapsed)}>
        {props.titleIcon}
        <SmallTitle
          color={"dark"}
          style={{
            textTransform: "capitalize",
            marginBottom: 0,
          }}
        >
          {props.title}
        </SmallTitle>
        <DropdownIcon isHighlightColor={false} isCollapsed={isCollapsed} />
      </DropdownHeader>
      {!isCollapsed && (
        <DropdownList style={{ width: "120px", zIndex: 400 }}>
          {props.items.map((item, index) => (
            <ActionableDropdownItem
              key={index}
              {...item}
              style={{ marginBottom: "1rem" }}
            />
          ))}
        </DropdownList>
      )}
    </Dropdown>
  );
};
