import {
  CardBottomContainer,
  CardButton,
  CardNavIcons,
  LeftArrowIcon,
  RightArrowIcon,
  CardControlsContent,
  BannerControlsContent,
  BannerControlArrow,
} from "../../elements";
import React from "react";
import { IBannerControls, ICardControls } from "../componentTypes";
import { PartialPath } from "history";

// overlays visibility property of navigation arrows
const isVisible = (
  arrowDirection: "left" | "right",
  pageNumber: number
): boolean => {
  if (arrowDirection === "left")
    if (pageNumber === 1) return false;
    else return true;
  else if (arrowDirection === "right")
    if (pageNumber === 1) return true;
    else return false;
  return false;
};

export const CardControls = (props: ICardControls) => (
  <CardBottomContainer>
    {props.pageCount === 0 ? null : (
      <CardControlsContent>
        <LeftArrowIcon
          isHighlightColor={false}
          onClick={props.clickLeft}
          style={{
            visibility: isVisible("left", props.currentPage)
              ? "visible"
              : "hidden",
          }}
        />
        <CardNavIcons
          totalPages={props.pageCount}
          currentPage={props.currentPage}
        />
        <RightArrowIcon
          isHighlightColor={false}
          onClick={props.clickRight}
          style={{
            visibility: isVisible("right", props.currentPage)
              ? "visible"
              : "hidden",
          }}
        />
      </CardControlsContent>
    )}
    <CardButton
      buttonType={props.buttonData.buttonType}
      onClick={props.buttonData.onClick}
      to={props.buttonData.to as string | (PartialPath & string)}
    >
      {props.buttonData.children}
    </CardButton>
  </CardBottomContainer>
);

export const BannerControls = (props: IBannerControls) => (
  <>
    {props.staticOnlyControls === true ? null : (
      <BannerControlArrow>
        <LeftArrowIcon
          isHighlightColor={true}
          onClick={props.clickLeft}
          style={{ color: "white " }}
        />
      </BannerControlArrow>
    )}
    <BannerControlsContent>{props.children}</BannerControlsContent>
    {props.staticOnlyControls === true ? null : (
      <BannerControlArrow style={{ justifyContent: "flex-end" }}>
        <RightArrowIcon
          isHighlightColor={true}
          onClick={props.clickRight}
          style={{ color: "white " }}
        />
      </BannerControlArrow>
    )}
  </>
);
