import React, { CSSProperties } from "react";
import { PageContainer } from "../../elements";
import { IProfileTags } from "./types";
import { TagList } from "../../components/lists";

export const ProfileTags = (
  props: IProfileTags & { style?: CSSProperties }
) => (
  <PageContainer style={props.style}>
    <h3>{props.title}</h3>
    <p>{props.description}</p>
    <TagList tags={props.tags} />
  </PageContainer>
);
