import {
  ICompletionCertificate,
  IMember,
} from "../../config/types/firebaseTypes";
import { useFirebaseContext } from "../../providers/FirebaseProvider";
import { useEffect, useState } from "react";
import { fetchCertificates } from "../../utils";

export const useCertificates = (
  membership: IMember | null
): [ICompletionCertificate[] | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  // certificate state
  const [certificates, setCertificates] = useState<
    ICompletionCertificate[] | null
  >(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    if (firebaseHelper && membership) {
      // set loading
      setLoadingStatus(true);
      (async () => {
        // fetch certificates
        const data = await fetchCertificates(
          firebaseHelper,
          membership.contentCertificates
        );
        setCertificates(data);
        setLoadingStatus(false);
      })();
    }
  }, [firebaseHelper, membership]);

  return [certificates, isLoading];
};
