import React, { useEffect, useMemo, useState } from "react";
import { Banner, Divider, TextOverview } from "../containers";
import { useParams } from "react-router-dom";
import { ContentGrid } from "../containers/grids/ContentGrid";
import {
  useModule,
  useModuleStats,
  useOrganization,
  useRoles,
  useSectionStats,
} from "../../boilerplate/hooks";
import { Loading } from "../components/misc";
import { PageContainer } from "../elements";
import {
  IContentTLDRCard,
  IInteractiveCard,
  isModuleType,
} from "../components";
import { IModule } from "../../boilerplate/config";
import {
  UserDataSlice,
  WorkspaceSlice,
} from "../../boilerplate/redux/reduxTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";

export default () => {
  // redux data
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;
  const workSpace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;

  // module/route params
  const routeParams = useParams();
  const activeMembership = useMemo(() => {
    const foundMembership = userData?.memberships?.find(
      (membership) => membership.id === workSpace?.associatedMembership
    );
    if (foundMembership === undefined) return null;
    else return foundMembership;
  }, [userData, workSpace]);

  // custom hooks
  const [module, moduleLoading] = useModule(routeParams.id);
  const [organization, organizationLoading] = useOrganization(
    useMemo(() => module?.parentOrganizationId || "", [module])
  );
  const [sections, sectionsLoading] = useSectionStats(
    organization?.externalSpaceID,
    module,
    activeMembership
  );
  const [moduleStats, moduleStatsLoading] = useModuleStats(
    organization?.externalSpaceID,
    useMemo(() => (module === null ? null : [module]), [module]),
    activeMembership
  );
  const roles = useMemo(() => {
    // filter out roles not included in membership
    if (module && activeMembership)
      return module.associatedRoles.filter((role) =>
        activeMembership.roles.includes(role)
      );
    return [];
  }, [module, activeMembership]);
  const [assignedRoles, rolesLoading] = useRoles(roles);

  useEffect(() => {
    // log data changes
    console.group("Module Template");
    console.group("Loading Status");
    console.log("Module loading", moduleLoading);
    console.log("Module Stats loading", moduleStatsLoading);
    console.log("Sections Stats loading", sectionsLoading);
    console.log("Organization loading", organizationLoading);
    console.groupEnd();

    console.group("Async Data");
    console.log("Module", module);
    console.log("Module Stats", moduleStats);
    console.log("Sections", sections);
    console.log("Organization", organization);
    console.groupEnd();

    console.group("Module Access Control");
    console.log(`Roles available`, roles);
    console.log("Active Membership", activeMembership);
    console.log(`Module Sample`, module?.isSample);
    console.groupEnd();
    console.groupEnd();
  });

  const parseTLDRFromStats = (
    module: IModule,
    stats: IInteractiveCard //ISectionCard | IModuleCard
  ): IContentTLDRCard | null => {
    const { statData } = stats;
    if (statData !== undefined && isModuleType(statData))
      return {
        title: "Module TLDR",
        timeEstimate: statData.timeToComplete || 0,
        timeEstimateUnits: statData.TTCUnits,
        quizCount: statData.quizCount,
        questionCount: statData.questionCount, // todo: FIX THIS!!
        earnableUserPoints: statData.earnableUserPoints,
      } as IContentTLDRCard;
    return null;
  };

  return (
    <>
      {moduleLoading || organizationLoading || rolesLoading ? (
        <PageContainer
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading message={"Loading Module"} />
        </PageContainer>
      ) : module !== null ? (
        roles.length > 0 ? (
          <>
            <Banner
              bannerArr={[
                {
                  imageData: {
                    imageURL: module.content.moduleImage?.fields.file.url,
                  },
                  textData: {
                    title: module.content.moduleHeading,
                    subTitle: `${
                      assignedRoles
                        ?.map((role) => role.roleName)
                        .reduce((prev, current) => prev + ", " + current) ||
                      "Public"
                    } Module`,
                  },
                },
              ]}
            />
            <TextOverview
              overviewText={{
                title: "Module Overview",
                description: module?.content.moduleOverview || "",
              }}
              tags={module.content.moduleTags?.map((tag) => ({
                children: tag.fields.tagline,
                onClick: (e) => {
                  e.preventDefault();
                  // todo: add search redirect later
                  console.log("module tag clicked");
                },
                to: "",
              }))}
            />
            <Divider
              title={"Course Content"}
              description={
                "Below is all the section content that must be studiied " +
                "to complete this module."
              }
              infoCard={
                moduleStats !== null && moduleStats.length > 0
                  ? parseTLDRFromStats(module, moduleStats[0])
                  : null
              }
            />
            {sections?.length === 0 || sections === null ? (
              <h3>No Content Found</h3>
            ) : (
              <ContentGrid
                content={sections === null ? [] : sections}
                isLoading={sectionsLoading}
                itemLimitReached={true}
                loadMoreClick={() => {}}
              />
            )}
          </>
        ) : (
          <h2>
            Error: Module with ID {routeParams.id} unable for viewing (you don't
            have access).
          </h2>
        )
      ) : (
        <h2>Error: Module with ID {routeParams.id} unavailable.</h2>
      )}
    </>
  );
};
