import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FetchError,
  IOrganization,
  refreshWorkspace,
  WorkspaceSlice,
} from "./internal";

let initialState: WorkspaceSlice = null as WorkspaceSlice;

const workspaceSlice = createSlice({
  name: "workspaceSlice",
  initialState,
  reducers: {
    clearWorkspace: () => {
      return null;
    },
    initializeWorkspace: (
      state: WorkspaceSlice,
      action: PayloadAction<{
        organization: IOrganization;
        associatedMembership: string;
      }>
    ) => {
      const { organization, associatedMembership } = action.payload;
      return {
        organization,
        associatedMembership,
      };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        refreshWorkspace.fulfilled.type,
        (state: WorkspaceSlice, action: PayloadAction<IOrganization>) => {
          if (state !== null) state.organization = action.payload;
        }
      )
      .addCase(
        refreshWorkspace.rejected.type,
        (state: WorkspaceSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          console.log(`Error ${error}: ${message}`);
          return null;
        }
      ),
});

const workspaceReducer = workspaceSlice.reducer;

export const { clearWorkspace, initializeWorkspace } = workspaceSlice.actions;
export { workspaceReducer };
