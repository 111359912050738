import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FetchError,
  fetchInvites,
  ReduxInvite,
  InviteSlice,
  parseReduxDate,
  AcceptInvitePayload,
  acceptInvite,
} from "./internal";

const initialState: InviteSlice = null as InviteSlice;

const inviteSlice = createSlice({
  name: "inviteSlice",
  initialState,
  reducers: {
    clearInviteState(state) {
      return null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchInvites.fulfilled.type,
        (state: InviteSlice, action: PayloadAction<ReduxInvite[]>) => {
          const invites = action.payload;
          const currentDate = new Date();
          return {
            acceptedInvites:
              invites.filter((invite) => invite?.invite?.acceptedDate) || [],
            expiredInvites:
              invites.filter(
                (invite) =>
                  !invite?.invite?.acceptedDate &&
                  parseReduxDate(invite?.invite?.expirationDate).getTime() <
                    currentDate.getTime()
              ) || [],
            openInvites:
              invites.filter(
                (invite) =>
                  !invite?.invite?.acceptedDate &&
                  parseReduxDate(invite?.invite?.expirationDate).getTime() >
                    currentDate.getTime()
              ) || [],
          };
        }
      )
      .addCase(
        fetchInvites.rejected.type,
        (state: InviteSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          console.log(`Error ${error}: ${message}`);
          return null;
        }
      )
      .addCase(
        acceptInvite.fulfilled.type,
        (state: InviteSlice, action: PayloadAction<AcceptInvitePayload>) => {
          if (state) {
            const { updatedInvite } = action.payload;
            // remove invite from open list, move to accepted list
            state.openInvites = state.openInvites.filter(
              (invite) => invite.invite.id !== updatedInvite.invite.id
            );
            state.acceptedInvites = [...state.acceptedInvites, updatedInvite];
          }
        }
      )
      .addCase(
        acceptInvite.rejected.type,
        (state: InviteSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          console.log(`Error ${error}: ${message}`);
          return null;
        }
      );
  },
});

const inviteReducer = inviteSlice.reducer;
const { clearInviteState } = inviteSlice.actions;

export { inviteReducer, clearInviteState };
