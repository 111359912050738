import React from "react";
import styled, { css } from "styled-components";

export const Divider = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    background-color: ${theme.color.mainText};
    padding-left: ${theme.structure.gutter};
    padding-right: ${theme.structure.gutter};
  `
);
