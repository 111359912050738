import React from "react";
import { ManagementFrame, ManagementHeader} from "../../elements";
import styled, { css } from "styled-components";
import { SortDropdown } from "../dropdowns";
import { IDropdown } from "../componentTypes";

export interface IManagementItemProps {
  itemIndex: number;
  name: string;
  date: Date;
  status: boolean;
  dropdown: IDropdown;
  buttons: React.ReactNode;
}

export const ManagementItem = (props: IManagementItemProps) => (
  <ManagementFrame>
    <Item>{props.itemIndex}</Item>
    <ItemName>{props.name}</ItemName>
    <Item>{props.date.toLocaleDateString()}</Item>
    <Item>{props.status}</Item>
    <Item>
      <SortDropdown {...props.dropdown} />
    </Item>
    <div>{props.buttons}</div>
  </ManagementFrame>
);

const Item = styled.div(({ theme }) => css`
    color: ${theme.color.midMute};
    font-family: OpenSans-SemiBold;
`);

const ItemName = styled(Item)(({ theme }) => css`
    color: ${theme.color.mainText};
`);

export interface IManagementHeaderProps {
    categories: string[]
}

export const ManagementListHeader = (props: IManagementHeaderProps) => (
    <ManagementHeader>
        {props.categories.map(category => <div>{category}</div>)}
    </ManagementHeader>
)
