import { AnnotatedTitle } from "../../components/misc";
import { ItemList, PageContainer } from "../../elements";
import React from "react";
import { ISearchResults } from "../types";
import { ActionableCard } from "../cards";

export const SearchResults = (props: ISearchResults) => (
  <PageContainer>
    <AnnotatedTitle
      title={`${props.message}`}
      description={"Perhaps try one of our pre-built searches below?"}
      color={"mute"}
      style={{ marginBottom: "2rem", textAlign: "center" }}
    />
    <ItemList>
      {props.resultContent.map((card) => (
        <ActionableCard {...card} />
      ))}
    </ItemList>
  </PageContainer>
);
