// divider types
import React, { CSSProperties, ReactNode, SyntheticEvent } from "react";
import {
  ICircularProgress,
  IGenericButton,
  IHeroBanner,
  INavItem,
  IProgressSlider,
  IInputField,
  ISliderHeader,
  ITextColors,
  IGenericIcon,
} from "../elements";
import { ICardButton, IQuizCard } from "../elements/elementTypes";
import { PartialPath } from "history";
import { Document } from "@contentful/rich-text-types";

// divider types
export type IDivider = IGenericParagraph & {
  children?: React.ReactNode;
};

// quiz types
export interface IQuizHeader {
  quizProgress: ISlider;
  timeRemaining: ICircularProgress;
  titleData: IGenericCardHeader;
}

export interface IQuizQuestion {
  questionNumber: number;
  question: string;
}

export type IQuizAnswer = {
  answer: string;
  onClick: (e: SyntheticEvent) => void;
  annotation?: {
    isCorrect: boolean;
  };
} & IQuizCard;

export interface IQuizControls {
  buttonOne: IGenericButton & { showButton: boolean };
  buttonTwo: IGenericButton & { showButton: boolean };
}

// card lock types
export interface IGenericCardLock {
  lockMessage: string;
}

// item types
export interface IStat {
  statName: string;
  statValue: string;
  icon?: ReactNode;
  style?: CSSProperties;
}

export interface ISimpleStat {
  statValue: string;
  icon: ReactNode;
  style?: CSSProperties;
}

export interface IGenericListItem {
  icon?: ReactNode;
  text: string;
  style?: CSSProperties;
  to?: string | (PartialPath & string);
  onClick?: (e: SyntheticEvent) => void;
}

export interface IFormDropdownItem {
  text: string;
  value: string;
  onClick: (e: SyntheticEvent) => void;
}

// paragraph types
export interface IGenericParagraph {
  title: string;
  description: string | Document;
  style?: CSSProperties;
  icon?: React.ReactNode;
}

// headers types
export interface IGenericCardHeader {
  title: string;
  subTitle?: string;
  style?: CSSProperties;
}

// card types
export interface IActionableCard {
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: (e: SyntheticEvent) => void;
  redirectLink: string;
}

export interface IContentTLDRCard {
  title: string;
  timeEstimate: number;
  timeEstimateUnits: string;
  quizCount: number;
  questionCount: number;
  earnableUserPoints: number;
}

export interface IProfileTLDRCard {
  title: string;
  joinDate: Date;
  quizCount: number;
  userPointCount: number;
  contentRating?: number; // percent
  contentCompletionRate?: number; // percent
}

export interface IActivityCard {
  startDate: Date;
  headerData: IGenericCardHeader;
  cardSummary: IGenericParagraph;
  buttonData: ICardButton;
  statData: ISlider & {
    additionalStat: string;
  };
}

// profile card
export type IOrganizationCard = {
  header: IGenericCardHeader;
  buttonContent: IGenericButton;
  memberData: IGenericIcon[];
  orgIcon: IGenericIcon;
  userSubscribed: boolean;
};

// module/section card types
export type IInteractiveCard = {
  isRequired: boolean;
  entityId: string; // associates stats with DB source data
  // lock properties
  isLocked: boolean;
  lockedMessage?: string;
  // basic card info
  title: string;
  subTitle: string;
  summary: string;
  dateStarted?: Date;
  dateFinished?: Date;
  // button data
  buttonData: ICardButton;
  // module or section data (must have one or the other)
  statData?: ISectionCard | IModuleCard;
};

export type IModuleCard = {
  // module stats
  userReviewRate?: number;
  quizCount: number;
  questionCount: number;
  sectionCount: number;
  earnableUserPoints: number;
  timeToComplete: number;
  TTCUnits: string;
};

export type ISectionCard = {
  // sections stats
  sectionProgress: number;
  averageQuizScore: number;
  passed: boolean;
  retakes?: number;
  totalTime?: number;
  totalTimeUnits: string;
  averageCompletionTime?: number;
  completionTimeUnits: string;
};

// widget types
export type ISlider = IProgressSlider &
  ISliderHeader & { style?: CSSProperties };

// card control types
export type ICardControls = {
  pageCount: number;
  currentPage: number;
  clickRight: (e: SyntheticEvent) => void;
  clickLeft: (e: SyntheticEvent) => void;
  buttonData: ICardButton;
};

export type IBannerControls = {
  staticOnlyControls: boolean;
  clickRight?: (e: SyntheticEvent) => void;
  clickLeft?: (e: SyntheticEvent) => void;
  children: ReactNode;
};

// input types
export interface ISearchBar {
  searchField: IInputField;
  children: ReactNode;
}

// dropdown types
export interface IDropdown {
  title: string;
  titleIcon?: ReactNode;
  //items: IActionableItem[];
  items: IGenericListItem[];
}

// banner types
export interface IBanner {
  imageData: IHeroBanner;
  textData: IGenericCardHeader & {
    tagLine?: string;
    tagIcon?: ReactNode;
  };
  buttonData?: IGenericButton;
}

// navbar types
export interface INavItemList {
  navItems: INavItem[];
}

// messaging type
export interface IMessagingInput {
  inputTitle: string;
  searchInput: IInputField;
  buttonData: IGenericButton;
}

// tag list types
export interface ITagList {
  tags: IGenericButton[];
}

// type guards
export const isModuleType = (
  value: IModuleCard | ISectionCard
): value is IModuleCard => value.hasOwnProperty("sectionCount");

export const isProfileType = (
  value: IProfileTLDRCard | IContentTLDRCard
): value is IProfileTLDRCard => {
  return value.hasOwnProperty("joinDate");
};

export const isDocumentType = (value: string | Document): value is Document => {
  return value.hasOwnProperty("nodeType") && value.hasOwnProperty("content");
};
