import React from "react";
import styled, { css } from "styled-components";
import { IQuizCard } from "./index";

export const GenericFrame = styled.div`
  display: block;
  position: relative;
  width: 100%;
  filter: drop-shadow(0px 3px 6px rgba(42, 41, 39, 0.16));
  background-color: white;
`;

// card bodies
export const ManagementHeader = styled(GenericFrame)(({ theme }) => css`
  height: 20px;
  padding: 2rem 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: ${theme.color.mainText};
  text-color: white;
`);

export const ManagementFrame = styled(GenericFrame)`
  height: 80px;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
`;

export const TLDRFrame = styled(GenericFrame)`
  padding: 1.5rem 2rem;
  width: 300px;
`;

export const RoleFrame = styled(GenericFrame)`
  width: auto;
  min-height: 200px;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RedirectFrame = styled(GenericFrame)`
  padding: 1.5rem 2rem;
  width: 400px;
`;

export const ActivityFrame = styled(GenericFrame)`
  padding: 1.5rem 2rem;
  padding: 0;
  min-width: 400px;
  width: 400px;
  height: 375px;
`;

export const ModuleFrame = styled(GenericFrame)`
  padding: 0;
  width: 350px;
  height: 430px;
`;

export const OrganizationFrame = styled(GenericFrame)`
  padding: 1.5rem 2rem;
  width: 320px;
  height: 320px;
`;

export const QuizFrame = styled(GenericFrame)`
  padding: 2rem;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
`;

export const DropdownListFrame = styled.div(
  ({ theme }) => css`
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 1.5rem;
    width: 200px;
    filter: drop-shadow(0px 3px 6px rgba(42, 41, 39, 0.16));
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: 300;
  `
);

export const FormDropdownList = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem;
  filter: drop-shadow(0px 3px 6px rgba(42, 41, 39, 0.16));
  z-index: 300;
`;

export const QuizAnswerCard = styled(GenericFrame)<IQuizCard>(
  ({ isSelected, theme }) => css`
    width: auto;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    background-color: white;
    border: solid 2px
      ${isSelected ? theme.color.mainHighlight : theme.color.mainText};
    border-radius: 20px;
    &:hover {
      border: solid 2px ${theme.color.mainHighlight};
    }
  `
);

// specialized containers
export const NavCardBody = styled.nav(
  ({ theme }) => css`
    padding: 1.5rem ${theme.structure.gutter};
    display: flex;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(0px 3px 6px rgba(42, 41, 39, 0.16));
    z-index: 400;
  `
);

export const FooterBody = styled.footer(
  ({ theme }) => css`
    background-color: ${theme.color.mainText};
    height: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    padding-right: ${theme.structure.gutter};
    padding-left: ${theme.structure.gutter};
  `
);

export const SummaryParagraphBody = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

export const Dropdown = styled.div`
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;

export const AdminMessaging = styled.div`
  width: 400px;
`;
