import React, { useState, useEffect, useCallback, SyntheticEvent } from "react";
import {
  QuizQuestion,
  QuizAnswer,
  QuizHeader,
  QuizControls,
} from "../../components";
import { PageContainer } from "../../elements";
import { IQuiz } from "../types";
import { IResponse } from "../../../boilerplate/config/types/firebaseTypes";
import { getScore, responsesCorrect } from "../../../boilerplate/utils";

export const Quiz = (props: IQuiz) => {
  const {
    quizName,
    currentQuestionIndex,
    totalQuestionCount,
    responses,
    questions,
    onNextClick,
    onPrevClick,
    reviewQuiz,
  } = props;

  // error and answer state
  const [selectedAnswer, setAnswerSelection] = useState<string | null>(null);
  const [quizError, setQuizError] = useState<string | null>(null);
  const [disableButtons, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    // since answers and responses are parallel arrays,
    // set selected answer (if index is not greater than response list)
    let answer: string | null = null;
    if (currentQuestionIndex <= responses.length - 1)
      answer = responses[currentQuestionIndex]?.externalAnswerId || null;
    if (answer !== selectedAnswer) setAnswerSelection(answer);
    // remove errors
    setQuizError(null);
  }, [currentQuestionIndex, responses]);

  useEffect(() => {
    console.log("Props in content quiz", props);
    console.log("Quiz next func", props.onNextClick);
  });

  const handleNext = async () => {
    if (disableButtons === false) {
      setDisabled(true);
      if (selectedAnswer !== null) {
        await onNextClick({
          answerId: selectedAnswer as string,
          questionId: questions[currentQuestionIndex].sys.id,
        });
      } else setQuizError("Answer must be selected before continuing");
    }
    setDisabled(false);
  };

  const handlePrevious = () => {
    if (!disableButtons) {
      setDisabled(true);
      onPrevClick();
    }
    setDisabled(false);
  };

  const getProgress = (
    questionIndex: number,
    totalQuestions: number,
    selectedAnswer: string | null
    //responses: IResponse[],
  ): number => {
    // get question number
    // check if current question is answered
    const questionAnswered = selectedAnswer !== null;
    // add +1 to question number if current question is answered
    return Math.floor(
      ((questionAnswered ? questionIndex + 1 : questionIndex) /
        totalQuestions) *
        100
    );
  };

  const displayButton = (
    direction: "next" | "prev" | "finish",
    currentIndex: number,
    totalQuestions: number
  ): boolean => {
    switch (direction) {
      case "next":
        return currentIndex + 1 < totalQuestions;
      case "prev":
        return currentIndex > 0;
      case "finish":
        return currentIndex + 1 === totalQuestions;
      default:
        return false;
    }
  };

  return (
    <PageContainer>
      <div>
        <QuizHeader
          quizProgress={{
            progressPercent: reviewQuiz
              ? 100
              : getProgress(
                  currentQuestionIndex,
                  totalQuestionCount,
                  selectedAnswer
                ),
            sliderName: "Progress",
          }}
          timeRemaining={{
            value: 30,
            minValue: 0,
            maxValue: 100,
            timeText: "--",
          }}
          titleData={{
            title: `${quizName} ${
              reviewQuiz
                ? `- ${getScore(responses, totalQuestionCount)}%`
                : null
            }`,
            subTitle: reviewQuiz
              ? `${responsesCorrect(
                  responses
                )} of ${totalQuestionCount} Questions Correct`
              : `${
                  totalQuestionCount - (currentQuestionIndex + 1)
                } Questions Remaining`,
          }}
        />
      </div>
      <div
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      >
        <QuizQuestion
          style={{
            textAlign: "center",
            marginBottom: "2rem",
            marginTop: "2rem",
          }}
          questionNumber={currentQuestionIndex + 1}
          question={questions[currentQuestionIndex]?.fields.question || ""}
        />
        {quizError !== "" ? (
          <p style={{ margin: "1rem 0", textAlign: "center", color: "red" }}>
            {quizError}
          </p>
        ) : null}
        <div style={{ marginBottom: "1rem" }}>
          {(questions[currentQuestionIndex]?.fields?.choices || []).map(
            (choice) => {
              // check local state against choice id
              const currentSelected =
                selectedAnswer && selectedAnswer === choice.sys.id
                  ? true
                  : false;
              return (
                <QuizAnswer
                  key={choice.sys.id}
                  style={{ marginBottom: "1rem" }}
                  answer={choice?.fields.answer || ""}
                  onClick={() => {
                    if (!reviewQuiz) setAnswerSelection(choice.sys.id);
                  }}
                  isSelected={currentSelected}
                  annotation={
                    currentSelected && reviewQuiz
                      ? { isCorrect: responses[currentQuestionIndex].isCorrect }
                      : undefined
                  }
                />
              );
            }
          )}
        </div>
        <QuizControls
          buttonOne={{
            children: "Prev",
            onClick: (e: SyntheticEvent) => {
              e.preventDefault();
              handlePrevious();
            },
            showButton: displayButton(
              "prev",
              currentQuestionIndex,
              totalQuestionCount
            ),
            to: "",
          }}
          buttonTwo={{
            children: displayButton(
              "next",
              currentQuestionIndex,
              totalQuestionCount
            )
              ? "Next"
              : "finish",
            onClick: async (e: SyntheticEvent) => {
              e.preventDefault();
              console.log("next has fired in content/quiz");
              await handleNext();
            },
            showButton:
              displayButton("next", currentQuestionIndex, totalQuestionCount) ||
              displayButton("finish", currentQuestionIndex, totalQuestionCount),
            to: "",
          }}
        />
      </div>
    </PageContainer>
  );
};
