import React, { SyntheticEvent } from "react";
import { LinkableDropdownItem, DropdownItem, ItemBody } from "../../elements";
import {
  //IActionableItem,
  IGenericListItem,
} from "../index";

export const RedirectDropdownItem = (props: IGenericListItem) => (
  <ItemBody style={props.style}>
    <div style={{ marginRight: "1rem" }}>{props.icon}</div>
    <LinkableDropdownItem to={props.to ? props.to : ""} onClick={props.onClick}>
      {props.text}
    </LinkableDropdownItem>
  </ItemBody>
);

export const ActionableDropdownItem = (props: IGenericListItem) => (
  <ItemBody style={props.style} onClick={props.onClick}>
    <div style={{ marginRight: "1rem" }}>{props.icon}</div>
    <DropdownItem>{props.text}</DropdownItem>
  </ItemBody>
);
