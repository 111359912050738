import { IModuleData, isModuleArrayType } from "../types";
import { useFirebaseContext } from "../../providers/FirebaseProvider";
import { useContentful } from "../../providers/ContentfulProvider";
import { useEffect, useState } from "react";
import {
  fetchCertificates,
  fetchModuleById,
  fetchOrganizationByModuleId,
  filterCertificatesBySections,
  generateModuleStats,
  getBeginEndDatesFromCertificates,
  getRequiredSections,
} from "../../utils";
import { IMember, IModule } from "../../config/types/firebaseTypes";
import { IInteractiveCard } from "../../../UIComponents/components";
import { useNavigate } from "react-router-dom";
import { ContentfulHelper } from "../../clients/contentful";
import { ROUTES } from "../../config/routing/routes";
import { isModuleType } from "./types";

export const useModule = (moduleId: string): [IModuleData | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  const contentfulHelper = useContentful();
  // module state
  const [module, setModule] = useState<IModuleData | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    let cancelOperation = false;
    let statePayload: IModuleData | null = null;
    if (firebaseHelper && contentfulHelper && moduleId.length > 0) {
      setLoadingStatus(true);
      (async () => {
        // determine parameter type, fetch data from correct source
        const fetchedModule = await fetchModuleById(
          firebaseHelper,
          moduleId
        ); /*isModuleParam(params)
          ? await fetchModuleById(firebaseHelper, params.firebaseModuleId)
          : await fetchModuleByExternalSectionId(
              firebaseHelper,
              contentfulHelper,
              params.sectionId
            );*/
        if (fetchedModule) {
          // fetch organization by module
          const [organization, moduleContent] = await Promise.all([
            fetchOrganizationByModuleId(fetchedModule.id, firebaseHelper),
            contentfulHelper.getModuleById(fetchedModule.externalModuleId),
          ]);
          statePayload = organization
            ? {
                ...fetchedModule,
                content: moduleContent.fields,
                parentOrganizationId: organization.id,
              }
            : null;
        }
        if (!cancelOperation) {
          // set payload and loading to false
          setModule(statePayload);
          setLoadingStatus(false);
        }
      })();
    } else {
      setLoadingStatus(false);
      setModule(null);
    }

    return () => {
      cancelOperation = true;
    };
  }, [moduleId, firebaseHelper, contentfulHelper]);

  return [module, isLoading];
};

export const useModuleStats = (
  externalSpaceId: string | undefined,
  firebaseModules: string[] | IModule[] | null,
  membership: IMember | null,
  filterByRole: boolean = false
): [IInteractiveCard[] | null, boolean] => {
  const navigate = useNavigate();
  const firebaseHelper = useFirebaseContext();
  // module state
  const [cards, setCards] = useState<IInteractiveCard[] | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    let cancelOperation = false;
    if (
      //membership &&
      firebaseHelper &&
      externalSpaceId !== undefined &&
      firebaseModules &&
      firebaseModules.length > 0
    ) {
      setLoadingStatus(true);
      (async () => {
        const contentfulClient = new ContentfulHelper(
          externalSpaceId,
          process.env.REACT_APP_CDA_TOKEN || ""
        );

        // fetch firebase modules, determine if list of IDs or actual module object array
        // fetch content modules
        const firebaseModule = (!isModuleArrayType(firebaseModules)
          ? (
              await Promise.all(
                firebaseModules.map((moduleId) =>
                  fetchModuleById(firebaseHelper, moduleId)
                )
              )
            )
              .filter((module) => module)
              .map((module) => module as IModule)
          : firebaseModules
        ).filter((module) =>
          filterByRole && membership
            ? memberCanAccessModule(module, membership)
            : true
        );
        const contentModules = await Promise.all(
          firebaseModule.map((module) =>
            contentfulClient.getModuleById(module.externalModuleId)
          )
        );
        1;
        // generate stats from parallel firebase and contentful module arrays
        // parse out all sections from content modules
        const contentStats = await Promise.all(
          firebaseModule.map((module) =>
            generateModuleStats(module, firebaseHelper, contentfulClient)
          )
        );
        const sections = contentModules.flatMap(
          (module) => module.fields.sections || []
        );
        // filter user certificates
        const memberCertificates = filterCertificatesBySections(
          (await fetchCertificates(
            firebaseHelper,
            membership ? membership.contentCertificates : []
          )) || [],
          sections
        );

        // map all preliminary module stats array items to interactive cards
        const moduleCards = contentStats.map((moduleStats, index) => {
          // get content/firebase module and stats
          const module = firebaseModule[index];
          const contentModule = contentModules[index];
          const moduleSections = getRequiredSections(
            contentModule.fields.sections || []
          );
          // filter out certificates specific to current module
          const certificates =
            membership === null
              ? []
              : filterCertificatesBySections(
                  memberCertificates,
                  moduleSections
                );
          // start/end dates (sort from start-end and select first element)
          const [startDate, endDate] = getBeginEndDatesFromCertificates(
            certificates,
            moduleSections.length
          );
          const { title, subTitle, summary, isLocked, ...stats } = moduleStats;
          // determine button type and text to be displayed
          const buttonType = startDate
            ? endDate
              ? "review"
              : "continue"
            : "begin";
          const interactiveStats: IInteractiveCard = {
            isLocked: false,
            entityId: module.id,
            isRequired: isLocked,
            lockedMessage: isLocked
              ? "Previous Module Must Be Completed"
              : undefined,
            title,
            subTitle,
            summary,
            dateStarted: startDate,
            dateFinished: endDate,
            statData: stats,
            buttonData: {
              children: buttonType,
              onClick: (e) => {
                e.preventDefault();
                // navigate to module view page on click
                navigate(ROUTES.MODULE.replace(":id", module.id));
              },
              to: "",
              buttonType,
            },
          };
          return interactiveStats;
        });
        // set state
        if (!cancelOperation) {
          setCards(moduleCards);
          setLoadingStatus(false);
        }
      })();
    } else {
      setLoadingStatus(false);
      setCards(null);
    }
    return () => {
      cancelOperation = true;
    };
  }, [firebaseModules, externalSpaceId, membership, firebaseHelper]);

  return [cards, isLoading];
};

const memberCanAccessModule = (module: IModule, member: IMember): boolean => {
  for (const role of member.roles) {
    const hasAccess: boolean = member.roles.includes(role);
    if (hasAccess) return true;
  }
  return false;
};
