import React, { useEffect, useState } from "react";
import { InputForm, InputItem } from "../containers/auth";
import {
  createInviteInputs,
  formatRoleName,
  handleInput,
  InviteState,
  trimInput,
} from "../../boilerplate/utils";
import { useRoles } from "../../boilerplate/hooks/role/role";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";
import { FetchError, WorkspaceSlice } from "../../boilerplate/redux/reduxTypes";
import { IMember, IRole } from "../../boilerplate/config/types";
import { IFormDropdownItem } from "../components";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { AppDispatch } from "../../boilerplate/providers/StoreProvider";
import { createAnInvite } from "../../boilerplate/redux/userData";
import { PageContainer } from "../elements";
import { ActionableCard } from "../containers/cards";

const initialState = {
  email: "",
  adminStatus: false,
  roleId: "",
};

export default () => {
  // state
  const [error, setError] = useState<string | null>(null);
  const [formInput, setFormInput] = useState<InviteState>(initialState);
  const [showSuccess, setSuccess] = useState<boolean>(false);

  // redux
  const workspace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;

  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const firebase = useFirebaseContext();
  const [roles, loading] = useRoles(workspace?.organization?.id || "");

  useEffect(() => {
    console.group("-----");
    console.log("Role data is", roles);
    console.log("Role data loading", loading);
    console.groupEnd();
  });

  const handleSubmit = async (): Promise<void> => {
    if (!firebase) {
      console.log("Firebase helper cannot be null");
      return;
    }
    // trim input
    const trimmedInput = trimInput<InviteState>(formInput);
    const result = await dispatch(
      createAnInvite({
        firebaseHelper: firebase,
        email: trimmedInput.email,
        roleId: trimmedInput.roleId,
      })
    );
    if (!result.payload) {
      setError("Error fulfilling request");
      console.log("Payload error");
    } else {
      const payload:
        | FetchError
        | {
            updatedMembership: IMember | undefined;
          } = result.payload as
        | FetchError
        | { updatedMembership: IMember | undefined };
      if (payload.hasOwnProperty("error")) {
        console.log("An error has occured", payload);
        setError((payload as FetchError).message);
      } else {
        if (error) setError(null);
        console.log(`Invite sent to ${trimmedInput.email}`);
      }
    }
  };

  const mapRolesToItems = (roles: IRole[]): IFormDropdownItem[] =>
    roles.map((role) => ({
      text: role.roleName,
      value: role.id,
      onClick: () => {},
    }));

  const mergeInputsWithRoles = (
    roles: IRole[],
    inputs: InputItem[],
    mergeIndex: number
  ) => {
    let updatedInputs = [...inputs];
    const dropdownItems = mapRolesToItems(roles);
    const dropdownToMerge = updatedInputs[mergeIndex].dropdown;
    if (dropdownToMerge)
      updatedInputs[mergeIndex].dropdown = {
        ...dropdownToMerge,
        items: dropdownItems,
      };
    return updatedInputs;
  };

  return (
    <>
      {showSuccess ? (
        <PageContainer>
          <ActionableCard
            title={"Invite Sent!"}
            description={`Your invite to "${formInput.email}" was successful`}
            buttonText={"Create Another Invite"}
            onButtonClick={() => {
              // reset invite state, set success to false
              setFormInput(initialState);
              setSuccess(false);
            }}
            redirectLink={""}
          />
        </PageContainer>
      ) : (
        <InputForm
          inputFields={mergeInputsWithRoles(roles || [], createInviteInputs, 2)}
          onSubmit={handleSubmit}
          onInput={(e, key) => {
            handleInput<InviteState>(e, key, setFormInput);
          }}
          formError={error}
          title={"Send an Invite"}
          subHeader={"Invite a new prospective member"}
          buttonText={"Send Invite"}
        />
      )}
    </>
  );
};
