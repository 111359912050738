import React from "react";
import CommunityHub from "../../../UIComponents/page/CommunityHub";
import Dashboard from "../../../UIComponents/page/Dashboard";
import Module from "../../../UIComponents/page/Module";
import TrackHome from "../../../UIComponents/page/TrackHome";
import Profile from "../../../UIComponents/page/Profile";
import ProfileSettings from "../../../UIComponents/page/ProfileSettings";
import Quiz from "../../../UIComponents/page/Quiz";
import Section from "../../../UIComponents/page/Section";
import Login from "../../../UIComponents/page/Login";
import Signup from "../../../UIComponents/page/Signup";
import Organization from "../../../UIComponents/page/Organization";
import { Route, Routes } from "react-router-dom";
import WorkspaceHub from "../../../UIComponents/page/WorkspaceHub";
import AcceptInvite from "../../../UIComponents/template/AcceptInvite";
import WorkspaceSearch from "../../../UIComponents/template/WorkspaceSearch";
import WorkspaceSelection from "../../../UIComponents/template/WorkspaceSelection";
import NotFound from "../../../UIComponents/page/NotFound";
import CreateInvite from "../../../UIComponents/page/UserManagement";

export interface IRoute {
  ShowOnNavbar: boolean;
  DisplayName: string;
  Component: React.ReactNode; //React.ElementType;
  ChildComponent?: React.ReactNode;
  RouteName?: string;
  SigninRequired: boolean;
}

export const ROUTES = {
  DASHBOARD: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  ORGANIZATION: "/organization/:id",
  // --------
  WORKSPACE_MAIN: "/workspace",
  WORKSPACE_SUB_SEARCH: "search",
  WORKSPACE_SUB_INVITE: "invites",
  // --------
  ADMIN_SEND_INVITE: "/admin/createInvite",
  // --------
  COMMUNITY_HUB: "/community",
  TRACK_HOME: "/track/:trackId",
  MODULE: "/module/:id",
  SECTION: "/module/:moduleId/section/:sectionId",
  QUIZ: "/quiz/:certificateId/session/:sessionId",
  PROFILE: "/profile/:id",
  PROFILE_SETTINGS: "/profile/settings",
  NOT_FOUND: "*",
};

export const mappedRoutes = [
  {
    ShowOnNavbar: false,
    DisplayName: "Send Invite",
    RouteName: ROUTES.ADMIN_SEND_INVITE,
    Component: <CreateInvite />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Not Found",
    RouteName: ROUTES.NOT_FOUND,
    Component: <NotFound />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Signup",
    RouteName: ROUTES.SIGNUP,
    Component: <Signup />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Login",
    RouteName: ROUTES.LOGIN,
    Component: <Login />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Organization",
    RouteName: ROUTES.ORGANIZATION,
    Component: <Organization />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Organization Selection",
    RouteName: ROUTES.WORKSPACE_MAIN,
    Component: <WorkspaceHub />,
    ChildComponent: (
      <>
        <Route path={ROUTES.WORKSPACE_SUB_INVITE} element={<AcceptInvite />} />
        <Route
          path={ROUTES.WORKSPACE_SUB_SEARCH}
          element={<WorkspaceSearch />}
        />
        <Route path={""} element={<WorkspaceSelection />} />
      </>
    ), //<OrganizationSelection />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: true,
    DisplayName: "Dashboard",
    RouteName: ROUTES.DASHBOARD,
    Component: <Dashboard />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: true,
    DisplayName: "Community Hub",
    RouteName: ROUTES.COMMUNITY_HUB,
    Component: <CommunityHub />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Track Home",
    RouteName: ROUTES.TRACK_HOME,
    Component: <TrackHome />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Module",
    RouteName: ROUTES.MODULE,
    Component: <Module />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Section",
    RouteName: ROUTES.SECTION,
    Component: <Section />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Quiz",
    RouteName: ROUTES.QUIZ,
    Component: <Quiz />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Profile",
    RouteName: ROUTES.PROFILE,
    Component: <Profile />,
    SigninRequired: true,
  },
  {
    ShowOnNavbar: false,
    DisplayName: "Profile Settings",
    RouteName: ROUTES.PROFILE_SETTINGS,
    Component: <ProfileSettings />,
    SigninRequired: true,
  },
] as IRoute[];
