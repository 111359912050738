import { IRole } from "../../config/types";
import { useFirebaseContext } from "../../providers/FirebaseProvider";
import { useEffect, useState } from "react";
import { fetchOrganizationById } from "../../redux/invite/internal";
import { fetchRoleById } from "../../utils";

export const useRoles = (organizationId: string): [IRole[] | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  const [roles, setRoles] = useState<IRole[] | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    let cancelTask = false;
    if (firebaseHelper) {
      setLoadingStatus(true);
      (async () => {
        // fetch roles by org
        const organization = await fetchOrganizationById(
          organizationId,
          firebaseHelper
        );
        const roles = await Promise.all(
          (organization?.roles || []).map((roleId) =>
            fetchRoleById(firebaseHelper, roleId)
          )
        );
        if (!cancelTask) {
          // filter out nulls
          setLoadingStatus(false);
          setRoles(roles.filter((role) => role).map((role) => role as IRole));
        }
      })();
    }

    return () => {
      cancelTask = true;
    };
  }, [firebaseHelper, organizationId]);

  return [roles, isLoading];
};
