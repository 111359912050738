import React, {
  ReactNode,
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSiginLogToUser,
  fetchUserMemberships,
  clearUserState,
  fetchUserAppData,
} from "../redux/userData";
import { AppDispatch } from "./StoreProvider";
import { useFirebaseContext } from "./FirebaseProvider";
import { User } from "@firebase/auth-types";
import { RootState } from "../redux/setup";
import { UserDataSlice, WorkspaceSlice } from "../redux/reduxTypes";
import { clearModuleState } from "../redux/module";
import { clearOrganizationState } from "../redux/organization";
import { clearWorkspace, refreshWorkspace } from "../redux/workspace";
import debounce from 'debounce'

const AuthenticationContext = createContext<User | null>(null);

export default (props: { children: ReactNode }) => {
  // provider hooks
  const firebaseHelper = useFirebaseContext();
  const dispatch = useDispatch<AppDispatch>();

  // local state
  const [authHandlerInitialized, setHandlerInitialized] = useState<boolean>(
    false
  );
  const [user, setUser] = useState<User | null>(null);

  // redux data
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;
  const workspace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;

  const handleAuthChange = useCallback(
    async (authUser: User | null): Promise<void> => {
      console.log("Auth provider fired, user is:", authUser);
      // check if auth user is not null
      if (authUser) {
        // get signin time, check diff in minutes
        const { issuedAtTime } = await authUser?.getIdTokenResult();
        const timeSignLogin: number =
          (new Date().getTime() - new Date(issuedAtTime).getTime()) / 1000;
        // sign out if idle for 60 minutes
        if (timeSignLogin > 60) await firebaseHelper?.auth.signOut();
        else if (
          authUser.uid !== user?.uid ||
          Date.now() - (userData?.signInTime || 0) >=
            1000 * 60 * 60 * 24 * 2
        ) {
          // don't re-fetch data that's already handled by redux persist
          // perform initial fetch (user data, memberships, active quizzes) if the user hasn't signed in yet
          if (firebaseHelper) {
            const requestParams = {
              requestedEntityId: authUser.uid,
              firebaseHelper,
            };
            if (workspace) dispatch(refreshWorkspace({firebaseHelper}))
            await dispatch(fetchUserAppData(requestParams));
            await dispatch(fetchUserMemberships(firebaseHelper));
            dispatch(addSiginLogToUser(requestParams));
          }
        }
      } else if (!authUser) {
        console.log('Auth user is nulll')
        // clear user app data, set user back to null, reset firebase to clear session
        dispatch(clearUserState());
        dispatch(clearOrganizationState());
        dispatch(clearWorkspace());
        dispatch(clearModuleState());
      }
      setUser(authUser);
    },
    [firebaseHelper, user, userData, workspace]
  );

  useEffect(() => {
    // fires when firebase helper classes is set in context
    if (firebaseHelper && !authHandlerInitialized) {
      // listen to sign-in/sign-out events
      firebaseHelper.auth.onAuthStateChanged(debounce(handleAuthChange, 300));
      setHandlerInitialized(true);
    }
  }, [firebaseHelper, authHandlerInitialized]);

  return (
    <AuthenticationContext.Provider value={user}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthenticationContext);

// https://blog.logrocket.com/user-authentication-firebase-react-apps/
