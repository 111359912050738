import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  contentFiltersIds,
  fetchModulesByOrganization,
  getNonQuizzableSections,
  getQuizzableSections,
  IContentfulHelper,
  IFirebaseHelper,
  IModule,
  isModuleRequired,
  RootState,
  searchExistingModules,
  sortIds,
} from "./internal";
import { isFetchError } from "../reduxTypes";

export const applyRoleFilter = (roleId: string, modules: IModule[]) =>
  modules.filter((module) => module.associatedRoles.includes(roleId));

export const applyGeneralSort = (
  sortID: string,
  modules: IModule[]
): IModule[] => {
  const { POPULAR, OLDEST, NEWEST, HIGHEST_RATED } = sortIds;
  if (sortID === POPULAR) {
    // order based on view count, displaying highest view counts first
    return modules.sort(
      (itemOne, itemTwo) => itemTwo.viewCount - itemOne.viewCount
    );
  } else if (sortID === OLDEST) {
    // order based on join date (lowest values first)
    return modules.sort(
      (itemOne, itemTwo) =>
        itemOne.addedOn.getTime() - itemTwo.addedOn.getTime()
    );
  } else if (sortID === NEWEST) {
    // order based on join date (highest values first)
    return modules.sort(
      (itemOne, itemTwo) =>
        itemTwo.addedOn.getTime() - itemOne.addedOn.getTime()
    );
  } else {
    // TODO: order based on aggregated review score
    return modules;
  }
};

const applyGeneralFilter = async (
  contentfulClient: IContentfulHelper,
  filterID: string,
  modules: IModule[]
): Promise<IModule[]> => {
  const {
    //ALL,
    REQUIRED,
    NON_REQUIRED,
    QUIZZABLE,
    NON_QUIZZABLE,
    MY_ORGANIZATIONS,
  } = contentFiltersIds;
  // fetch module content
  const contentfulModules = await Promise.all(
    modules.map((module) =>
      contentfulClient.getModuleById(module.externalModuleId)
    )
  );
  // filter out content ids based on filter param
  const filteredIds = contentfulModules
    .filter((module) => {
      const sections = module.fields.sections;
      if (filterID === REQUIRED) {
        return isModuleRequired(module);
      } else if (filterID === NON_REQUIRED) {
        return !isModuleRequired(module);
      } else if (filterID === QUIZZABLE) {
        return sections ? getQuizzableSections(sections).length > 0 : false;
      } else if (filterID === NON_QUIZZABLE) {
        return sections ? getNonQuizzableSections(sections).length > 0 : false;
      } else {
        return true;
      }
    })
    .map((module) => module.sys.id);
  return modules.filter((module) =>
    filteredIds.includes(module.externalModuleId)
  );
};

export const filterModules = createAsyncThunk(
  "moduleGridSlice/filterModules",
  async (
    params: { contentfulClient: IContentfulHelper; filterId: string },
    thunkAPI
  ) => {
    const { contentfulClient, filterId } = params;
    const workspace = (thunkAPI.getState() as RootState).workspace;
    const {
      modules,
      itemSortID,
      roleId,
      searchTerm,
    } = (thunkAPI.getState() as RootState).moduleSearch;
    if (workspace !== null) {
      // apply general content filter
      let filteredModules = await applyGeneralFilter(
        contentfulClient,
        filterId,
        roleId ? applyRoleFilter(roleId, modules) : modules
      );
      // check and apply search filter
      if (searchTerm.trim().length > 0)
        filteredModules = await searchExistingModules(
          filteredModules,
          contentfulClient,
          searchTerm
        );
      // perform sort on newly filtered modules
      return {
        filterId,
        filteredModules: applyGeneralSort(itemSortID, filteredModules),
      };
    }
    thunkAPI.rejectWithValue({
      error: "Workspace",
      message: "Organization not set in global store",
    });
  }
);

export const filterModulesBySearch = createAsyncThunk(
  "moduleGridSlice/fetchModulesBySearch",
  async (
    params: {
      contentfulClient: IContentfulHelper;
    },
    thunkAPI
  ) => {
    const { contentfulClient } = params;
    const {
      modules,
      searchTerm,
      itemSortID,
      itemFilterID,
      itemLimit,
    } = (thunkAPI.getState() as RootState).moduleSearch;
    try {
      // fetch all modules based on search term
      const searchResults = await searchExistingModules(
        modules,
        contentfulClient,
        searchTerm
      );
      // sort/filter results
      const filteredResults = await applyGeneralFilter(
        contentfulClient,
        itemFilterID,
        applyGeneralSort(itemSortID, searchResults)
      );
      return {
        filteredModules: filteredResults,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.error,
        message: err.message,
      });
    }
  }
);

// load more modules given a pre-existing set of search criteria
export const loadMoreModules = createAsyncThunk(
  "moduleGridSlice/loadMoreSearchedModules",
  async (
    params: {
      contentfulClient: IContentfulHelper;
    },
    thunkAPI
  ) => {
    const { contentfulClient } = params;
    const {
      searchTerm,
      itemSortID,
      itemFilterID,
      itemLimit,
      modules,
    } = (thunkAPI.getState() as RootState).moduleSearch;
    try {
      // set new item limits and fetch all modules based on search term
      const newLimit = itemLimit + 50;
      const searchResults = await searchExistingModules(
        modules,
        contentfulClient,
        searchTerm
      );
      // sort new results
      const filteredResults = await applyGeneralFilter(
        contentfulClient,
        itemFilterID,
        applyGeneralSort(itemSortID, searchResults)
      );
      return {
        itemLimit: newLimit,
        filteredModules: filteredResults,
      };
    } catch (err) {
      return {
        error: err.error,
        message: err.message,
      };
    }
  }
);

// fetches all modules associated with an organization, but does not filter/sort them
export const fetchModules = createAsyncThunk(
  "moduleGridSlice/fetchModules",
  async (
    params: { firebaseHelper: IFirebaseHelper; organizationId: string },
    thunkAPI
  ) => {
    const { firebaseHelper, organizationId } = params;
    try {
      // fetch all modules based on organization
      const modules = await fetchModulesByOrganization(
        firebaseHelper,
        organizationId,
        0
      );
      if (isFetchError(modules)) thunkAPI.rejectWithValue(modules);
      else return modules;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
