interface ISortItem {
  id: string;
  displayName: string;
}

// ids
export const sortIds = {
  POPULAR: "POPULAR",
  NEWEST: "NEWEST",
  OLDEST: "OLDEST",
  HIGHEST_RATED: "HIGHEST_RATED",
};

export const contentFiltersIds = {
  ALL: "ALL",
  REQUIRED: "REQUIRED",
  NON_REQUIRED: "NON_REQUIRED",
  QUIZZABLE: "QUIZZABLE",
  NON_QUIZZABLE: "NON_QUIZZABLE",
  MY_ORGANIZATIONS: "MY_ORGANIZATIONS",
};

// sorts with correct display name
export const baseSorts: ISortItem[] = [
  {
    id: sortIds.POPULAR,
    displayName: "Popular",
  },
  {
    id: sortIds.NEWEST,
    displayName: "Newest",
  },
  {
    id: sortIds.OLDEST,
    displayName: "Oldest",
  },
  {
    id: sortIds.HIGHEST_RATED,
    displayName: "Highest Rated",
  },
];

export const contentFilters: ISortItem[] = [
  {
    id: contentFiltersIds.ALL,
    displayName: "All",
  },
  {
    id: contentFiltersIds.REQUIRED,
    displayName: "Required",
  },
  {
    id: contentFiltersIds.NON_REQUIRED,
    displayName: "Non-Required",
  },
  {
    id: contentFiltersIds.QUIZZABLE,
    displayName: "Quizzable",
  },
  {
    id: contentFiltersIds.NON_QUIZZABLE,
    displayName: "Non-Quizzable",
  },
];
