import React from "react";
import { IGenericCardHeader } from "../index";
import { BannerBase, BannerTitle, BannerSubTitle } from "../../elements";

export const BannerCardHeader = (props: IGenericCardHeader) => (
  <BannerBase style={props.style}>
    <BannerTitle>{props.title}</BannerTitle>
    <BannerSubTitle>{props.subTitle}</BannerSubTitle>
  </BannerBase>
);
