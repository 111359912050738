// async thunks
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchError,
  fetchOrganizationById,
  IFirebaseHelper,
  IInvite,
  IMember,
  IOrganization,
  IUserData,
  ReduxInvite,
  RootState,
} from "./internal";

export const fetchInvites = createAsyncThunk(
  "inviteSlice/fetchInvites",
  async (
    params: {
      firebaseHelper: IFirebaseHelper;
    },
    thunkAPI
  ) => {
    const { firebaseHelper } = params;
    const { userData } = thunkAPI.getState() as RootState;
    const authUser = firebaseHelper.auth.currentUser;
    if (userData && authUser) {
      // fetch invite docs
      const inviteDocs = await firebaseHelper
        .invites()
        .where("userEmail", "==", authUser.email || "")
        .get();
      const invites = inviteDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as IInvite)
      );
      // filter out repeats and fetch organizations (based on invites)
      const organizations = await Promise.all(
        invites
          .map((invite) => invite.workspaceId)
          .filter((orgId, index, arr) => arr.indexOf(orgId) === index)
          .map((orgId) => fetchOrganizationById(orgId, firebaseHelper))
      );
      // package invite objects
      return invites
        .map((invite) => {
          const organization = organizations.find(
            (org) => org?.id === invite.workspaceId
          );
          if (organization)
            return {
              invite,
              organization,
            } as ReduxInvite;
          return null;
        })
        .filter((invite) => invite)
        .map((invite) => invite as ReduxInvite);
    }
    return thunkAPI.rejectWithValue({
      error: "User data unavailable",
      message:
        "User data must be loaded into redux before requesting user invites",
    } as FetchError);
  }
);

export const acceptInvite = createAsyncThunk(
  "",
  async (
    params: {
      firebaseHelper: IFirebaseHelper;
      inviteId: string;
    },
    thunkAPI
  ) => {
    const { invites, userData } = thunkAPI.getState() as RootState;
    const { firebaseHelper, inviteId } = params;
    // check user data
    if (!userData)
      return thunkAPI.rejectWithValue({
        message: "Null user",
        error: "User data must be present before accepting invite",
      } as FetchError);
    // find invite in redux
    const foundInvite = invites?.openInvites.find(
      (invite) => invite.invite.id === inviteId
    );
    if (foundInvite) {
      const inviteDoc = firebaseHelper.invites().doc(inviteId);
      const organizationDoc = firebaseHelper
        .organizations()
        .doc(foundInvite.invite.workspaceId);
      const userDoc = firebaseHelper.users().doc(userData.appData.id);
      const memberDoc = firebaseHelper.members().doc();
      const transaction = await firebaseHelper.firestore.runTransaction(
        async (transaction) => {
          // setup new membership
          // add membership to organization
          // add membership to user
          // add receiver user id to invite
          // update all entities
          const organization = (
            await transaction.get(organizationDoc)
          ).data() as IOrganization;
          const updatedInvite: IInvite = {
            ...foundInvite.invite,
            acceptedDate: new Date(),
            userReceiverId: userData.appData.id,
          } as IInvite;
          const updatedOrganization: IOrganization = {
            ...organization,
            members: [...organization.members, memberDoc.id],
          };
          const updatedUser: IUserData = {
            ...userData.appData,
            memberships: [...userData.appData.memberships, memberDoc.id],
          };
          const newMembership: IMember = {
            id: memberDoc.id,
            isOwner: false,
            isAdmin: false,
            registryDate: new Date(),
            profileImage:
              "https://cdn.imgbin.com/19/18/7/imgbin-user-profile-computer-icons-avatar-profile-s-free-g3HKgd4wAe6e2RSv0PJ77dVRv.jpg",
            userPoints: 0,
            contentVisits: [],
            createdInvites: [],
            roles: [foundInvite.invite.assignedRoleId],
            moduleReviews: [],
            contentCertificates: [],
            likedContent: [],
          };
          transaction.update(inviteDoc, updatedInvite);
          transaction.update(organizationDoc, updatedOrganization);
          transaction.update(userDoc, updatedUser);
          transaction.set(memberDoc, newMembership);
          return {
            updatedInvite,
            updatedOrganization,
            updatedUser,
            newMembership,
          };
        }
      );
      const { updatedInvite, ...rest } = transaction;
      return {
        updatedInvite: {
          invite: updatedInvite,
          organization: foundInvite.organization,
        } as ReduxInvite,
        ...rest,
      };
    } else
      return thunkAPI.rejectWithValue({
        message: "Invite Not Found",
        error: `Invite with ID of '${inviteId}' is not in list of open invites`,
      } as FetchError);
  }
);
