import React, { ReactNode } from "react";
import { FailBadge, PassBadge, RequiredBadge } from "../../elements";
import styled from "styled-components";

const PositionedOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
`;

export const RequireBadgeOverlay = () => (
  <PositionedOverlay>
    <RequiredBadge />
  </PositionedOverlay>
);

export const PassedBadgeOverlay = (props: { didPass: boolean }) => (
  <PositionedOverlay>
    {props.didPass ? <PassBadge /> : <FailBadge />}
  </PositionedOverlay>
);
