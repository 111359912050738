import React, { CSSProperties, SyntheticEvent } from "react";
import { NavCardBody, IGenericButton, LightBoxButton } from "../../elements";
import { IGenericCardHeader } from "../componentTypes";
import { LightHeader } from "./types";
import { CSSProp } from "styled-components";

export const OrganizationCardHeader = (props: IGenericCardHeader) => (
  <div style={props.style}>
    <p>{props.subTitle}</p>
    <h3>{props.title}</h3>
  </div>
);

export const LightPageHeader = (
  props: LightHeader & { style?: CSSProperties }
) => (
  <NavCardBody style={{ display: "block", ...props.style }}>
    <h2>{props.title}</h2>
    <p style={{ marginBottom: "1rem" }}>{props.description}</p>
    {props.buttons.map((button) => (
      <LightBoxButton
        to={""}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          button.buttonClick();
        }}
        style={{ marginRight: "2rem" }}
      >
        {button.buttonText}
      </LightBoxButton>
    ))}
  </NavCardBody>
);
