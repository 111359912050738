import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { CollectionReference } from "@firebase/firestore-types";
import validator from "validator";
import profanityFilter from "bad-words";
import {
  IFirebaseHelper,
  IAuthResult,
  IUserCreationArgs,
  IUserData,
  CollectionTypes,
} from "../config/types/firebaseTypes";

const firebaseProdConfig: any = {
  apiKey: "AIzaSyBAaJMx-98fN_pj01JHmDGlpg4dYslHIwM",
  authDomain: "ddtsi-lms-firebase.firebaseapp.com",
  databaseURL: "https://ddtsi-lms-firebase.firebaseio.com",
  projectId: "ddtsi-lms-firebase",
  storageBucket: "ddtsi-lms-firebase.appspot.com",
  messagingSenderId: "679355230761",
  appId: "1:679355230761:web:8c1cc97e67b74c8c028a38",
  measurementId: "G-SGGFFERFG6",
};

export class FirebaseHelper implements IFirebaseHelper {
  // fields
  private readonly _firestore: firebase.firestore.Firestore;
  private readonly _auth: firebase.auth.Auth;
  private readonly _functions: firebase.functions.Functions;

  constructor() {
    // https://stackoverflow.com/questions/63654721/firebase-emulators-requests-to-local-firestore-unsuccessful
    // check if app needs to be created, or if existing one can be reused
    firebase.initializeApp(firebaseProdConfig);
    this._firestore = firebase.firestore();
    this._auth = firebase.auth();
    this._functions = firebase.functions();
    // initialize emulators
    if (process.env.NODE_ENV === "development") {
      this._auth.useEmulator("http://localhost:9099");
      this._firestore.useEmulator("localhost", 8080);
      this._functions.useEmulator("localhost", 5001);
    }
  }

  // properties
  get firestore(): firebase.firestore.Firestore {
    return this._firestore;
  }
  get auth(): firebase.auth.Auth {
    return this._auth;
  }
  get functions(): firebase.functions.Functions {
    return this._functions;
  }

  // initializer
  async init(): Promise<void> {
    // set session config
    await this._auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    // delete excess firebase apps
    if (firebase.apps.length > 1) {
      const [currApp, ...oldApps] = firebase.apps
      await Promise.all(oldApps.map(
          oldApp => oldApp.delete())
      )
    }
  }

  // collection references
  moduleReviews = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.MODULE_REVIEW
    ) as CollectionReference;
  };

  organizations = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.ORGANIZATION
    ) as CollectionReference;
  };

  modules = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.MODULE
    ) as CollectionReference;
  };

  roles = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.ROLE
    ) as CollectionReference;
  };

  permissions = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.PERMISSION
    ) as CollectionReference;
  };

  contentVisits = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.CONTENT_VISIT
    ) as CollectionReference;
  };

  users = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.USER
    ) as CollectionReference;
  };

  invites = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.INVITE
    ) as CollectionReference;
  };

  members = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.MEMBER
    ) as CollectionReference;
  };

  responses = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.RESPONSE
    ) as CollectionReference;
  };

  quizSessions = (): CollectionReference => {
    return this._firestore.collection(
      CollectionTypes.QUIZ_SESSION
    ) as CollectionReference;
  };

  completionCertificates = (): CollectionReference => {
    return this._firestore.collection(
      "completionCertificate"
    ) as CollectionReference;
  };

  // authentication functions
  standardSignIn = async (
    email: string,
    password: string
  ): Promise<IAuthResult> => {
    let errMsg: string | null = null;
    try {
      // login user
      const credentials = await this._auth.signInWithEmailAndPassword(
        email.trim(),
        password.trim()
      );
    } catch (err) {
      errMsg = `Sign in error '${err.code}': ${err.message}`;
    }
    if (errMsg !== null) return { success: false, error: errMsg };
    return { success: true };
  };

  createNewUser = async (args: IUserCreationArgs): Promise<IAuthResult> => {
    const {
      username,
      password,
      email,
      phoneNumber,
      firstName,
      lastName,
      birthDate,
    } = args;
    let errMsg: string | null = null;
    // check for errors
    const isUserAdult = this.isValidBirthDate(birthDate);
    const areNamesValid = this.areNamesValid([firstName, lastName]);
    const isUsernameProfane = this.isUsernameProfane(username);
    const isPhoneNumValid = this.isPhoneNumberValid(phoneNumber);
    const [isEmailReal, isUsernameUnique] = await Promise.all([
      this.isEmailReal(email),
      this.isUsernameTaken(username),
    ]);
    if (isUserAdult) {
      if (password.length >= 6) {
        if (this.isValidPassword(password)) {
          if (areNamesValid) {
            if (!isUsernameProfane) {
              if (isPhoneNumValid) {
                if (isEmailReal) {
                  if (isUsernameUnique) {
                    try {
                      // setup auth user
                      // create new application user with auth user id
                      // todo: associate phone number with account
                      const userCreds = await this._auth.createUserWithEmailAndPassword(
                        email.trim(),
                        password.trim()
                      );
                      try {
                        // set display name
                        await userCreds.user?.updateProfile({
                          displayName: username,
                        });
                        // create user app data
                        const appUserData: IUserData = {
                          id: userCreds.user?.uid || "",
                          firstName,
                          lastName,
                          birthDate,
                          signupDate: new Date(),
                          loginLog: [],
                          memberships: [],
                        };
                        await this.users()
                          .doc(userCreds.user?.uid)
                          .set(appUserData);
                      } catch (err) {
                        // delete auth user
                        userCreds.user?.delete();
                        errMsg = `User data error '${err.code}': ${err.message}`;
                      }
                    } catch (err) {
                      errMsg = `Authentication error '${err.code}': ${err.message}`;
                    }
                  } else {
                    errMsg = "Username is already taken";
                  }
                } else {
                  errMsg = "Email is not valid";
                }
              } else {
                errMsg = "Phone number is invalid";
              }
            } else {
              errMsg = "Profanity not allowed in username";
            }
          } else {
            errMsg =
              "You first and last names cannot contain special characters (?><.&%^)";
          }
        } else {
          errMsg =
            "Password must have at least one lowercase, uppercase, and numeric character";
        }
      } else {
        errMsg = "Password needs at least 6 characters";
      }
    } else {
      errMsg = "You must be at least 13 in order to make an account";
    }

    // return error message
    if (errMsg !== null) return { success: false, error: errMsg };
    return { success: true };
  };

  // form helpers
  private isValidBirthDate = (birthDate: Date): boolean => {
    // check to see if birth day is at least 18 years away from today
    const today = new Date();
    const diffInYears = today.getFullYear() - birthDate.getFullYear();
    const diffInMonths = today.getMonth() + 1 - (birthDate.getMonth() + 1);
    const diffInDays = today.getDate() - birthDate.getDate();
    const age = Math.floor(
      diffInYears + diffInMonths / 12 + diffInDays / (12 * 30)
    );
    if (age >= 13) return true;
    return false;
  };
  private isValidPassword = (password: string): boolean => {
    // needs uppercase, lowercase, and at least 1 numeric character
    let hasUpper = false,
      hasLower = false,
      hasNumber = false,
      hasWhiteSpace = false;
    for (const char of password) {
      if (validator.isInt(char)) hasNumber = true;
      else if (validator.isUppercase(char)) hasUpper = true;
      else if (validator.isLowercase(char)) hasLower = true;
      else if (char === " ") hasWhiteSpace = true;
    }
    return hasUpper && hasLower && hasNumber && !hasWhiteSpace;
  };
  private areNamesValid = (names: string[]): boolean => {
    // check that names are at least 1 character and contain no special characters
    const validatedNames: boolean[] = [...names].map((name) =>
      validator.isAlpha(name)
    );
    return !validatedNames.includes(false);
  };
  private isUsernameProfane = (username: string): boolean => {
    // check if includes bad words
    const filter = new profanityFilter();
    return filter.isProfane(username);
  };
  private isPhoneNumberValid = (phoneNumber: string): boolean => {
    // locally validate and then with firebase
    return validator.isMobilePhone(phoneNumber, "en-US");
  };
  private isUsernameTaken = async (username: string): Promise<boolean> => {
    // check if not already taken
    const usernameConflicts = await this.users()
      .where("username", "==", username.toUpperCase())
      .get();
    if (usernameConflicts.docs.length === 0) return true;
    return false;
  };
  private isEmailReal = async (email: string): Promise<boolean> => {
    // check email validation
    if (validator.isEmail(email)) {
      //const signInMethods = await this._auth.fetchSignInMethodsForEmail(email);
      //if (signInMethods.length >= 1) return true;
      return true;
    }
    return false;
  };
}
