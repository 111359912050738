import React, { CSSProperties, ReactNode, SyntheticEvent } from "react";
import styled, { css } from "styled-components";
import {
  InputBody,
  QuickSearchBody,
  SearchIcon,
  InputField,
} from "../../elements";
import { ISearchBar } from "../componentTypes";

const AlignedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchBar = (props: ISearchBar & { style?: CSSProperties }) => (
  <InputBody style={props.style}>
    <AlignedContainer style={{ justifyContent: "flex-start", flexGrow: 2 }}>
      <SearchIcon isHighlightColor={false} style={{ marginRight: "0.5rem" }} />
      <InputField {...props.searchField} />
    </AlignedContainer>
    <AlignedContainer style={{ justifyContent: "flex-end" }}>
      {props.children}
    </AlignedContainer>
  </InputBody>
);

export const QuickSearch = (
  props: ISearchBar & { children: null; style?: CSSProperties }
) => (
  <QuickSearchBody style={props.style}>
    <SearchIcon isHighlightColor={false} style={{ marginRight: "0.5rem" }} />
    <InputField {...props.searchField} />
  </QuickSearchBody>
);
