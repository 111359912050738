import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";
import {
  QuizSlice,
  UserDataSlice,
  WorkspaceSlice,
} from "../../boilerplate/redux/reduxTypes";
import {
  CheckIcon,
  PageContainer,
  PointsIcon,
  QuestionIcon,
  RetakeIcon,
  TimeIcon,
} from "../elements";
import { Loading } from "../components/misc";
import { Quiz } from "../containers/content";
import { QuizOverview } from "../containers/cards";
import {
  decrementCurrentQuestion,
  endQuiz,
  fetchQuizData,
  incrementCurrentQuestion,
  markResponse,
  setQuestionIndex,
  setQuizReview,
} from "../../boilerplate/redux/quiz";
import { useContentful } from "../../boilerplate/providers/ContentfulProvider";
import {
  useRoles,
  useModule,
  useQuiz,
  useSection,
  useRole,
} from "../../boilerplate/hooks";
import {
  calcUserPointsBySections,
  formatRoleName,
  getScore,
  responsesCorrect,
} from "../../boilerplate/utils";
import {
  ICompletionCertificate,
  IQuizSession,
} from "../../boilerplate/config/types/firebaseTypes";
import { AppDispatch } from "../../boilerplate/providers/StoreProvider";
import { IQuiz, ISection } from "../../../@types/generated/contentful";
import { ISimpleStat } from "../components";

export default () => {
  const { certificateId, sessionId } = useParams();

  // local state
  const [showQuiz, setBeginQuiz] = useState<boolean>(false);

  // provider hooks
  const firebaseHelper = useFirebaseContext();
  const contentfulHelper = useContentful();
  const dispatch = useDispatch<AppDispatch>();

  // redux
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;
  const workSpace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;
  const quiz = useSelector<RootState>((state) => state.quiz) as QuizSlice;

  // memoized entities
  const activeMembership = useMemo(() => {
    const foundMembership = userData?.memberships?.find(
      (membership) => membership.id === workSpace?.associatedMembership
    );
    if (foundMembership) return foundMembership;
    else return null;
  }, [userData, workSpace]);
  const sectionId = useMemo(() => quiz?.certificate.externalSectionId || "", [
    quiz,
  ]);
  const quizId = useMemo(() => quiz?.session.externalQuizId || "", [quiz]);

  // content fetching hooks
  const [section, sectionLoading] = useSection(sectionId, contentfulHelper);
  const [quizContent, quizContentLoading] = useQuiz(quizId);
  const [module, moduleLoading] = useModule(
    quiz?.certificate?.firebaseModuleId || ""
  );
  const roleIds = useMemo(() => {
    if (module && activeMembership) {
      // filter out roleIds not included in membership
      const filteredRoles = module.associatedRoles.filter((role) =>
        activeMembership.roles.includes(role)
      );
      return filteredRoles;
    }
    return [];
  }, [module, activeMembership]);
  const [assignedRoles, rolesLoading] = useRoles(roleIds);

  // fires on-mount when all dependencies are not null
  useEffect(() => {
    if (firebaseHelper && contentfulHelper && workSpace && userData) {
      // fetch quiz data
      (async () => {
        // const quizData =
        await dispatch(
          fetchQuizData({
            contentfulHelper,
            firebaseHelper,
            certificateId,
            sessionId,
          })
        );
      })();
    }
  }, [firebaseHelper, contentfulHelper, workSpace, userData]);

  // event handlers
  const nextQuestionClick = useCallback(
    async (answer: { answerId: string; questionId: string }): Promise<void> => {
      if (quiz && contentfulHelper && firebaseHelper) {
        const {
          responses,
          currentQuestionIndex,
          totalQuestionCount,
          certificate,
        } = quiz;
        const { answerId, questionId } = answer;
        // find response with question id, check for undefined
        const currentResponse = quiz.responses.find(
          (response) => response.externalQuestionId === questionId
        );
        // update/create response
        await dispatch(
          markResponse({
            contentfulHelper,
            firebaseHelper,
            externalQuestionId: questionId,
            externalAnswerId: answerId,
            responseId: currentResponse?.id, // id will be undefined if response doesn't exist
          })
        );
        // determine if quiz was finished (and not in review)
        if (currentQuestionIndex >= totalQuestionCount - 1) {
          // end quiz in redux
          setBeginQuiz(false);
          dispatch(setQuestionIndex(0));
          if (quiz.inReview) dispatch(setQuizReview(false));
          else dispatch(endQuiz({ contentfulHelper, firebaseHelper }));
        }
        // increment current question index
        else dispatch(incrementCurrentQuestion());
      }
    },
    [quiz, contentfulHelper, firebaseHelper]
  );

  const prevQuestionClick = useCallback(() => {
    if (quiz) {
      const { responses, currentQuestionIndex, totalQuestionCount } = quiz;
      // decrement current question index (if greater than 0)
      if (currentQuestionIndex > 0) dispatch(decrementCurrentQuestion());
    }
  }, [quiz]);

  const getButtonText = (
    certificate: ICompletionCertificate,
    session: IQuizSession
  ): string => {
    if (certificate.startDate && certificate.completionDate)
      return "Review Quiz";
    else if (session.responses.length > 0) return "Continue Quiz";
    else return "Begin Quiz";
  };

  const parseReviewStatistics = (
    quizContent: IQuiz,
    quizData: QuizSlice,
    sectionContent: ISection,
    quizFinished: boolean
  ): ISimpleStat[] => {
    const attempts = quizData?.certificate?.quizSessions?.length || 1;
    const passingGrade = quizContent?.fields.passingGrade || 0;
    const totalResponses = quizContent.fields.quizQuestions.length;
    const correctResponses =
      quizData?.responses.filter((response) => response.isCorrect).length || 0;
    const score = Math.floor((correctResponses / totalResponses) * 100);
    return [
      {
        icon: <PointsIcon isHighlightColor={true} />,
        statValue: !quizFinished
          ? `${calcUserPointsBySections(
              sectionContent ? [sectionContent] : []
            )} Earnable User Points`
          : `${quizData?.certificate?.pointsAwarded || 0} User Points Earned`,
      },
      {
        icon: <TimeIcon isHighlightColor={true} />,
        statValue: `No Time Limit ---fix this`,
      },
      {
        icon: <QuestionIcon isHighlightColor={true} />,
        statValue: !quizFinished
          ? `${totalResponses} Total Questions`
          : `${correctResponses} of ${totalResponses} Questions Correct`,
      },
      {
        icon: <CheckIcon isHighlightColor={true} />,
        statValue: !quizFinished
          ? `${passingGrade}% Required For Pass`
          : `Score Of ${score}% - ${score < passingGrade ? "Fail" : "Pass"}`,
      },
      {
        icon: <RetakeIcon isHighlightColor={true} />,
        statValue: `${attempts} Attempts To Date`,
      },
    ];
  };

  const didPass = () =>
    getScore(quiz?.responses || [], quiz?.totalQuestionCount || 1) >=
    (quizContent?.fields?.passingGrade || 80);

  useEffect(() => {
    // log data
    console.group("Quiz Logging");
    console.group("Loading data");
    console.log("Section loading", sectionLoading);
    console.log("Module loading", moduleLoading);
    console.log("Quiz Content loading", quizContentLoading);
    console.log("Roles are loading", rolesLoading);
    console.groupEnd();

    console.group("Async Payload Data");
    //console.log('Quiz ID', quizId);
    //console.log('Section ID', sectionId);
    //console.log('Module Params', moduleParams);
    console.log("Section Content", section);
    console.log("Module Content", module);
    console.log("Quiz Content", quizContent);
    console.log("Redux Quiz Data", quiz);
    console.groupEnd();

    console.group("Access Control");
    console.log("Roles IDs", roleIds);
    console.log("Assigned Roles", assignedRoles);
    console.log("Active membership", activeMembership);
    console.groupEnd();
    console.groupEnd();
  });

  return (
    <>
      {quiz === null ||
      quizContentLoading ||
      sectionLoading ||
      moduleLoading ||
      rolesLoading ? (
        <PageContainer
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading message={"Loading Quiz"} />
        </PageContainer>
      ) : activeMembership ? (
        roleIds.length > 0 ? (
          <>
            {showQuiz ? (
              // show quiz if not finished
              <Quiz
                quizName={quizContent?.fields?.quizName || ""}
                reviewQuiz={quiz.inReview}
                currentQuestionIndex={quiz.currentQuestionIndex || 0}
                totalQuestionCount={quiz.totalQuestionCount || 0}
                responses={quiz.responses || []}
                questions={quizContent?.fields?.quizQuestions || []}
                onNextClick={nextQuestionClick}
                onPrevClick={prevQuestionClick}
              />
            ) : (
              // display overview
              <PageContainer>
                <QuizOverview
                  didPass={didPass()}
                  isRequired={section?.fields.isRequired || false}
                  isCompleted={quiz?.certificate?.completionDate ? true : false}
                  title={section?.fields.sectionHeader || ""}
                  subTitle={`For ${
                    workSpace?.organization.name
                  } ${assignedRoles?.reduce(
                    (prev, current, index) =>
                      index === 0
                        ? ` ${formatRoleName(current.roleName)}`
                        : `${prev}, ${current.roleName}`,
                    ""
                  )} Personnel`}
                  buttonData={{
                    children: getButtonText(quiz.certificate, quiz.session),
                    onClick: (e) => {
                      e.preventDefault();
                      // begin quiz
                      // set review status
                      setBeginQuiz(true);
                      if (quiz?.certificate?.isComplete)
                        dispatch(setQuizReview(true));
                    },
                    to: "",
                  }}
                  stats={
                    quizContent && section && quiz
                      ? parseReviewStatistics(
                          quizContent,
                          quiz,
                          section,
                          quiz?.certificate?.completionDate ? true : false
                        )
                      : []
                  }
                />
              </PageContainer>
            )}
          </>
        ) : (
          <h2>
            Error: Access Denied. You do not have the appropriate role
            permissions.
          </h2>
        )
      ) : (
        <h2>
          Error: Access to certificate with ID {certificateId} invalid (not
          associated with your membership).
        </h2>
      )}
    </>
  );
};
