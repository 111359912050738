import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  acceptInvite,
  AcceptInvitePayload,
  //addCompletionCertificate,
  addMembershipToUser,
  addSiginLogToUser,
  FetchError,
  fetchUserAppData,
  fetchUserMemberships,
  ICompletionCertificate,
  IMember,
  IOrganization,
  IUserData,
  UserDataSlice,
} from "./internal";
import { addCompletionCertificate, createAnInvite } from "./thunks";
import { IInvite } from "../../config/types";

let initialState: UserDataSlice = {
  appData: {
    id: "",
    firstName: "",
    lastName: "",
    birthDate: new Date(),
    signupDate: new Date(),
    memberships: [],
    loginLog: [],
  },
  signInTime: 0,
} as UserDataSlice;

const userDataSlice = createSlice({
  name: "userDataSlice",
  initialState,
  reducers: {
    clearUserState(state) {
      // reset to inital state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // create invite
      .addCase(
        createAnInvite.fulfilled.type,
        (
          state: UserDataSlice,
          action: PayloadAction<{ updatedMembership: IMember }>
        ) => {
          const { updatedMembership } = action.payload;
          if (state && state.memberships) {
            state.memberships = [
              ...state?.memberships?.filter(
                (member) => member.id !== updatedMembership.id
              ),
              updatedMembership,
            ];
          }
        }
      )
      .addCase(
        createAnInvite.rejected.type,
        (state: UserDataSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          console.log(`Error ${error}: ${message}`);
          return null;
        }
      )
      // responding to invite accepting
      .addCase(
        acceptInvite.fulfilled.type,
        (state: UserDataSlice, action: PayloadAction<AcceptInvitePayload>) => {
          const { updatedUser, newMembership } = action.payload;
          if (state) {
            // add new membership id to user
            // add membership object
            state.memberships = state.memberships
              ? [...state.memberships, newMembership]
              : [newMembership];
            state.appData = updatedUser;
          }
        }
      )
      .addCase(
        acceptInvite.rejected.type,
        (state: UserDataSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          console.log(`Error ${error}: ${message}`);
          return null;
        }
      )
      // certificate creation
      .addCase(
        addCompletionCertificate.fulfilled.type,
        (
          state: UserDataSlice,
          action: PayloadAction<{
            membershipId: string;
            certificate: ICompletionCertificate;
          }>
        ) => {
          if (state && state.memberships) {
            const { membershipId, certificate } = action.payload;
            // update membership associated with workspace
            state.memberships = state.memberships.map((membership, index) => {
              if (membership.id === membershipId) {
                return {
                  ...membership,
                  contentCertificates: [
                    ...membership.contentCertificates,
                    certificate.id,
                  ],
                };
              }
              return membership;
            });
          }
        }
      )
      .addCase(
        addCompletionCertificate.pending.type,
        (state: UserDataSlice) => {}
      )
      .addCase(
        addCompletionCertificate.rejected.type,
        (state: UserDataSlice, action: PayloadAction<FetchError>) => {}
      )
      // membership
      .addCase(
        addMembershipToUser.fulfilled.type,
        (
          state: UserDataSlice,
          action: PayloadAction<{
            updatedOrganization: IOrganization[];
            newMembership: IMember;
          }>
        ) => {
          const { newMembership } = action.payload;
          if (state) {
            if (state.memberships)
              state.memberships = [...state.memberships, newMembership];
            else state.memberships = [newMembership];
          }
        }
      )
      // fetch user memberships
      .addCase(
        addMembershipToUser.rejected.type,
        (state: UserDataSlice, action: PayloadAction<FetchError>) => {
          // set state to null, log error
          const { error, message } = action.payload;
          console.log(`Error ${error}: ${message}`);
          return initialState;
        }
      )
      .addCase(
        fetchUserMemberships.fulfilled.type,
        (state: UserDataSlice, action: PayloadAction<IMember[]>) => {
          if (state) {
            state.memberships = action.payload;
          }
        }
      )
      .addCase(
        fetchUserMemberships.rejected.type,
        (state: UserDataSlice, action: PayloadAction<IMember[]>) => {
          console.log("membership fetching error occurred");
          return initialState;
        }
      )
      // user app data
      .addCase(
        fetchUserAppData.fulfilled.type,
        (state: UserDataSlice, action: PayloadAction<IUserData>) => {
          // return entire new state value
          return { appData: action.payload, signInTime: new Date().getTime() };
        }
      )
      .addCase(
        fetchUserAppData.rejected.type,
        (state: UserDataSlice, action: PayloadAction<FetchError>) => {
          // set state to null, log error
          const { error, message } = action.payload;
          console.log(`Fetch user app data error: ${message}`);
          return initialState;
        }
      )
      // sign in log
      .addCase(
        addSiginLogToUser.fulfilled.type,
        (state: UserDataSlice, action: PayloadAction<IUserData>) => {
          if (state) state.appData = action.payload;
        }
      )
      .addCase(
        addSiginLogToUser.rejected.type,
        (state: UserDataSlice, action: PayloadAction<FetchError>) => {
          // log error, reset state
          const { error, message } = action.payload;
          console.log(`${error} error: ${message}`);
          return initialState;
        }
      );
  },
});

const userReducer = userDataSlice.reducer;
const { clearUserState } = userDataSlice.actions;

export { userReducer, clearUserState };
