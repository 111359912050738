import { DefaultTheme } from "styled-components";

const AppTheme: DefaultTheme = {
  // color
  color: {
    mainBackground: "#F7F6F5",
    mainText: "#2A2927",
    midMute: "#AEACAA",
    lightMute: "#D0D0CE",
    continueHighlight: "#28688B",
    beginHighlight: "#4D7925",
    mainHighlight: "#F9A825",
  },
  structure: {
    gutter: "3.5rem",
  },
};
export { AppTheme };
