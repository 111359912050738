import React, { useCallback, useEffect, useMemo } from "react";
import {
  DashboardSidebar,
  MessageIcon,
  PageContainer,
  NotificationIcon,
} from "../elements";
import { RoleCard, TLDRCard } from "../containers/cards";
import { useSelector } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";
import {
  UserDataSlice,
  WorkspaceSlice,
} from "../../boilerplate/redux/reduxTypes";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../boilerplate/providers/AuthProvider";
import {
  IQuizData,
  useRecentActivity,
  useRoles,
  useRoleStats,
} from "../../boilerplate/hooks";
import { Loading } from "../components/misc";
import { IRoleStats } from "../../boilerplate/hooks";
import { ActionableCard } from "../containers/cards";
import {
  daysToUnix,
  formatRoleName,
  parseReduxDate,
  parseTrackStats,
  unixTimeSince,
} from "../../boilerplate/utils";
import { ROUTES } from "../../boilerplate/config/routing/routes";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import {
  ICompletionCertificate,
  IQuizSession,
} from "../../boilerplate/config/types/firebaseTypes";

export default () => {
  // setup hooks
  const firebaseHelper = useFirebaseContext();
  const navigate = useNavigate();
  const user = useAuthContext();

  // redux
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;
  const workSpace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;

  // memoized values
  const activeMembership = useMemo(() => {
    const foundMembership = userData?.memberships?.find(
      (membership) => membership.id === workSpace?.associatedMembership
    );
    if (foundMembership === undefined) return null;
    else return foundMembership;
  }, [userData, workSpace]);

  // custom hooks
  const [roles, loadingRoles] = useRoles(activeMembership?.roles || []);
  const [roleStats, loadingRoleStats] = useRoleStats(roles, activeMembership);
  const [quizActivity, loadingActivity] = useRecentActivity(
    activeMembership,
    false
  );

  // logging
  useEffect(() => {
    // log data changes
    console.group("Dashboard Data");
    console.group("Loading data");
    console.log("Roles loading", loadingRoles);
    console.log("Role Stats Loading", loadingRoleStats);
    console.log("Quiz activity loading", loadingActivity);
    console.groupEnd();

    console.group("Async Payload Data");
    console.log("Roles", roles);
    console.log("Role Stats", roleStats);
    console.log("Quiz activity", quizActivity);
    console.groupEnd();

    console.group("Access Control");
    console.log(`Active Membership`, activeMembership);
    console.groupEnd();
    console.groupEnd();
  });

  // helpers
  const isRecent = (quiz: IQuizData, cutoffInDays: number): boolean => {
    const { sessions, certificate } = quiz;
    if (!certificate.isComplete) {
      const timeSinceStart = unixTimeSince(
        sessions[sessions.length - 1].startDate
      );
      if (timeSinceStart <= daysToUnix(cutoffInDays)) return true;
    }
    return false;
  };

  const hasRecentQuiz = (activityArr: IQuizData[]): boolean => {
    let recentActivity = false;
    for (const activity of activityArr) {
      if (isRecent(activity, 7)) return true;
    }
    return recentActivity;
  };

  const getRecentQuiz = (activityArr: IQuizData[]): IQuizData | null => {
    let quizData: IQuizData | null = null;
    for (const activity of activityArr) {
      const { sessions, certificate } = activity;
      // only progress if certificate not completed and more recent than 7 days
      if (!certificate.isComplete && isRecent(activity, 7)) {
        const timeSinceStart = unixTimeSince(
          sessions[sessions.length - 1].startDate
        );
        if (quizData === null) quizData = activity;
        else if (
          timeSinceStart <
          unixTimeSince(
            quizData.sessions[quizData.sessions.length - 1].startDate
          )
        )
          quizData = activity;
      }
    }
    return quizData;
  };

  const averageAllTrackProgress = (stats: IRoleStats[]): number =>
    Math.floor(
      stats
        .map((item) => item.trackProgress)
        .reduce((curr, prev) => curr + prev, 0) / stats.length
    );

  const passedQuizCount = (stats: IRoleStats[]): number =>
    stats
      .map((item) => item.quizzesCompleted)
      .reduce((curr, prev) => curr + prev, 0);

  const totalUserPoints = (stats: IRoleStats[]): number =>
    stats
      .map((item) => item.userPointsEarned)
      .reduce((curr, prev) => curr + prev, 0);

  const handleQuizResume = (activity: IQuizData[]): void => {
    const recent = getRecentQuiz(activity);
    if (recent)
      navigate(
        ROUTES.QUIZ.replace(":certificateId", recent.certificate.id).replace(
          ":sessionId",
          recent.sessions[recent.sessions.length - 1].id
        )
      );
  };

  const handleModuleResume = async () => {
    console.log("enable content visits for module/content resume feature");
  };

  const handleViewOrganization = () => {
    // redirec to active workspace profile
    if (workSpace)
      navigate(ROUTES.ORGANIZATION.replace(":id", workSpace.organization.id));
  };

  const handleEditContent = () => {
    console.log("feature unavailable");
  };

  return (
    <>
      {loadingRoles || loadingRoleStats || loadingActivity ? (
        <PageContainer
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading message={"Loading Dashboard"} />
        </PageContainer>
      ) : (
        <PageContainer
          style={{
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "2rem", width: "100%" }}>
            <RoleCard
              style={{ width: "80%" }}
              title={`Welcome Back, ${user?.displayName}`}
              slider={{
                sliderName: "Average Track Progress",
                progressPercent: averageAllTrackProgress(roleStats || []),
              }}
              stats={[
                {
                  statName: "Messages",
                  statValue: "25 Unread",
                  icon: <MessageIcon isHighlightColor={true} />,
                },
                {
                  statName: "Schedule Notifications",
                  statValue: "5 Unread",
                  icon: <NotificationIcon isHighlightColor={true} />,
                },
                {
                  statName: "Content Updates",
                  statValue: "13 Unread",
                  icon: <MessageIcon isHighlightColor={true} />,
                },
              ]}
              roleClick={() => {}}
            />
            <TLDRCard
              title={"General Stats"}
              joinDate={
                activeMembership
                  ? parseReduxDate(activeMembership.registryDate)
                  : new Date()
              }
              quizCount={passedQuizCount(roleStats || [])}
              userPointCount={totalUserPoints(roleStats || [])}
            />
          </div>
          <div style={{ flexGrow: 2 }}>
            {roles && roleStats && (
              <>
                <h2>Your Learning Tracks</h2>
                {roles.map((role, index) => (
                  <RoleCard
                    title={formatRoleName(role.roleName)}
                    slider={{
                      sliderName: "Track Progress",
                      progressPercent: roleStats[index].trackProgress,
                    }}
                    stats={parseTrackStats(roleStats[index])}
                    roleClick={(e) => {
                      // prevent default behavior
                      e.preventDefault();
                      // redirect to track page
                      navigate(ROUTES.TRACK_HOME.replace(":trackId", role.id));
                    }}
                  />
                ))}
              </>
            )}
          </div>
          <DashboardSidebar>
            {/*<ActionableCard
                title={"Resume Learning"}
                description={
                  "You have required content that isn't finished. Resume" +
                  "your learning to increase learning track progress"
                }
                buttonText={"Continue Learning"}
                onButtonClick={(e) => {
                  e.preventDefault();
                  handleModuleResume();
                }}
                redirectLink={""}
            />*/}
            {hasRecentQuiz((quizActivity as IQuizData[]) || []) ? (
              <ActionableCard
                title={"Resume Active Quiz"}
                description={
                  "You have a required quiz that needs to be finished. Pick " +
                  "up where you left off by completing, and then reviewing, this still " +
                  "active quiz"
                }
                buttonText={"Finish Quiz"}
                onButtonClick={(e) => {
                  e.preventDefault();
                  handleQuizResume(quizActivity as IQuizData[]);
                }}
                redirectLink={""}
              />
            ) : null}
            <ActionableCard
              title={"View Organization"}
              description={
                "Review the statistics, member lists, and general stats of the " +
                "organization you have currently loaded up."
              }
              buttonText={"View"}
              onButtonClick={(e) => {
                e.preventDefault();
                handleViewOrganization();
              }}
              redirectLink={""}
            />
            {activeMembership?.isAdmin && (
              <>
                <ActionableCard
                  title={"Edit Content"}
                  description={
                    "Create, manage, and update organization content, within " +
                    "the constraints of your current role"
                  }
                  buttonText={"Edit"}
                  onButtonClick={(e) => {
                    e.preventDefault();
                    handleEditContent();
                  }}
                  redirectLink={""}
                />
                <ActionableCard
                  title={"Manage Users"}
                  description={
                    "Create, manage, and update the users/roles within your organization"
                  }
                  buttonText={"Manage"}
                  onButtonClick={(e) => {}}
                  redirectLink={ROUTES.ADMIN_SEND_INVITE}
                />
              </>
            )}
          </DashboardSidebar>
        </PageContainer>
      )}
    </>
  );
};
