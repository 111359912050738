import {
  ICompletionCertificate,
  IFirebaseHelper,
  IQuizSession,
  IResponse,
} from "../config";
import { userPointsValues } from "../config/constants";

export const responsesCorrect = (responses: IResponse[]): number =>
  responses.filter((response) => response.isCorrect).length;

export const getScore = (
  responses: IResponse[],
  totalQuestions: number
): number => Math.floor((responsesCorrect(responses) / totalQuestions) * 100);

export const didChoiceChange = (
  currentIndex: number,
  responses: IResponse[],
  choiceId: string
): boolean => {
  // if there was no previous response, a new selection counts as a change
  // otherwise, check the current response object
  if (currentIndex > responses.length - 1) return true;
  return responses[currentIndex].externalAnswerId !== choiceId;
};

export const fetchQuizSession = async (
  firebaseHelper: IFirebaseHelper,
  sessionId: string
): Promise<IQuizSession | null> => {
  // fetch session
  const session = await firebaseHelper.quizSessions().doc(sessionId).get();
  if (session.exists)
    return { ...session.data(), id: session.id } as IQuizSession;
  return null;
};

export const getCompletedQuizCertificates = (
  certificates: ICompletionCertificate[]
): ICompletionCertificate[] =>
  certificates.filter((cert) => cert.isComplete && cert.quizSessions);

export const getResponseById = async (
  firebaseHelper: IFirebaseHelper,
  responseId: string
): Promise<IResponse | null> => {
  const document = await firebaseHelper.responses().doc(responseId).get();
  if (document.exists)
    return { ...document.data(), id: document.id } as IResponse;
  return null;
};

export const fetchQuizResponses = async (
  firebaseHelper: IFirebaseHelper,
  quizzes: IQuizSession[]
): Promise<IResponse[][]> => {
  // request response (nested map) objects
  const quizResponses = await Promise.all(
    quizzes.map(async (quiz, index) =>
      (
        await Promise.all(
          quiz.responses.map((responseId) =>
            getResponseById(firebaseHelper, responseId)
          )
        )
      )
        .filter((response) => response)
        .map((response) => response as IResponse)
    )
  );
  return quizResponses;
};

export const fetchQuizSessionsByExternalId = async (
  firebaseHelper: IFirebaseHelper,
  externalId: string
): Promise<IQuizSession[]> => {
  // query firestore quiz sessions based on external id
  const quizSessionDocs = await firebaseHelper
    .quizSessions()
    .where("externalQuizId", "==", externalId)
    .get();
  // parse doc data
  const quizSessions = quizSessionDocs.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as IQuizSession)
  );
  return quizSessions;
};

export const averageResponseTime = (
  quizSessions: IQuizSession[],
  timeUnits: "hrs" | "mins"
): number => {
  // average times
  const sessionTimes = quizSessions.map(
    (session) =>
      (session.endDate || new Date()).getTime() - session.startDate.getTime()
  );
  const rawAverage =
    sessionTimes.reduce((prev, currentTime) => prev + currentTime) /
    sessionTimes.length;
  // convert to correct units
  const averageInUnits = rawAverage / 6000 / (timeUnits === "mins" ? 1 : 60);
  return Math.floor(averageInUnits);
};

export const getStartEndSessionDates = (
  quizSessions: IQuizSession[]
): [startDate: Date | undefined, endDate: Date | undefined] => {
  // find first session (use its start date)
  // find final session (use its end date)
  const sortedSessions = quizSessions.sort(
    (sessionOne, sessionTwo) =>
      sessionOne.startDate.getTime() - sessionTwo.startDate.getTime()
  );
  const firstQuiz = sortedSessions[0];
  const lastQuiz = sortedSessions[quizSessions.length - 1];
  return [
    firstQuiz === undefined ? undefined : firstQuiz.startDate,
    lastQuiz === undefined ? undefined : lastQuiz.endDate,
  ];
};

export const highestQuizScore = (
  quizResponses: IResponse[][],
  totalQuestionCount: number
): number => {
  // loop over responses, get correct answer count
  const correctlyAnswered = quizResponses.map((responseSet) =>
    getCorrectResponseCount(responseSet)
  );
  // calculate/sort quiz results
  const results = correctlyAnswered
    .map((count) => Math.floor((count / totalQuestionCount) * 100))
    .sort((itemOne, itemTwo) => itemTwo - itemOne);
  // get highest result (first item since it is sorted from high to low)
  return results[0];
};

export const calcPointsEarned = (quizResponses: IResponse[]): number => {
  // get correct answers and multiply by points value
  return (
    getCorrectResponseCount(quizResponses) * userPointsValues.CORRECT_QUESTION
  );
};

export const calcAverageQuizScore = (
  quizResponses: IResponse[][],
  totalQuestionCount: number
): number => {
  // loop over responses
  let correctlyAnswered: number = 0;
  for (const responseSet of quizResponses) {
    // aggregate correct responses count
    correctlyAnswered += getCorrectResponseCount(responseSet);
  }
  // average response results
  return Math.floor(
    (correctlyAnswered / totalQuestionCount / quizResponses.length) * 100
  );
};

export const getCorrectResponseCount = (responses: IResponse[]): number =>
  responses
    .map((response) => (response.isCorrect ? 1 : 0) as number)
    .reduce((prev: number, current: number) => prev + current, 0);
