import React, { SyntheticEvent } from "react";
import { LightBoxButton, ModuleCardList, PageContainer } from "../../elements";
import { IContentGrid } from "../types";
import { ModuleCard } from "../cards";
import { Loading } from "../../components/misc";

export const ContentGrid = (props: IContentGrid) => {
  const { content, isLoading, itemLimitReached, loadMoreClick } = props;

  return (
    <PageContainer>
      <ModuleCardList style={{ marginBottom: "5rem" }}>
        {content.map((card) => (
          <ModuleCard {...card} />
        ))}
      </ModuleCardList>
      {isLoading && <Loading message={"Loading Content"} />}
      {!isLoading && !itemLimitReached && (
        <LightBoxButton
          onClick={(e: SyntheticEvent) => {
            // prevent redirect and increase item limit
            e.preventDefault();
            loadMoreClick();
          }}
          to={""}
        >
          Load More
        </LightBoxButton>
      )}
    </PageContainer>
  );
};
