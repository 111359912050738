import React, { SyntheticEvent } from "react";
import { RedirectFrame, LightBoxButton } from "../../elements";
import { IActionableCard } from "../../components/componentTypes";

export const ActionableCard = (props: IActionableCard) => (
  <RedirectFrame>
    <h3>{props.title}</h3>
    <p style={{ marginBottom: "2rem" }}>{props.description}</p>
    <LightBoxButton
      onClick={(e: SyntheticEvent) => props.onButtonClick(e)}
      to={props.redirectLink}
    >
      {props.buttonText}
    </LightBoxButton>
  </RedirectFrame>
);
