import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { AppTheme, GlobalStyles } from "../config/styling";

export default (props: { children: ReactNode }) => (
  <ThemeProvider theme={AppTheme}>
    {props.children}
    <GlobalStyles />
  </ThemeProvider>
);
