import React from "react";
import { IGenericCardHeader } from "../index";
import { ActivityHeaderBase, CardHeading, CardSubTitle } from "../../elements";

export const ActivityCardHeader = (props: IGenericCardHeader) => (
  <ActivityHeaderBase style={props.style}>
    <CardHeading>{props.title}</CardHeading>
    {props.subTitle === undefined ? null : (
      <CardSubTitle style={{ marginBottom: "0" }}>
        {props.subTitle}
      </CardSubTitle>
    )}
  </ActivityHeaderBase>
);
