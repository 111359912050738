import React, { CSSProperties, SyntheticEvent, useState } from "react";
import { ActivityFrame } from "../../elements";
import {
  IActivityCard,
  ActivityCardHeader,
  CardControls,
  AnnotatedSlider,
} from "../../components";
import { SummaryParagraph } from "../../components";
import styled from "styled-components";

const AdditionalStat = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 1.2em;
`;
const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;
`;

export const RecentActivityCard = (
  props: IActivityCard & { style?: CSSProperties }
) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleArrowClick = (incrementBy: number): void => {
    setCurrentPage(currentPage + incrementBy);
  };

  return (
    <ActivityFrame style={props.style}>
      {currentPage >= 2 ? (
        <StatContainer>
          <AnnotatedSlider
            progressPercent={props.statData.progressPercent}
            sliderName={props.statData.sliderName}
            style={{ marginBottom: "1rem" }}
          />
          <AdditionalStat>{props.statData.additionalStat}</AdditionalStat>
        </StatContainer>
      ) : (
        <>
          <ActivityCardHeader
            {...props.headerData}
            style={{ marginBottom: "1rem" }}
          />
          <SummaryParagraph {...props.cardSummary} color={"dark"} />
        </>
      )}
      <CardControls
        pageCount={2}
        currentPage={currentPage}
        clickLeft={(e: SyntheticEvent) => handleArrowClick(-1)}
        clickRight={(e: SyntheticEvent) => handleArrowClick(1)}
        buttonData={{ ...props.buttonData }}
      />
    </ActivityFrame>
  );
};
