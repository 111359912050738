import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addMembershipToUser,
  contentFiltersIds,
  FetchError,
  fetchOrganizations,
  fetchOrganizationsBySearch,
  filterOrganizations,
  IMember,
  IOrganization,
  loadMoreSearchedOrganizations,
  OrganizationSortPayload,
  OrganizationsSlice,
  sortIds,
  sortOrganizations,
} from "./internal";

let initialState: OrganizationsSlice = {
  isLoading: false,
  itemLimit: 0,
  itemLimitReached: true,
  organizations: [],
  searchTerm: "",
  itemSortID: sortIds.POPULAR,
  itemFilterID: contentFiltersIds.ALL,
};

const organizationsSlice = createSlice({
  name: "cardGrid",
  initialState,
  reducers: {
    // clear
    clearOrganizationState(state) {
      return initialState;
    },
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload.trim();
    },
  },
  extraReducers: (builder) => {
    builder
      // adding
      .addCase(
        addMembershipToUser.fulfilled,
        (
          state: OrganizationsSlice,
          action: PayloadAction<{
            updatedOrganization: IOrganization;
            newMembership: IMember;
          }>
        ) => {
          const { updatedOrganization } = action.payload;
          // find org to update by id
          const updateIndex = state.organizations.findIndex(
            (org) => org.id === updatedOrganization.id
          );
          if (updateIndex >= 0) {
            state.organizations[updateIndex] = updatedOrganization;
          }
        }
      )
      // filter
      .addCase(
        filterOrganizations.fulfilled.type,
        (
          state: OrganizationsSlice,
          action: PayloadAction<{
            filterId: string;
            filteredOrganizations: IOrganization[];
          }>
        ) => {
          const { filterId, filteredOrganizations } = action.payload;
          state.itemFilterID = filterId;
          state.organizations = filteredOrganizations;
        }
      )
      // fetching
      .addCase(
        fetchOrganizations.fulfilled.type,
        (state: OrganizationsSlice, action: PayloadAction<IOrganization[]>) => {
          state.organizations = action.payload;
          state.isLoading = false;
          state.itemLimitReached = true;
          state.itemLimit = action.payload.length;
        }
      )
      .addCase(
        fetchOrganizations.pending.type,
        (state: OrganizationsSlice, action: PayloadAction<IOrganization[]>) => {
          state.isLoading = true;
        }
      )
      .addCase(
        fetchOrganizations.rejected.type,
        (state: OrganizationsSlice, action: PayloadAction<FetchError>) => {
          // log error, reset state
          const { error, message } = action.payload;
          console.log(`${error} error: ${message}`);
          return initialState;
        }
      )
      // searching
      .addCase(
        fetchOrganizationsBySearch.fulfilled.type,
        (
          state: OrganizationsSlice,
          action: PayloadAction<{
            itemLimit: number;
            limitReached: boolean;
            sortedOrganizations: IOrganization[];
          }>
        ) => {
          const {
            itemLimit,
            limitReached,
            sortedOrganizations,
          } = action.payload;
          state.itemLimit = itemLimit;
          state.itemLimitReached = limitReached;
          state.organizations = sortedOrganizations;
          state.isLoading = false;
        }
      )
      .addCase(
        fetchOrganizationsBySearch.pending.type,
        (state: OrganizationsSlice) => {
          state.isLoading = true;
        }
      )
      .addCase(
        fetchOrganizationsBySearch.rejected.type,
        (state: OrganizationsSlice, action: PayloadAction<FetchError>) => {
          // log error, reset state
          const { error, message } = action.payload;
          console.log(`${error} error: ${message}`);
          return initialState;
        }
      )
      // item loading
      .addCase(
        loadMoreSearchedOrganizations.fulfilled.type,
        (
          state: OrganizationsSlice,
          action: PayloadAction<{
            organizations: IOrganization[];
            newItemLimit: number;
            limitReached: boolean;
          }>
        ) => {
          const { organizations, newItemLimit, limitReached } = action.payload;
          state.organizations = organizations;
          state.itemLimit = newItemLimit;
          state.itemLimitReached = limitReached;
          state.isLoading = false;
        }
      )
      .addCase(
        loadMoreSearchedOrganizations.pending.type,
        (state: OrganizationsSlice) => {
          state.isLoading = true;
        }
      )
      .addCase(
        loadMoreSearchedOrganizations.rejected.type,
        (state: OrganizationsSlice, action: PayloadAction<FetchError>) => {
          // log error, reset state
          const { error, message } = action.payload;
          console.log(`${error} error: ${message}`);
          return initialState;
        }
      )
      // item sorting
      .addCase(
        sortOrganizations.fulfilled.type,
        (
          state: OrganizationsSlice,
          action: PayloadAction<OrganizationSortPayload>
        ) => {
          state.organizations = action.payload.sortedOrganizations;
          state.itemSortID = action.payload.sortId;
          state.isLoading = false;
        }
      )
      .addCase(sortOrganizations.pending.type, (state: OrganizationsSlice) => {
        state.isLoading = true;
      })
      .addCase(
        sortOrganizations.rejected.type,
        (state: OrganizationsSlice, action: PayloadAction<IOrganization[]>) => {
          console.log("Error performing sort operation on organizations");
          return initialState;
        }
      );
  },
});

const organizationReducer = organizationsSlice.reducer;
const { clearOrganizationState, setSearchTerm } = organizationsSlice.actions;

export { organizationReducer, clearOrganizationState, setSearchTerm };
