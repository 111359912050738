import { IContentfulHelper } from "../config/types/contentfulTypes";
import {
  IModule,
  ISection,
  ISectionTag,
  IQuiz,
  IQuizQuestion,
  IQuizAnswer,
  IContentBlock,
  IModuleFields,
  ISectionFields,
  ISectionTagFields,
  IQuizFields,
  IQuizQuestionFields,
  IQuizAnswerFields,
  IContentBlockFields,
} from "../../../@types/generated/contentful";
import * as contentful from "contentful";

export class ContentfulHelper implements IContentfulHelper {
  // fields
  private readonly _rawClient: contentful.ContentfulClientApi;
  private _space: contentful.Space | undefined;

  constructor(spaceId: string, CDAToken: string) {
    this._rawClient = contentful.createClient({
      space: spaceId,
      accessToken: CDAToken,
    });
  }

  // initializer
  init = async () => {
    this._space = await this._rawClient.getSpace();
  };

  // properties
  get space() {
    return this._space;
  }
  get rawClient() {
    return this._rawClient;
  }

  // methods
  modules = (query?: any) =>
    this._rawClient.getEntries<IModuleFields>({
      content_type: "module",
      include: 10,
      ...query,
    });
  sections = (query?: any) =>
    this._rawClient.getEntries<ISectionFields>({
      content_type: "section",
      include: 10,
      ...query,
    });
  sectionTags = (query?: any) =>
    this._rawClient.getEntries<ISectionTagFields>({
      content_type: "sectionTag",
      include: 10,
      ...query,
    });
  quizzes = (query?: any) =>
    this._rawClient.getEntries<IQuizFields>({
      content_type: "quiz",
      include: 10,
      ...query,
    });
  quizQuestions = (query?: any) =>
    this._rawClient.getEntries<IQuizQuestionFields>({
      content_type: "quizQuestion",
      include: 10,
      ...query,
    });
  quizAnswerChoices = (query?: any) =>
    this._rawClient.getEntries<IQuizAnswerFields>({
      content_type: "quizAnswer",
      include: 10,
      ...query,
    });
  contentBlocks = (query?: any) =>
    this._rawClient.getEntries<IContentBlockFields>({
      content_type: "contentBlock",
      include: 10,
      ...query,
    });

  // getters
  getModuleById = async (id: string) =>
    (await this._rawClient.getEntry<IModuleFields>(id, {
      include: 10,
    })) as IModule;

  getSectionById = async (id: string) =>
    (await this._rawClient.getEntry<ISectionFields>(id, {
      include: 10,
    })) as ISection;

  getSectionTagById = async (id: string) =>
    (await this._rawClient.getEntry<ISectionTagFields>(id, {
      include: 10,
    })) as ISectionTag;

  getQuizById = async (id: string) =>
    (await this._rawClient.getEntry<IQuizFields>(id, {
      include: 10,
    })) as IQuiz;

  getQuestionById = async (id: string) =>
    (await this._rawClient.getEntry<IQuizQuestionFields>(id, {
      include: 10,
    })) as IQuizQuestion;

  getAnswerChoiceById = async (id: string) =>
    (await this._rawClient.getEntry<IQuizAnswerFields>(id, {
      include: 10,
    })) as IQuizAnswer;

  getContentBlockById = async (id: string) =>
    (await this._rawClient.getEntry<IContentBlockFields>(id, {
      include: 10,
    })) as IContentBlock;
}
