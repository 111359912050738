import React, { CSSProperties, SyntheticEvent } from "react";
import { FilterIcon, PageContainer, SortIcon } from "../../elements";
import { SearchBar, SortDropdown } from "../../components";
import { ISearchControls } from "../types";

const iconStyles = {
  width: "25px",
  marginRight: "0.75rem",
};

export const SearchControls = (
  props: ISearchControls & { style?: CSSProperties }
) => (
  <PageContainer style={props.style}>
    <SearchBar
      searchField={{
        placeholder: "Search for Organizations",
        type: "text",
        onChange: (e: SyntheticEvent) =>
          props.onSearchInput((e.target as HTMLInputElement).value),
        onKeyDown: (e) => {
          if (e.key.toUpperCase() === "ENTER") props.onSearch();
        },
        value: props.inputValue,
        required: true,
        name: "SearchBarInput",
        readOnly: false,
      }}
    >
      <SortDropdown
        {...props.sorts}
        title={props.sorts.title}
        titleIcon={<SortIcon style={iconStyles} />}
        style={{ marginRight: "2rem" }}
      />
      {props.filters ? (
        <SortDropdown
          {...props.filters}
          title={props.filters.title}
          titleIcon={<FilterIcon style={iconStyles} />}
        />
      ) : null}
    </SearchBar>
  </PageContainer>
);
