import { ItemList } from "../../elements/ListItems";
import {
  IStat,
  TitledStatItem,
  StatItem,
  InlineStatItem,
  ISimpleStat,
} from "../index";
import React, { CSSProperties } from "react";
import styled from "styled-components";

const VerticalList = styled(ItemList)`
  flex-direction: column;
  align-items: flex-start;
`;

type AlignmentTypes =
  | "right"
  | "center"
  | "left"
  | "inherit"
  | "-moz-initial"
  | "initial"
  | "revert"
  | "unset"
  | "end"
  | "start"
  | "justify"
  | "match-parent"
  | undefined;

const getStatAlignment = (
  currentItem: number,
  rowBreakpoint: number
): AlignmentTypes => {
  // item starts at 1, NOT zero
  // breakpoint represents the number of renderable items in a row
  const adjustedItemPosition =
    currentItem > rowBreakpoint ? currentItem - rowBreakpoint : currentItem;
  const itemAlignments: { itemPosition: number; CSSAlignment: string }[] = [
    {
      itemPosition: 3,
      CSSAlignment: "right",
    },
    {
      itemPosition: 2,
      CSSAlignment: "center",
    },
  ];
  // determine which item alignment to use
  let correctAlignment = "left";
  for (const alignment of itemAlignments) {
    if (adjustedItemPosition % alignment.itemPosition === 0)
      correctAlignment = alignment.CSSAlignment;
  }
  return correctAlignment as AlignmentTypes;
};

export const StatList = (props: {
  statsArr: IStat[];
  style?: CSSProperties;
}) => (
  <ItemList style={{ ...props.style }}>
    {props.statsArr.map((stat, index) => (
      <>
        {stat.icon === undefined ? (
          <StatItem
            key={stat.statName}
            style={{
              marginRight: `${(index + 1) % 3 === 0 ? "0" : "0.5rem"}`,
              textAlign: getStatAlignment(index + 1, 3),
            }}
            {...stat}
          />
        ) : (
          <TitledStatItem
            key={stat.statName}
            style={{
              marginRight: `${(index + 1) % 3 === 0 ? "0" : "0.5rem"}`,
            }}
            {...stat}
          />
        )}
      </>
    ))}
  </ItemList>
);

export const VerticalStatList = (props: {
  stats: ISimpleStat[];
  style?: CSSProperties;
}) => (
  <VerticalList style={{ ...props.style, marginBottom: "1rem" }}>
    {props.stats.map((stat) => (
      <InlineStatItem
        key={stat.statValue}
        style={{ marginBottom: "1rem" }}
        {...stat}
      />
    ))}
  </VerticalList>
);
