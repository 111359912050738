import React from "react";
import { Divider } from "../../elements";
import { HighlightParagraph, IDivider } from "../index";

export const ContentHeader = ({
  title,
  description,
  children,
  style,
}: IDivider) => (
  <Divider style={style}>
    <HighlightParagraph title={title} description={description} />
    {children}
  </Divider>
);
