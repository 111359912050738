import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AppContainer,
  ContentFrame,
  MainFrame,
} from "../../UIComponents/elements";
import { Navbar, Footer } from "../../UIComponents/containers";
import { ROUTES } from "../config/routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./StoreProvider";
import { useFirebaseContext } from "./FirebaseProvider";
import { RootState } from "../redux/setup";
import { clearWorkspace } from "../redux/workspace";
import { matchPath } from "react-router-dom";
import { setModuleSearchTerm } from "../redux/module";

export default (props: { children: React.ReactNode }) => {
  // provider hooks
  const firebaseHelper = useFirebaseContext();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  // local state
  const [renderFrame, setFrameRender] = useState<boolean>(true);

  // redux
  const { userData, workspace } = useSelector<RootState>(
    (state) => state
  ) as RootState;

  useEffect(() => {
    const currentPath = location.pathname;
    //console.log('render frame checker hook fired')
    //console.log('location is', location)
    //console.log('Is route in project', isRouteInProject(currentPath))
    // do not display frame if user is in the login or signup pages
    if (!isRouteInProject(currentPath)) setFrameRender(false);
    else if (
      matchPath(ROUTES.LOGIN, currentPath) ||
      matchPath(ROUTES.SIGNUP, currentPath) ||
      matchPath(`${ROUTES.WORKSPACE_MAIN}/*`, currentPath)
    )
      setFrameRender(false);
    else if (!renderFrame) setFrameRender(true);
  }, [userData, location]);

  const isRouteInProject = (currentRoute: string) => {
    const appRoutes = Object.values(ROUTES).filter((char) => char !== "*");
    for (const route of appRoutes) {
      //console.log('Route in project is', route)
      if (matchPath(route, currentRoute)) return true;
    }
    return false;
  };

  return (
    <>
      {renderFrame === false ? (
        <>{props.children}</>
      ) : (
        <AppContainer>
          <MainFrame>
            <Navbar
              navbarImgSRC={
                workspace?.organization?.logo ||
                "https://uploads-ssl.webflow.com/5e3ba490f838f4bba69aeb17/5e3cb1bc679a501d449a9119_creative-soapbox-logo.svg"
              }
              username={userData?.appData.firstName || ""}
              primeSearch={(searchTerm) => {
                // prime content search term
                dispatch(setModuleSearchTerm(searchTerm));
              }}
              beginContentSearch={async () => {
                // fetch content by search, then redirect to module home
                /*if (
                  firebaseHelper !== null &&
                  contentfulClient !== null &&
                  workspace !== null
                ) {
                  await dispatch(
                    fetchModulesBySearch({
                      firebaseHelper,
                      contentfulClient,
                      organizationId: workspace.organization.id,
                    })
                  );
                  navigate(ROUTES.MODULE_HOME);
                }*/
                console.log("fix this later");
              }}
              resetPassword={() => {}}
              changeWorkspace={() => {
                dispatch(clearWorkspace());
              }}
              signOut={async () => {
                await firebaseHelper?.auth.signOut();
              }}
            />
            <ContentFrame>{props.children}</ContentFrame>
          </MainFrame>
          <Footer />
        </AppContainer>
      )}
    </>
  );
};
