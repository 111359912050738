import React, { SyntheticEvent } from "react";
import { LightBoxButton, ModuleCardList, PageContainer } from "../../elements";
import { IOrganizations } from "../types";
import moment from "moment";
import { Loading } from "../../components/misc";
import { OrganizationCard } from "../cards";
import { isUserSubscribed } from "../../../boilerplate/utils";
import { useMembershipImages } from "../../../boilerplate/hooks";

export const OrganizationGrid = (props: IOrganizations) => {
  const {
    loadMoreClick,
    organizationClick,
    itemLimitReached,
    userMemberships,
    organizations,
    isLoading,
  } = props;

  const [memberArr, loadingMembers] = useMembershipImages(
    organizations.length > 0 ? organizations : null
  );

  return (
    <PageContainer>
      <ModuleCardList style={{ marginBottom: "5rem" }}>
        {organizations.map((org, index) => {
          const isSubscribed = isUserSubscribed(userMemberships, org);
          return (
            <OrganizationCard
              key={org.id}
              header={{
                title: org.name,
                subTitle: `Org. Joined On ${moment(
                  (org.joinDate as any).seconds * 1000
                ).format("L")}`,
              }}
              buttonContent={{
                children: isSubscribed ? "Load" : "View",
                onClick: (e) => {
                  e.preventDefault();
                  organizationClick(org, isSubscribed !== undefined);
                },
                to: "",
              }}
              memberData={
                !loadingMembers && memberArr !== null ? memberArr[index] : []
              }
              orgIcon={{ imageURL: org.logo }}
              userSubscribed={isSubscribed !== undefined}
            />
          );
        })}
      </ModuleCardList>
      {isLoading && <Loading message={"Loading Organizations"} />}
      {!isLoading && !itemLimitReached && (
        <LightBoxButton
          onClick={(e: SyntheticEvent) => {
            // prevent redirect and increase item limit
            e.preventDefault();
            loadMoreClick();
          }}
          to={""}
        >
          Load More
        </LightBoxButton>
      )}
    </PageContainer>
  );
};
