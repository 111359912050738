import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { UserCircle } from "styled-icons/fa-regular";
import {
  ArrowIosBackOutline,
  ArrowIosForwardOutline,
  ArrowIosDownwardOutline,
  MinusCircleOutline,
  PlusCircleOutline,
} from "styled-icons/evaicons-outline";
import { Cross } from "styled-icons/icomoon";
import {
  Search,
  RadioCircle,
  RadioCircleMarked,
  Loader,
  Star,
  FilterAlt,
} from "styled-icons/boxicons-regular";
import { Message } from "styled-icons/boxicons-solid";
import {
  AngleDoubleUp,
  Clock,
  Calendar,
  Pen,
  FlagCheckered,
  Play,
  HourglassStart,
  Check,
  Lock,
  ThumbsDown,
  ThumbsUp,
  CheckCircle,
  GraduationCap,
  Eye,
  Key,
  User,
  Bell,
} from "styled-icons/fa-solid";
import {
  Group,
  Replay,
  Sort,
  Warning,
  QuestionAnswer,
  ViewModule,
  Cancel,
} from "styled-icons/material";
import { Section } from "styled-icons/icomoon";
import DarkLogo from "../../boilerplate/assets/logos/grayLogo.svg";
import LightLogo from "../../boilerplate/assets/logos/defaultLogo.svg";
import { IGenericIcon, IInteractiveIcon } from "./elementTypes";

export const QuestionIcon = styled(QuestionAnswer)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const MessageIcon = styled(Message)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const NotificationIcon = styled(Bell)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const CompletionIcon = styled(GraduationCap)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const StarIcon = styled(Star)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const UserIcon = styled(User)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const KeyIcon = styled(Key)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const GroupIcon = styled(Group)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const LeftArrowIcon = styled(ArrowIosBackOutline)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const RightArrowIcon = styled(ArrowIosForwardOutline)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const DownArrowIcon = styled(ArrowIosDownwardOutline)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
    width: 35px;
  `
);

export const SearchIcon = styled(Search)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 20px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const TimeIcon = styled(Clock)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const CalendarIcon = styled(Calendar)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const ModuleIcon = styled(ViewModule)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const QuizIcon = styled(Pen)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const PointsIcon = styled(AngleDoubleUp)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const CircleIcon = styled(RadioCircle)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const MarkedCircleIcon = styled(RadioCircleMarked)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const CheckCircleIcon = styled(CheckCircle)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const CancelCircleIcon = styled(Cancel)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 25px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const CheckeredFlagIcon = styled(FlagCheckered)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const PlayIcon = styled(Play)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const SectionIcon = styled(Section)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const HourGlassIcon = styled(HourglassStart)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const CheckIcon = styled(Check)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const RetakeIcon = styled(Replay)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const SortIcon = styled(Sort)(
  ({ theme }) => css`
    width: 40px;
    color: ${theme.color.mainText};
  `
);

export const FilterIcon = styled(FilterAlt)(
  ({ theme }) => css`
    width: 40px;
    color: ${theme.color.mainText};
  `
);

export const LockIcon = styled(Lock)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 65px;
    color: ${isHighlightColor === true ? theme.color.mainHighlight : "white"};
  `
);

export const LoadingIcon = styled(Loader)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const ThumbsupIcon = styled(ThumbsUp)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const ThumbsDownIcon = styled(ThumbsDown)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const EyeIcon = styled(Eye)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    width: 40px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const BadgeIcon = styled.div<IGenericIcon>(
  ({ imageURL, theme }) => css`
    height: 30px;
    width: 30px;
    border: ${theme.color.mainText} solid 2px;
    border-radius: 100%;
    background: url(${imageURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  `
);

export const PlaceholderProfileIcon = styled(UserCircle)<IInteractiveIcon>(
  ({ isHighlightColor, theme }) => css`
    height: 55px;
    width: 55px;
    color: ${isHighlightColor === true
      ? theme.color.mainHighlight
      : theme.color.mainText};
  `
);

export const FooterLogo = (props: { style?: CSSProperties }) => (
  <img src={DarkLogo} style={{ ...props.style, width: "120px" }} />
);
export const DefaultLogo = (props: { style?: CSSProperties }) => (
  <img src={LightLogo} style={{ ...props.style, width: "120px" }} />
);
export const NavbarLogo = (props: { style?: CSSProperties; src: string }) => (
  <img src={props.src} style={{ ...props.style, width: "120px" }} />
);
export const OrganizationIcon = styled.img`
  width: 150px;
  height: 150px;
  filter: drop-shadow(0px 3px 6px rgba(42, 41, 39, 0.16));
  border-radius: 100%;
  border: 3px white solid;
  background: white;
`;

const BadgeBody = styled.div`
  background-color: white;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  filter: drop-shadow(0px 3px 6px rgba(42, 41, 39, 0.16));
`;

export const MinusIcon = styled(MinusCircleOutline)(
  ({ theme }) => css`
    width: 20px;
    color: ${theme.color.midMute};
  `
);

export const PlusIcon = styled(PlusCircleOutline)(
  ({ theme }) => css`
    width: 20px;
    color: ${theme.color.midMute};
  `
);

export const WarningIcon = styled(Warning)(
  ({ theme }) => css`
    width: 40px;
    color: ${theme.color.mainHighlight};
  `
);

export const RequiredBadge = () => (
  <BadgeBody>
    <WarningIcon />
  </BadgeBody>
);

const CheckGreen = styled(Check)(
  ({ theme }) => css`
    width: 40px;
    color: ${theme.color.beginHighlight};
  `
);
export const PassBadge = () => (
  <BadgeBody>
    <CheckGreen />
  </BadgeBody>
);

const OrangeCross = styled(Cross)(
  ({ theme }) => css`
    width: 40px;
    color: ${theme.color.mainHighlight};
  `
);
export const FailBadge = () => (
  <BadgeBody>
    <OrangeCross />
  </BadgeBody>
);

export const DropdownIcon = styled(DownArrowIcon)<{ isCollapsed: boolean }>(
  ({ isCollapsed }) => css`
    transform: rotate(${!isCollapsed ? "0deg" : "180deg"});
  `
);
