import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { IInputField } from "../../elements/elementTypes";
import { IFormDropdownItem, IGenericListItem } from "../componentTypes";
import {
  DropdownIcon,
  FormDropdownBody,
  FormDropdownItem,
  FormDropdownList,
  InputBody,
  InputField,
} from "../../elements";
import { ActionableDropdownItem } from "../listItems";

export type IFormDropdown = { input: IInputField; items: IFormDropdownItem[] };

export const FormDropdown = (props: IFormDropdown) => {
  const selectorRef = useRef<any>();
  const [isCollapsed, setCollapse] = useState<boolean>(true);
  const [selection, setSelection] = useState<string | null>(null);

  const setSelectValue = (selectedVal: string) => {
    console.log("Selected value in dropdown is:", selectedVal);
    console.log("Selector ref value", selectorRef);
    // set value of selector
    if (selectorRef)
      if (selectorRef.current && selectorRef.current.options) {
        const options = selectorRef.current.options;
        for (let i = 0; i < options.length - 1; i++) {
          const currOption = options[i];
          if (currOption.value === selectedVal) {
            selectorRef.current.selectedIndex = i;
            selectorRef.current.options[i].selected = true;
          }
        }
        selectorRef.current.value = selectedVal;
      }
  };

  useEffect(() => {
    if (!isCollapsed) setCollapse(true);
  }, [selection]);

  return (
    <div style={{ position: "relative" }}>
      <FormDropdownBody
        ref={selectorRef}
        name={props.input.name}
        required={props.input.required}
      >
        {props.items.map((item) => (
          <option key={item.text} value={item.text}>
            {item.text}
          </option>
        ))}
      </FormDropdownBody>
      <InputBody
        onClick={() => setCollapse(!isCollapsed)}
        style={{ marginBottom: "0.5rem" }}
      >
        <InputField
          {...props.input}
          value={selection ? selection : undefined}
          readOnly
        />
        <DropdownIcon isHighlightColor={false} isCollapsed={isCollapsed} />
      </InputBody>
      {!isCollapsed && (
        <FormDropdownList style={{ zIndex: 400 }}>
          {props.items.map((item, index) => (
            <ActionableDropdownItem
              key={item.text}
              {...item}
              onClick={(e: SyntheticEvent) => {
                // persist event
                // call ref update and original callback
                setSelectValue(item.text);
                setSelection(item.text);
                if (item.onClick) item.onClick(e);
              }}
              style={{ marginBottom: "1rem", height: "25px" }}
            />
          ))}
        </FormDropdownList>
      )}
    </div>
  );
};
