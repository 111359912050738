import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useRoles,
  useCertificates,
  useModule,
  useOrganization,
  useSectionStats,
} from "../../boilerplate/hooks";
import { ISection } from "../../../@types/generated/contentful";
import { Loading } from "../components/misc";
import {
  CheckeredFlagIcon,
  CheckIcon,
  IProgressSlider,
  ISliderHeader,
  PageContainer,
  PlayIcon,
  RetakeIcon,
  TimeIcon,
} from "../elements";
import { Banner } from "../containers/banner";
import {
  Divider,
  SectionContent,
  TextOverview,
  StatsOverview,
} from "../containers/content";
import {
  IContentTLDRCard,
  IInteractiveCard,
  ISectionCard,
  isModuleType,
  IStat,
} from "../components";
import moment from "moment";
import {
  calcTimeToComplete,
  calcUserPointsBySections,
  filterCertificatesBySections,
  parseReduxDate,
  sortCertificatesLowToHigh,
} from "../../boilerplate/utils";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import {
  ICompletionCertificate,
  IFirebaseHelper,
} from "../../boilerplate/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";
import {
  UserDataSlice,
  WorkspaceSlice,
} from "../../boilerplate/redux/reduxTypes";
import {
  addCompletionCertificate,
  finishCompletionCertificate,
} from "../../boilerplate/redux/userData";
import { AppDispatch } from "../../boilerplate/providers/StoreProvider";
import { PayloadAction } from "@reduxjs/toolkit";
import { ROUTES } from "../../boilerplate/config";

type ISectionData = ISection & { sectionStats?: IInteractiveCard };
export default () => {
  const { moduleId, sectionId } = useParams();
  const navigate = useNavigate();
  const firebaseHelper = useFirebaseContext();
  const dispatch = useDispatch<AppDispatch>();

  // local state
  const [section, setSection] = useState<ISectionData | null>(null);

  // redux
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;
  const workSpace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;

  const activeMembership = useMemo(() => {
    const foundMembership = userData?.memberships?.find(
      (membership) => membership.id === workSpace?.associatedMembership
    );
    if (foundMembership === undefined) return null;
    else return foundMembership;
  }, [userData, workSpace]);

  // custom hooks
  const [module, moduleLoading] = useModule(moduleId);
  const [organization, organizationLoading] = useOrganization(
    module?.parentOrganizationId || ""
  );
  const [certificates, certificatesLoading] = useCertificates(activeMembership);
  const [sectionStats, sectionStatsLoading] = useSectionStats(
    organization?.externalSpaceID,
    module,
    activeMembership
  );
  const roles = useMemo(() => {
    if (module && activeMembership) {
      // filter out roles not included in membership
      const filteredRoles = module.associatedRoles.filter((role) =>
        activeMembership.roles.includes(role)
      );
      return filteredRoles;
    }
    return [];
  }, [module, activeMembership]);
  const [assignedRoles, rolesLoading] = useRoles(roles);

  useEffect(() => {
    // log data changes
    console.group("Loading data");
    console.log("Module is loading", moduleLoading);
    console.log("Section stats loading", sectionStatsLoading);
    console.log("Organization is loading", organizationLoading);
    console.log("Roles are loading", rolesLoading);
    console.groupEnd();

    console.group("Async Payload Data");
    console.log("Module", module);
    console.log("Section", section);
    console.log("Section Stats", sectionStats);
    console.log("Organization", organization);
    console.log("Roles", roles);
    console.groupEnd();

    console.group("Access Control");
    console.log(`Module Public`, module?.isSample);
    console.log(`Organization Public`, organization?.isPublic);
    console.log(`Roles available`, roles);
    console.groupEnd();
  });

  useEffect(() => {
    if (module !== null && !moduleLoading && !sectionStatsLoading) {
      // get CONTENT section based on id from URL
      const sectionContent = module.content.sections?.find(
        (section) => section.sys.id === sectionId
      );
      if (sectionContent) {
        // get statistical section data based on the IDs
        const foundStatistics = sectionStats?.find(
          (sectionStats) => sectionStats.entityId === sectionId
        );
        setSection({
          ...sectionContent,
          sectionStats: foundStatistics,
        });
      }
    }
  }, [module, moduleLoading, sectionStats, sectionStatsLoading]);

  const parseTLDRFromSection = (section: ISectionData): IContentTLDRCard => {
    const timeEstimate = calcTimeToComplete([section]);
    return {
      title: "Section TLDR",
      timeEstimate: timeEstimate.time,
      timeEstimateUnits: timeEstimate.units,
      quizCount:
        section.fields.quizzes !== undefined
          ? section.fields.quizzes.length
          : 0,
      questionCount:
        section.fields.quizzes
          ?.map((quiz) => quiz.fields.quizQuestions.length)
          .reduce((prev, current) => prev + current, 0) || 0,
      earnableUserPoints: calcUserPointsBySections([section]),
    };
  };

  const parseSlidersFromStats = (
    section: ISectionCard,
    quizzable: boolean
  ): (IProgressSlider & ISliderHeader)[] => {
    return quizzable
      ? [
          {
            sliderName: "Average Quiz Score",
            progressPercent: section.averageQuizScore,
          },
        ]
      : [];
  };

  const parseStatsFromSection = (section: ISectionData): IStat[] => {
    const sectionData = section?.sectionStats as IInteractiveCard;
    const sectionStats = section.sectionStats?.statData as ISectionCard;
    const iconStats: IStat[] = [
      {
        statName: "Date Started",
        statValue: `${
          sectionData?.dateStarted
            ? parseReduxDate(sectionData.dateStarted).toLocaleDateString()
            : "N/A"
        }`,
        icon: <PlayIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
      {
        statName: "Date Finished",
        statValue: `${
          sectionData?.dateFinished
            ? parseReduxDate(sectionData.dateFinished).toLocaleDateString()
            : "N/A"
        }`,
        icon: (
          <CheckeredFlagIcon
            style={{ width: "25px" }}
            isHighlightColor={true}
          />
        ),
      },
      {
        statName: `Total Time`,
        statValue: `${
          sectionStats.totalTime
            ? `${sectionStats.totalTime} ${sectionStats.totalTimeUnits}`
            : "N/A"
        }`,
        icon: <TimeIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
    ];
    // add did pass stat only if quizzes are available
    return iconStats.concat(
      section.fields.quizzes
        ? [
            {
              statName: "Retakes",
              statValue: `${
                sectionStats.retakes === undefined
                  ? "N/A"
                  : sectionStats.retakes
              }`,
              icon: (
                <RetakeIcon style={{ width: "25px" }} isHighlightColor={true} />
              ),
            },
            {
              statName: "Passed",
              statValue: sectionStats.passed ? "Yes" : "No",
              icon: (
                <CheckIcon style={{ width: "25px" }} isHighlightColor={true} />
              ),
            },
          ]
        : []
    );
  };

  const shouldRenderButton = (
    section: ISectionData,
    certificates: ICompletionCertificate[] | null
  ): boolean => {
    const { fields, sectionStats } = section;
    const finishDate = sectionStats?.dateFinished;
    // check for certificate with section id
    const foundCertificate = certificates?.find(
      (cert) => cert.externalSectionId === section.sys.id && cert.startDate
    );
    // render only if required content
    if (fields.isRequired) {
      if (sectionStats) {
        // reading is not finished
        if (finishDate === undefined && !foundCertificate) return true;
        // quiz available
        else if (fields?.quizzes) return true;
      }
    }
    return false;
  };

  const parseButtonText = (section: ISectionData): string => {
    const { isRequired, quizzes } = section?.fields;
    const { sectionStats } = section;
    if (isRequired && sectionStats) {
      if (quizzes) {
        // check if quiz is finished
        if (!sectionStats.dateStarted) return "Begin Quiz";
        else if (!sectionStats.dateFinished) return "Continue Quiz";
        else if (sectionStats.dateFinished) return "Review Quiz";
      }
    }
    return "Mark As Read";
  };

  const handleFinishClick = async (
    section: ISection,
    firebaseHelper: IFirebaseHelper,
    sectionCertificates: ICompletionCertificate[]
  ) => {
    // check required and quiz status
    const { isRequired, quizzes } = section.fields;
    if (isRequired) {
      // check if current certificate list includes section
      // find certificate that is still unfinished
      const sortedCertificates = sortCertificatesLowToHigh(sectionCertificates);
      const sectionHasCertificate = sectionCertificates.length > 0;
      const recentCertificate =
        sortedCertificates.length === 0
          ? undefined
          : sortedCertificates[sortedCertificates.length - 1];
      const quizId = quizzes ? quizzes[0].sys.id : undefined;
      const sessions = recentCertificate?.quizSessions || [];
      if (!sectionHasCertificate) {
        // create certificate for section
        const thunkResult = await dispatch(
          addCompletionCertificate({
            firebaseHelper,
            sectionId,
            moduleId,
            quizId,
          })
        );
        const payload = (thunkResult as PayloadAction<{
          membershipId: string;
          sessionId: string | undefined;
          certificate: ICompletionCertificate;
        }>).payload;
        // navigate to now-active quiz, or
        // finish off certificate, if no quiz attached
        if (quizId) {
          const sessions = payload.certificate.quizSessions || [];
          navigate(
            ROUTES.QUIZ.replace(
              ":certificateId",
              payload.certificate.id
            ).replace(":sessionId", sessions[sessions.length - 1])
          );
        } else
          await dispatch(
            finishCompletionCertificate({
              firebaseHelper,
              certificateId: payload.certificate.id,
              pointsAwarded: calcUserPointsBySections([section]),
            })
          );
      } else if (!recentCertificate?.completionDate && quizzes) {
        // redirect to active session (should be the last session id in array)
        navigate(
          ROUTES.QUIZ.replace(
            ":certificateId",
            recentCertificate?.id || ""
          ).replace(":sessionId", sessions[sessions.length - 1])
        );
      } else {
        // redirect to session for review
        navigate(
          ROUTES.QUIZ.replace(
            ":certificateId",
            recentCertificate?.id || ""
          ).replace(":sessionId", sessions[sessions.length - 1])
        );
      }
    }
  };

  return (
    <>
      {moduleLoading ||
      organizationLoading ||
      sectionStatsLoading ||
      certificatesLoading ? (
        <PageContainer
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading message={"Loading Section"} />
        </PageContainer>
      ) : organization !== null && module !== null && section !== null ? (
        roles.length > 0 ? (
          <>
            <Banner
              bannerArr={[
                {
                  imageData: {
                    imageURL: section.fields.sectionImage.fields.file.url,
                  },
                  textData: {
                    title: section.fields.sectionHeader,
                    subTitle: `${
                      assignedRoles
                        ?.map((role) => role.roleName)
                        .reduce((prev, current) => prev + ", " + current) ||
                      "Public"
                    } Section`,
                  },
                },
              ]}
            />
            <TextOverview
              overviewText={{
                title: section.fields.sectionHeader,
                description: section.fields.sectionOverview,
              }}
            />
            <StatsOverview
              statsTitle={"Section Stats"}
              sliders={
                section !== null &&
                section.sectionStats !== undefined &&
                section.sectionStats.statData !== undefined &&
                !isModuleType(section.sectionStats.statData)
                  ? parseSlidersFromStats(
                      section.sectionStats.statData,
                      section.fields.quizzes ? true : false
                    )
                  : []
              }
              stats={
                section !== null &&
                section.sectionStats !== undefined &&
                section.sectionStats.statData !== undefined
                  ? parseStatsFromSection(section)
                  : []
              }
            />
            <Divider
              title={`${
                section.fields.isRequired ? "Required" : "Optional"
              } Content`}
              description={
                "Below is important section information that you are responsible for studying. " +
                "There will be a final assessment."
              }
              infoCard={
                section !== null &&
                section.sectionStats !== undefined &&
                section.sectionStats.statData !== undefined &&
                !isModuleType(section.sectionStats.statData)
                  ? parseTLDRFromSection(section)
                  : null
              }
              style={{ marginBottom: "5rem" }}
            />
            <SectionContent
              overview={{
                title: section.fields.sectionContentHeader,
                description: section.fields.quizzes
                  ? "Assessed Content"
                  : "Non-Assessed Content",
              }}
              content={section.fields.contentBlocks.map((block) => ({
                //paragraph: block.fields.paragraph,
                //blockHeader: block.fields.blockHeader
                ...block.fields,
              }))}
              metaData={{
                displayButton: activeMembership
                  ? shouldRenderButton(section, certificates)
                  : false,
                buttonText: parseButtonText(section),
                handleContentFinish: async () => {
                  const { sectionStats, ...sectionContent } = section;
                  if (section && firebaseHelper)
                    await handleFinishClick(
                      section,
                      firebaseHelper,
                      filterCertificatesBySections(certificates || [], [
                        sectionContent,
                      ])
                    );
                },
              }}
            />
          </>
        ) : (
          <h2>
            Error: Access for section with ID {sectionId} is unable for viewing
            (you don't have access).
          </h2>
        )
      ) : (
        <h2>Error: Section with ID {sectionId} unavailable.</h2>
      )}
    </>
  );
};
