import React from "react";
import {
  AnnotatedSlider,
  IGenericParagraph,
  IModuleCard,
  isDocumentType,
  ISectionCard,
  ISlider,
  IStat,
  StandardParagraph,
  StatList,
  TagList,
} from "../../components";
import { IGenericButton, PageContainer } from "../../elements";
import { ContainerTypes, IStatsOverview, ITextOverview } from "../index";
import styled from "styled-components";
import {
  createRenderableDateStats,
  createRenderableStatArr,
} from "../../../boilerplate/utils/staticHelpers";

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Sliders = styled.div`
  margin-right: 4rem;
`;
const StatsHeader = styled.h3`
  font-family: OpenSans-SemiBold;
`;

export const TextOverview = (props: ITextOverview) => (
  <PageContainer>
    {props.tags !== undefined ? (
      <TagList tags={props.tags} style={{ marginBottom: "1rem" }} />
    ) : null}
    <StandardParagraph
      {...props.overviewText}
      style={{ marginBottom: "2rem" }}
    />
  </PageContainer>
);

export const StatsOverview = (props: IStatsOverview) => (
  <PageContainer>
    <StatsHeader>{props.statsTitle}</StatsHeader>
    <Stats>
      {props.sliders.length > 0 && (
        <Sliders>
          {props.sliders.map((slider) => (
            <AnnotatedSlider
              key={slider.sliderName}
              style={{ width: "250px", marginBottom: "2rem" }}
              {...slider}
            />
          ))}
        </Sliders>
      )}
      <StatList
        statsArr={props.stats}
        style={{ flexGrow: 2, alignItems: "stretch" }}
      />
    </Stats>
  </PageContainer>
);
