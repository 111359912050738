import { IFirebaseHelper, IInvite } from "../config/types/firebaseTypes";

export const fetchInviteById = async (
  firebaseHelper: IFirebaseHelper,
  inviteId: string
): Promise<IInvite | null> => {
  const inviteDoc = await firebaseHelper.invites().doc(inviteId).get();
  if (inviteDoc.exists) return { ...inviteDoc.data(), id: inviteId } as IInvite;
  return null;
};
