import React, { ReactNode } from "react";
import {
  PointsIcon,
  QuizIcon,
  TimeIcon,
  CalendarIcon,
  TLDRFrame,
  ModuleIcon,
  ThumbsupIcon,
} from "../../elements";
import moment from "moment";
import {
  IContentTLDRCard,
  IProfileTLDRCard,
  isProfileType,
} from "../../components/componentTypes";
import { IGenericListItem } from "../../components";
import { TLDRCategory } from "../../components/listItems";

export const TLDRCard = (
  props: (IContentTLDRCard | IProfileTLDRCard) & {
    profileType?: "organization" | "user";
  }
) => {
  const { profileType } = props;
  // create an array of icons based on the type in props
  const categoryArr: IGenericListItem[] = isProfileType(props)
    ? [
        {
          icon: (
            <CalendarIcon
              isHighlightColor={true}
              style={{ marginRight: "1rem" }}
            />
          ),
          text: `Joined ${moment(props.joinDate).format("L")}`,
        },
        {
          icon: (
            <QuizIcon isHighlightColor={true} style={{ marginRight: "1rem" }} />
          ),
          text: `${props.quizCount} ${
            profileType === "organization"
              ? "Quizzes Available"
              : "Completed Quizzes"
          }`,
        },
        {
          icon: (
            <PointsIcon
              isHighlightColor={true}
              style={{ marginRight: "1rem" }}
            />
          ),
          text: `${props.userPointCount} ${
            profileType === "organization"
              ? "Story Points Available"
              : "Accumulated Points"
          }`,
        },
        {
          icon:
            profileType === "organization" ? (
              <ThumbsupIcon
                isHighlightColor={true}
                style={{ marginRight: "1rem", width: "30px" }}
              />
            ) : (
              <ModuleIcon
                isHighlightColor={true}
                style={{ marginRight: "1rem" }}
              />
            ),
          text:
            props.contentRating === undefined ||
            props.contentCompletionRate === undefined
              ? "N/A"
              : profileType === "organization"
              ? `${props.contentRating}% Positive Reception`
              : `${props.contentCompletionRate}% Completion Rate`,
        },
      ]
    : [
        {
          icon: (
            <TimeIcon isHighlightColor={true} style={{ marginRight: "1rem" }} />
          ),
          text: `${props.timeEstimate} ${props.timeEstimateUnits} To Complete`,
        },
        {
          icon: (
            <QuizIcon isHighlightColor={true} style={{ marginRight: "1rem" }} />
          ),
          text: `${props.quizCount} Quizzes, ${props.questionCount} Total Questions`,
        },
        {
          icon: (
            <PointsIcon
              isHighlightColor={true}
              style={{ marginRight: "1rem" }}
            />
          ),
          text: `${props.earnableUserPoints} Potential User Points`,
        },
      ];

  // render icon array below
  return (
    <TLDRFrame>
      <h3 style={{ marginBottom: "1rem" }}>{props.title}</h3>
      {categoryArr.map((category, index) => (
        <TLDRCategory
          key={index}
          style={{ marginBottom: "1rem" }}
          {...category}
        />
      ))}
    </TLDRFrame>
  );
};
