import React, { useEffect, useState } from "react";
import { SearchControls } from "../containers";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";
import {
  initializeWorkspace,
  clearWorkspace,
} from "../../boilerplate/redux/workspace";
import {
  OrganizationsSlice,
  UserDataSlice,
} from "../../boilerplate/redux/reduxTypes";
import {
  fetchOrganizations,
  fetchOrganizationsBySearch,
  sortOrganizations,
  loadMoreSearchedOrganizations,
  setSearchTerm,
  clearOrganizationState,
} from "../../boilerplate/redux/organization";
import { baseSorts } from "../../boilerplate/config";
import { IGenericListItem } from "../components";
import { OrganizationGrid } from "../containers/grids";
import { SearchResults } from "../containers/search/SearchResults";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../boilerplate/config";

export default () => {
  // provider hooks
  const firebaseHelper = useFirebaseContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux
  const {
    searchTerm,
    itemSortID,
    organizations,
    itemLimitReached,
    isLoading,
  } = useSelector<RootState>(
    (state) => state.organizationSearch
  ) as OrganizationsSlice;
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;

  useEffect(() => {
    // fetch all organizations from user memberships
    if (firebaseHelper !== null) {
      console.log("Select organizations page has mounted");
      dispatch(fetchOrganizations(firebaseHelper));
    }
    return () => {
      // clean out organization search
      dispatch(clearOrganizationState());
    };
  }, []);

  console.log(`Logging organizations in select orgs:`, organizations);
  return (
    <>
      <SearchControls
        inputValue={searchTerm}
        filters={{
          title: "",
          items: [],
        }}
        sorts={{
          title: baseSorts.find((sort) => sort.id === itemSortID)
            ?.displayName as string,
          items: baseSorts
            .filter((sort) => sort.id !== itemSortID)
            .map(
              (sortItem) =>
                // convert to renderable generic item format
                ({
                  text: sortItem.displayName,
                  onClick: () => {
                    // apply sort to results
                    if (firebaseHelper !== null)
                      dispatch(
                        sortOrganizations({
                          firebaseHelper,
                          sortId: sortItem.id,
                        })
                      );
                  },
                } as IGenericListItem)
            ),
        }}
        onSearch={() => {
          if (firebaseHelper !== null)
            dispatch(fetchOrganizationsBySearch({ firebaseHelper }));
        }}
        onSearchInput={(searchInput) => {
          if (firebaseHelper !== null) dispatch(setSearchTerm(searchInput));
        }}
        style={{ marginBottom: "7rem" }}
      />
      {organizations.length === 0 ? (
        <SearchResults
          message={
            searchTerm === ""
              ? "It Appears You Have not Made a Search Yet"
              : `0 Results Found For ${searchTerm}`
          }
          resultContent={[
            {
              title: "Most Popular",
              description:
                "Search for the trending organizations everyone is talking about.",
              onButtonClick: (e) => {
                e.preventDefault();
              },
              buttonText: "Search",
              redirectLink: "",
            },
            {
              title: "Interesting to You",
              description:
                "Find organizations reltated or similar to any you are are current a member of.",
              onButtonClick: (e) => {
                e.preventDefault();
              },
              buttonText: "Search",
              redirectLink: "",
            },
            {
              title: "Something New",
              description:
                "Need a little variety? Let us provide a semi-random search based on your calculated interests that" +
                "might introduce a new topic to you.",
              onButtonClick: (e) => {
                e.preventDefault();
              },
              buttonText: "Search",
              redirectLink: "",
            },
          ]}
        />
      ) : (
        <OrganizationGrid
          userMemberships={userData?.memberships || []}
          organizations={organizations}
          isLoading={isLoading}
          itemLimitReached={itemLimitReached}
          organizationClick={async (organization, subscribed: boolean) => {
            if (firebaseHelper !== null) {
              if (!subscribed)
                navigate(ROUTES.ORGANIZATION.replace(":id", organization.id));
              else {
                // find common membership id that is shared between selected organization and user memberships list
                const organizationMembers = organization.members;
                let associatedMembership: string | undefined = undefined;
                // loop through user memberships
                for (const userMembership of userData?.memberships || []) {
                  // loop through org membership list
                  for (const organizationMember of organizationMembers) {
                    if (userMembership.id === organizationMember)
                      associatedMembership = organizationMember;
                  }
                }
                // handle redux state and navigation
                if (associatedMembership === undefined)
                  dispatch(clearWorkspace());
                else {
                  dispatch(
                    initializeWorkspace({
                      organization,
                      associatedMembership,
                    })
                  );
                  navigate(ROUTES.DASHBOARD);
                }
              }
            }
          }}
          loadMoreClick={() => {
            if (firebaseHelper !== null)
              dispatch(loadMoreSearchedOrganizations(firebaseHelper));
          }}
        />
      )}
    </>
  );
};
