import React, { CSSProperties } from "react";
import {
  IGenericIcon,
  ImageCollectionItem,
  ImageCollectionList,
  ITextColors,
  SmallTitle,
} from "../../elements";

export const ImageCollection = (props: {
  items: IGenericIcon[];
  textColor: ITextColors;
  style?: CSSProperties;
}) => (
  <ImageCollectionList style={props.style}>
    <div style={{ marginRight: "0.5rem" }}>
      {props.items.slice(0, 6).map((item, index) => (
        <ImageCollectionItem key={index} src={item.imageURL} />
      ))}
    </div>
    {props.items.length > 6 && (
      <SmallTitle color={props.textColor}>
        +{props.items.length - 6} More
      </SmallTitle>
    )}
  </ImageCollectionList>
);
