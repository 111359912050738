import React from "react";
import { QuizHeaderBody, ProgressCircle, QuizTimer } from "../../elements";
import { IQuizHeader, AnnotatedSlider } from "../index";

export const QuizHeader = (props: IQuizHeader) => (
  <QuizHeaderBody>
    <div style={{ width: "35%" }}>
      <p>{props.titleData.subTitle}</p>
      <h2>{props.titleData.title}</h2>
    </div>
    <QuizTimer>
      <p>Time Remaining</p>
      <div style={{ width: "50px" }}>
        <ProgressCircle {...props.timeRemaining} />
      </div>
    </QuizTimer>
    <div style={{ width: "35%" }}>
      <AnnotatedSlider {...props.quizProgress} style={{ color: "white" }} />
    </div>
  </QuizHeaderBody>
);
