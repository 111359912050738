import { combineReducers } from "@reduxjs/toolkit";
import {
  userReducer,
  workspaceReducer,
  organizationReducer,
  moduleReducer,
  quizReducer,
  inviteReducer,
} from "./internal";

export const rootReducer = combineReducers({
  userData: userReducer,
  workspace: workspaceReducer,
  //selectedContent: selectedContentReducer, !!! replace by workspace state !!!
  organizationSearch: organizationReducer,
  moduleSearch: moduleReducer,
  quiz: quizReducer,
  invites: inviteReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
