import React, { useEffect, useState } from "react";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";
import {
  initializeWorkspace,
  clearWorkspace,
} from "../../boilerplate/redux/workspace";
import {
  OrganizationsSlice,
  UserDataSlice,
} from "../../boilerplate/redux/reduxTypes";
import {
  fetchOrganizations,
  loadMoreSearchedOrganizations,
  clearOrganizationState,
} from "../../boilerplate/redux/organization";
import { OrganizationGrid } from "../containers/grids";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../boilerplate/config/routing/routes";

export default () => {
  // provider hooks
  const firebaseHelper = useFirebaseContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux
  const { organizations, itemLimitReached, isLoading } = useSelector<RootState>(
    (state) => state.organizationSearch
  ) as OrganizationsSlice;
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;

  useEffect(() => {
    // fetch all organizations from user memberships
    if (firebaseHelper) {
      console.log("Select organizations page has mounted");
      dispatch(fetchOrganizations(firebaseHelper));
    }
    return () => {
      // clean out organization search
      dispatch(clearOrganizationState());
    };
  }, []);

  console.log(`Logging organizations in select orgs:`, organizations);
  return (
    <OrganizationGrid
      userMemberships={userData?.memberships || []}
      organizations={organizations}
      isLoading={isLoading}
      itemLimitReached={itemLimitReached}
      organizationClick={async (organization, subscribed: boolean) => {
        if (firebaseHelper !== null) {
          if (!subscribed)
            navigate(ROUTES.ORGANIZATION.replace(":id", organization.id));
          else {
            // find common membership id that is shared between selected organization and user memberships list
            const organizationMembers = organization.members;
            let associatedMembership: string | undefined = undefined;
            // loop through user memberships
            for (const userMembership of userData?.memberships || []) {
              // loop through org membership list
              for (const organizationMember of organizationMembers) {
                if (userMembership.id === organizationMember)
                  associatedMembership = organizationMember;
              }
            }
            // handle redux state and navigation
            if (associatedMembership === undefined) dispatch(clearWorkspace());
            else {
              dispatch(
                initializeWorkspace({
                  organization,
                  associatedMembership,
                })
              );
              navigate(ROUTES.DASHBOARD);
            }
          }
        }
      }}
      loadMoreClick={() => {
        if (firebaseHelper !== null)
          dispatch(loadMoreSearchedOrganizations(firebaseHelper));
      }}
    />
  );
};
