import hexRgb from "hex-rgb";
import {
  IModuleCard,
  ISectionCard,
  isModuleType,
  IStat,
} from "../../UIComponents/components";
import {
  CheckeredFlagIcon,
  CheckIcon,
  HourGlassIcon,
  ModuleIcon,
  PlayIcon,
  PointsIcon,
  QuizIcon,
  RetakeIcon,
  SectionIcon,
  ThumbsupIcon,
  TimeIcon,
} from "../../UIComponents/elements";
import React from "react";
import moment from "moment";
import { IRoleStats } from "../hooks";
import { isReduxTime } from "../redux/reduxTypes";

export const hexAndOpacityToCSS = (
  hexValue: string,
  opacityValue: number
): string => {
  // convert to rgb
  // return as css string
  const { red, green, blue } = hexRgb(hexValue);
  return `rgba(${red}, ${green}, ${blue}, ${opacityValue})`;
};

// returns date stats in a format that can be consumed by the StatList component
export const createRenderableDateStats = (
  dateStarted?: Date,
  dateFinished?: Date
): IStat[] => [
  {
    statName: "Date Started",
    statValue: `${
      dateStarted === undefined ? "N/A" : moment(dateStarted).format("L")
    }`,
    icon: <PlayIcon style={{ width: "25px" }} isHighlightColor={true} />,
  },
  {
    statName: "Date Finished",
    statValue: `${
      dateFinished === undefined ? "N/A" : moment(dateFinished).format("L")
    }`,
    icon: (
      <CheckeredFlagIcon style={{ width: "25px" }} isHighlightColor={true} />
    ),
  },
];

// takes in stat data and returns the correct stat category, value, and associated UI icon
export const createRenderableStatArr = (
  statData: ISectionCard | IModuleCard
): IStat[] => {
  if (isModuleType(statData)) {
    return [
      {
        statName: `Time to Complete`,
        statValue: `${statData.timeToComplete + " " + statData.TTCUnits}`,
        icon: <TimeIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
      {
        statName: `User Reviews`,
        statValue: `${
          statData.userReviewRate === undefined
            ? "N/A"
            : statData.userReviewRate + "%"
        }`,
        icon: (
          <ThumbsupIcon style={{ width: "25px" }} isHighlightColor={true} />
        ),
      },
      {
        statName: `Quiz Count`,
        statValue: `${statData.quizCount}`,
        icon: <QuizIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
      {
        statName: `Section Count`,
        statValue: `${statData.sectionCount}`,
        icon: <SectionIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
      {
        statName: `Earnable User Points`,
        statValue: `${statData.earnableUserPoints}`,
        icon: <PointsIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
    ];
  } else {
    return [
      {
        statName: `Avg. Completion`,
        statValue: `${
          statData.averageCompletionTime === undefined
            ? "N/A"
            : statData.averageCompletionTime +
              " " +
              statData.completionTimeUnits
        }`,
        icon: (
          <HourGlassIcon style={{ width: "25px" }} isHighlightColor={true} />
        ),
      },
      {
        statName: "Passed",
        statValue: statData.passed ? "Yes" : "No",
        icon: <CheckIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
      {
        statName: "Retakes",
        statValue: `${
          statData.retakes === undefined ? "N/A" : statData.retakes
        }`,
        icon: <RetakeIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
      {
        statName: `Total Time`,
        statValue: `${
          statData.totalTime === undefined
            ? "N/A"
            : statData.totalTime + " " + statData.totalTimeUnits
        }`,
        icon: <TimeIcon style={{ width: "25px" }} isHighlightColor={true} />,
      },
    ];
  }
};

export const parseReduxDate = (reduxDate: Date): Date => {
  if (isReduxTime(reduxDate)) return new Date(reduxDate.seconds * 1000);
  return reduxDate;
};
export const formatRoleName = (string: string): string =>
  string[0].toUpperCase() + string.slice(1).toLowerCase();

export const parseTrackStats = (stats: IRoleStats) => [
  {
    statName: "Date Started",
    statValue: stats.dateStarted
      ? parseReduxDate(stats.dateStarted).toLocaleDateString()
      : "N/A",
    icon: <PlayIcon style={{ width: "25px" }} isHighlightColor={true} />,
  },
  {
    statName: "Date Finished",
    statValue: stats.dateFinished
      ? parseReduxDate(stats.dateFinished).toLocaleDateString()
      : "N/A",
    icon: (
      <CheckeredFlagIcon style={{ width: "25px" }} isHighlightColor={true} />
    ),
  },
  {
    statName: "Total Time",
    statValue:
      stats?.dateFinished && stats?.dateStarted
        ? `${
            (parseReduxDate(stats.dateFinished).getTime() -
              parseReduxDate(stats.dateStarted).getTime()) /
            1000 /
            60 /
            60 /
            24
          } Days`
        : "N/A",
    icon: <TimeIcon style={{ width: "25px" }} isHighlightColor={true} />,
  },
  {
    statName: "Quizzes",
    statValue: `${stats?.quizzesCompleted || 0}/${stats?.totalQuizzes || 0}`,
    icon: <QuizIcon style={{ width: "25px" }} isHighlightColor={true} />,
  },
  {
    statName: "User Points",
    statValue: `${stats?.userPointsEarned || 0}/${
      stats?.userPointsPossible || 0
    }`,
    icon: <PointsIcon style={{ width: "25px" }} isHighlightColor={true} />,
  },
  {
    statName: "Modules",
    statValue: `${stats?.modulesCompleted || 0}/${stats?.modulesTotal || 0}`,
    icon: <ModuleIcon style={{ width: "25px" }} isHighlightColor={true} />,
  },
];

export const daysToUnix = (days: number) => days * 24 * 60 * 60 * 1000;

export const unixTimeSince = (startDate: Date): number => {
  const unixStartTime = parseReduxDate(startDate).getTime();
  const currentTime = new Date().getTime();
  const difference = currentTime - unixStartTime;
  return difference;
};
