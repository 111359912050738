import { IModule, IOrganization } from "../config/types/firebaseTypes";
import { IModuleFields } from "../../../@types/generated/contentful";

export type IOrganizationContent = IOrganization & { tags: string[] };

export type IModuleData = IModule & {
  parentOrganizationId: string;
  content: IModuleFields;
};

export type IRoleStats = {
  // progress sliders
  trackProgress: number;
  averageQuizScore: number;
  // completion times
  dateFinished?: Date;
  dateStarted?: Date;
  totalTime: number;
  // quizzes
  quizzesCompleted: number;
  totalQuizzes: number;
  // modules
  modulesCompleted: number;
  modulesTotal: number;
  // points
  userPointsEarned: number;
  userPointsPossible: number;
};

export interface IMembershipStats {
  // modules
  modulesCompleted: number;
  modulesTotal: number;
  // quizzes
  quizzesCompleted: number;
  totalQuizzes: number;
  // points
  userPointsEarned: number;
  userPointsPossible: number;
}

export const isModuleArrayType = (
  value: IModule[] | string[]
): value is IModule[] => {
  return value[0].hasOwnProperty("id") && value[0].hasOwnProperty("addedOn");
};

/*export const isModuleParam = (
  value: IUseModuleParams
): value is { firebaseModuleId: string } => {
  return value.hasOwnProperty("firebaseModuleId");
};*/

/*export type IUseModuleParams =
  | { firebaseModuleId: string }
  | { sectionId: string };*/
