import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  applyGeneralSort,
  applyRoleFilter,
  contentFiltersIds,
  FetchError,
  fetchModules,
  filterModules,
  filterModulesBySearch,
  IModule,
  loadMoreModules,
  ModuleGridSlice,
  sortIds,
} from "./internal";

let initialState: ModuleGridSlice = {
  modules: [],
  displayedModules: [],
  isLoading: false,
  itemLimit: 50,
  itemLimitReached: true,
  itemSortID: sortIds.POPULAR,
  itemFilterID: contentFiltersIds.ALL,
  searchTerm: "",
};

const moduleGridSlice = createSlice({
  name: "moduleGridSlice",
  initialState,
  reducers: {
    clearModuleState(state) {
      return initialState;
    },
    setModuleSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload.trim();
    },
    // role filtering
    filterModulesByRole(state, action: PayloadAction<string>) {
      // first order filtering (performing this action WILL remove any previous filter/sorts)
      const roleId = action.payload;
      state.roleId = roleId;
      state.itemSortID = sortIds.POPULAR;
      state.itemFilterID = contentFiltersIds.ALL;
      state.displayedModules = applyRoleFilter(roleId, state.modules);
    },
    // sorting (applies general sort to modules already in state)
    sortModules(state, action: PayloadAction<string>) {
      const sortId = action.payload;
      const sortedModules = applyGeneralSort(sortId, state.displayedModules);
      state.displayedModules = sortedModules;
      state.itemSortID = sortId;
    },
  },
  extraReducers: (builder) => {
    builder
      // filtering
      .addCase(
        filterModules.fulfilled.type,
        (
          state: ModuleGridSlice,
          action: PayloadAction<{
            filterId: string;
            filteredModules: IModule[];
          }>
        ) => {
          const { filterId, filteredModules } = action.payload;
          state.isLoading = false;
          state.displayedModules = filteredModules;
          state.itemFilterID = filterId;
        }
      )
      .addCase(
        filterModules.pending.type,
        (
          state: ModuleGridSlice,
          action: PayloadAction<{
            filterId: string;
            filteredModules: IModule[];
          }>
        ) => {
          state.isLoading = true;
        }
      )
      .addCase(
        filterModules.rejected.type,
        (state: ModuleGridSlice, action: PayloadAction<FetchError>) => {
          state.isLoading = false;
          const { error, message } = action.payload;
          // log error and reset state
          console.log(`Error ${error}: ${message}`);
          return initialState;
        }
      )
      // searching
      .addCase(
        filterModulesBySearch.fulfilled.type,
        (
          state: ModuleGridSlice,
          action: PayloadAction<{
            filteredModules: IModule[];
          }>
        ) => {
          const { filteredModules } = action.payload;
          state.isLoading = false;
          // limit displayed results
          state.displayedModules = filteredModules.slice(0, state.itemLimit);
        }
      )
      .addCase(filterModulesBySearch.pending.type, (state: ModuleGridSlice) => {
        state.isLoading = true;
      })
      .addCase(
        filterModulesBySearch.rejected.type,
        (state: ModuleGridSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          // log error and reset state
          console.log(`Error ${error}: ${message}`);
          return initialState;
        }
      )
      // module fetching
      .addCase(
        fetchModules.fulfilled.type,
        (state: ModuleGridSlice, action: PayloadAction<IModule[]>) => {
          state.isLoading = false;
          state.modules = action.payload;
          // limit displayed results
          state.displayedModules = action.payload.slice(0, state.itemLimit);
        }
      )
      .addCase(fetchModules.pending.type, (state: ModuleGridSlice) => {
        state.isLoading = true;
      })
      .addCase(
        fetchModules.rejected.type,
        (state: ModuleGridSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          // log error and reset state
          console.log(`Error ${error}: ${message}`);
          return initialState;
        }
      )
      // load more modules
      .addCase(
        loadMoreModules.fulfilled.type,
        (
          state: ModuleGridSlice,
          action: PayloadAction<{
            itemLimit: number;
            filteredModules: IModule[];
          }>
        ) => {
          const { itemLimit, filteredModules } = action.payload;
          state.isLoading = false;
          // set new item limit
          // limit display results via limit
          // limit reached if stale display modules in state is equal to new, limited, modules list
          const limitedModules = filteredModules.slice(0, itemLimit);
          state.itemLimit = itemLimit;
          state.itemLimitReached =
            state.displayedModules.length === limitedModules.length;
          state.displayedModules = limitedModules;
        }
      )
      .addCase(loadMoreModules.pending.type, (state: ModuleGridSlice) => {
        state.isLoading = true;
      })
      .addCase(
        loadMoreModules.rejected.type,
        (state: ModuleGridSlice, action: PayloadAction<FetchError>) => {
          const { error, message } = action.payload;
          // log error and reset state
          console.log(`Error ${error}: ${message}`);
          return initialState;
        }
      );
  },
});

const moduleReducer = moduleGridSlice.reducer;
const {
  clearModuleState,
  //setRoleSort,
  filterModulesByRole,
  sortModules,
  setModuleSearchTerm,
} = moduleGridSlice.actions;

export {
  moduleReducer,
  clearModuleState,
  filterModulesByRole,
  sortModules,
  setModuleSearchTerm,
};
