import {
  ICompletionCertificate,
  IFirebaseHelper,
  IInvite,
  IMember,
  IModule,
  IOrganization,
  IQuizSession,
  IResponse,
  IUserData,
} from "../config";
import {
  IInteractiveCard,
  IOrganizationCard,
} from "../../UIComponents/components";

// error handling
export interface FetchError {
  error: string;
  message: string;
}

// generic async thunk parameters
export interface GenericThunkFetchParams {
  requestedEntityId: string;
  firebaseHelper: IFirebaseHelper;
}

// filter/search thunk parameter
export interface GenericThunkSearchFilterParams {
  //searchTerm: string;
  firebaseHelper: IFirebaseHelper;
  //generalFilterID: string;
  //roleFilterID?: string;
}

export interface OrganizationSortPayload {
  sortId: string;
  sortedOrganizations: IOrganization[];
}

// user data params
export interface AddCertificateParams {
  firebaseHelper: IFirebaseHelper;
  sectionId: string; // contentfulId
  moduleId: string; // contentfulId
  quizId?: string;
}

export interface FinishCertificateParams {
  firebaseHelper: IFirebaseHelper;
  certificateId: string;
  pointsAwarded: number;
}

// invite thunk payloads
export interface AcceptInvitePayload {
  updatedInvite: ReduxInvite;
  updatedOrganization: IOrganization;
  updatedUser: IUserData;
  newMembership: IMember;
}

// slice state shapes
export type QuizSlice = {
  session: IQuizSession;
  responses: IResponse[];
  certificate: ICompletionCertificate;
  currentQuestionIndex: number;
  totalQuestionCount: number;
  inReview: boolean;
} | null;

export type SelectedContentSlice = {
  organization: IOrganization;
  moduleId?: string; // only IDs are needed since only static content will be fetched
  sectionId?: string;
};

export type ReduxInvite = {
  invite: IInvite;
  organization: IOrganization;
};

export type InviteSlice = {
  openInvites: ReduxInvite[];
  expiredInvites: ReduxInvite[];
  acceptedInvites: ReduxInvite[];
} | null;

export type UserDataSlice = {
  appData: IUserData;
  memberships?: IMember[];
  signInTime: number;
  //quizSessions?: IQuizSession[];
} | null;

export type WorkspaceSlice = {
  organization: IOrganization;
  associatedMembership: string;
} | null;

export type ResponsePayload = {
  response: IResponse;
  index: number; // index exists on interface to ensure that, if a new question is mark in redux, the new payload won't throw error
  //session?: IQuizSession
};

export type GridMetaData = {
  isLoading: boolean;
  //upperItemLimit: number;
  itemLimit: number;
  itemLimitReached: boolean;
  itemSortID: string;
  itemFilterID: string;
  //roleSortID?: string;
};

export type ModuleGridSlice = {
  searchTerm: string;
  modules: IModule[]; // stores original search payload
  displayedModules: IModule[]; // used for filtering and sorting
  roleId?: string;
} & GridMetaData;

export type OrganizationsSlice = {
  searchTerm: string;
  organizations: IOrganization[];
} & GridMetaData;

export const isFetchError = (
  data: IModule[] | FetchError
): data is FetchError => {
  return (
    (data as FetchError).error !== undefined &&
    (data as FetchError).message !== undefined
  );
};

// redux time
export interface ReduxTime {
  seconds: number;
  nanoseconds: number;
}

export const isReduxTime = (time: ReduxTime | Date): time is ReduxTime =>
  time.hasOwnProperty("seconds") && Object.entries(time).length === 2;
