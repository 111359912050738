import React from "react";
import { IMessagingInput } from "../componentTypes";
import {
  FooterMessageBody as MessageBody,
  SmallTitle as MessageTitle,
  LightBoxButton as Button,
  InputField as Input,
  AdminMessaging,
} from "../../elements";

export const MessagingInput = (props: IMessagingInput) => (
  <AdminMessaging>
    <MessageTitle color={"mute"}>{props.inputTitle}</MessageTitle>
    <MessageBody>
      <Input
        {...props.searchInput}
        style={{ background: "transparent", color: "white" }}
      />
      <Button {...props.buttonData} />
    </MessageBody>
  </AdminMessaging>
);
