import React from "react";
import {
  OrganizationFrame,
  OrganizationIcon,
  IconTopContainer,
  LightBoxButton,
  CardBottomContainer,
  StarIcon,
  StarIconContainer,
} from "../../elements";
import {
  IOrganizationCard,
  OrganizationCardHeader,
  ImageCollection,
} from "../../components";

export const OrganizationCard = (props: IOrganizationCard) => (
  <OrganizationFrame>
    <IconTopContainer>
      <OrganizationIcon src={props.orgIcon.imageURL} />
    </IconTopContainer>
    {props.userSubscribed ? (
      <StarIconContainer>
        <StarIcon isHighlightColor={true} />
      </StarIconContainer>
    ) : null}
    <OrganizationCardHeader
      {...props.header}
      style={{ marginTop: "5rem", textAlign: "center" }}
    />
    <ImageCollection
      items={props.memberData}
      style={{ justifyContent: "center" }}
      textColor={"dark"}
    />
    <CardBottomContainer
      style={{ padding: "1.5rem 2rem", alignItems: "center", width: "auto" }}
    >
      <LightBoxButton {...props.buttonContent} />
    </CardBottomContainer>
  </OrganizationFrame>
);
