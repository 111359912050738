// login and sign up state
export interface SignupState {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumber: string;
  birthDate: Date;
  roleId: number;
}

export interface LoginState {
  email: string;
  password: string;
}

// create invite state
export interface InviteState {
  email: string;
  adminStatus: boolean;
  roleId: string;
}

export const initialSignupState: SignupState = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  username: "",
  phoneNumber: "",
  birthDate: new Date(),
  roleId: 0,
};

export const initialLoginState: LoginState = {
  email: "",
  password: "",
};
