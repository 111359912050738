import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { INavItem } from "./elementTypes";
import React from "react";

const VanillaLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.2em;
`;

export const FooterNavItem = styled(VanillaLink)<INavItem>(
  ({ theme }) => css`
    font-family: "OpenSans-Bold";
    color: ${theme.color.midMute};
    &:hover {
      color: ${theme.color.mainHighlight};
    }
  `
);

export const NavItem = styled(VanillaLink)<INavItem>(
  ({ theme }) => css`
    font-family: "OpenSans-Regular";
    color: ${theme.color.mainText};
    padding: 0.5rem 0 0.5rem 0;
    &:hover {
      color: ${theme.color.mainHighlight};
      border-top: solid 3px ${theme.color.mainHighlight};
    }
  `
);

export const ItemList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ItemBody = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-grow: 1;
  `
);

export const LinkableDropdownItem = styled(VanillaLink)<INavItem>(
  ({ theme }) => css`
    margin: 0;
    font-family: "OpenSans-SemiBold";
    color: ${theme.color.mainText};
    padding: 0.5rem 0 0.5rem 0;
    font-size: 1em;
  `
);

export const DropdownItem = styled.p(
  ({ theme }) => css`
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1em;
    margin: 0;
    font-family: "OpenSans-SemiBold";
    color: ${theme.color.mainText};
    padding: 0.5rem 0 0.5rem 0;
  `
);

export const ImageCollectionItem = styled.img(
  ({ theme }) => css`
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: ${theme.color.mainText};
    border: solid white 1px;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
      z-index: 200;
    }
    &:not(:first-child) {
      margin-left: -10px;
    }
    filter: drop-shadow(0px 3px 6px rgba(42, 41, 39, 0.16));
  `
);

export const ImageCollectionList = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
`;

export const ModuleCardList = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
  align-content: space-between;
`;

export const ActivityCardList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
`;
