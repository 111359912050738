import { IFirebaseHelper, IRole } from "../config/types";

export const fetchRoleById = async (
  firebase: IFirebaseHelper,
  roleId: string
): Promise<IRole | null> => {
  const role = await firebase.roles().doc(roleId).get();
  if (role.exists) return { ...role.data(), id: roleId } as IRole;
  return null;
};
