import React from "react";
import styled, { css } from "styled-components";
import { IHeroBanner } from "./index";
import { hexAndOpacityToCSS } from "../../boilerplate/utils/staticHelpers";

export const BannerBody = styled.div<IHeroBanner>(
  ({ imageURL, theme }) => css`
    position: relative;
    height: 400px;
    padding: 2rem;
    padding-left: ${theme.structure.gutter};
    padding-right: ${theme.structure.gutter};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${imageURL === undefined
      ? theme.color.mainText
      : `linear-gradient(90deg, ${hexAndOpacityToCSS(
          theme.color.mainHighlight,
          0.3
        )} 0%, 
        ${hexAndOpacityToCSS(
          theme.color.continueHighlight,
          0.3
        )} 100%), url(${imageURL})`};
    background-size: cover;
    background-position: center center;
  `
);
