import React from "react";
import styled, { css } from "styled-components";
import { IGenericCardTitle } from "./elementTypes";

// default card title and card headers
export const CardHeaderBase = styled.div`
  font-family: "OpenSans-Bold";
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.color.mainText};
  color: white;
  height: 50px;
`;
export const CardHeading = styled.h4`
  font-family: "OpenSans-Regular";
  text-transform: capitalize;
`;
export const CardSubTitle = styled.p`
  font-family: "OpenSans-Light";
  color: white;
  text-transform: uppercase;
  font-size: 0.75em;
`;

// activity headers elements
export const ActivityHeaderBase = styled(CardHeaderBase)`
  height: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// banner elements
export const BannerBase = styled.div(
  ({ theme }) => css`
        color: white;
        width: 100%;
        text-align: right;
        padding; 1.5rem;
    `
);
export const BannerTitle = styled.h1<IGenericCardTitle>`
  font-family: "OpenSans-Bold";
  text-transform: capitalize;
`;

export const BannerSubTitle = styled.p`
  text-transform: uppercase;
`;

// quiz elements
export const QuizHeaderBody = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.midMute};
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    align-items: center;
  `
);
export const QuizTimer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

// dropdown
export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
`;
