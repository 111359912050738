import { ItemBody } from "../../elements";
import { ISimpleStat, IStat } from "../index";
import React from "react";

export const StatItem = (props: IStat) => (
  <div style={{ ...props.style, flexGrow: 1 }}>
    <p>{props.statName}</p>
    <b>
      <p>{props.statValue}</p>
    </b>
  </div>
);

export const TitledStatItem = (props: IStat) => (
  <ItemBody style={props.style}>
    <div style={{ marginRight: "1rem" }}>{props.icon}</div>
    <StatItem statName={props.statName} statValue={props.statValue} />
  </ItemBody>
);

export const InlineStatItem = (props: ISimpleStat) => (
  <ItemBody style={props.style}>
    <div style={{ marginRight: "1rem" }}>{props.icon}</div>
    <p>{props.statValue}</p>
  </ItemBody>
);
