import React, { CSSProperties, ReactNode } from "react";
import styled, { css } from "styled-components";
import {
  LeftArrowIcon,
  RightArrowIcon,
  IProgressSlider,
  ICircularProgress,
} from "./index";
import { CircularProgressbar } from "react-circular-progressbar";
import { AppTheme } from "../../boilerplate/config";

export const SliderHeaderBody = styled.div<{ children: ReactNode }>(
  ({ theme }) => css`
    color: ${theme.color.mainText};
    display: flex;
    justify-content: space-between;
  `
);

export const ProgressSlider = styled.div<IProgressSlider>(
  ({ progressPercent, theme }) => css`
    height: 15px;
    background: linear-gradient(
      90deg,
      ${theme.color.mainHighlight} ${progressPercent}%,
      white ${progressPercent}%
    );
    border: ${theme.color.mainHighlight} solid 2px;
  `
);

export const ProgressCircle = (props: ICircularProgress) => (
  <CircularProgressbar
    value={props.value}
    minValue={props.minValue}
    maxValue={props.maxValue}
    text={props.timeText}
    strokeWidth={props.strokeWidth}
    styles={{
      root: {},
      path: {
        stroke: AppTheme.color.mainHighlight,
        strokeLinecap: "butt",
      },
      trail: {
        stroke: "white",
        strokeLinecap: "butt",
      },
      text: {
        fill: "white",
        textAlign: "center",
        textAnchor: "middle",
        dominantBaseline: "middle",
        fontSize: "2.45em",
      },
      background: {
        fill: "transparent",
      },
    }}
  />
);

const ScrollBody = styled.div`
  width: 80px;
  height: 28px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ScrollThumb = (props: any) => (
  <ScrollBody {...props}>
    <LeftArrowIcon isHighlightColor={false} style={{ width: "28px" }} />
    <RightArrowIcon isHighlightColor={false} style={{ width: "28px" }} />
  </ScrollBody>
);

const ScrollTrack = styled.div`
  display: flex;
  align-items: center;
  height: 10px;
  position: relative;
`;
export const ScrolledSolidLine = styled.div`
  height: 7px;
  background: ${({ theme }) => theme.color.mainText};
`;
export const ScrolledLine = styled.div`
  height: 0px;
  border: ${({ theme }) => theme.color.mainText} solid 2px;
`;
const ThumbContainer = styled.div`
  position: absolute;
`;
export const HorizontalScrollTrack = React.forwardRef(
  (props: any, ref: React.Ref<any>) => (
    <ScrollTrack {...props} ref={ref}>
      <ScrolledLine />
    </ScrollTrack>
  )
);

export const UnscrolledLine = styled.div`
  height: 0px;
  border: ${({ theme }) => theme.color.mainText} dashed 2px;
`;
