import { IGenericIcon } from "../../UIComponents/elements";
import {
  IFirebaseHelper,
  IMember,
  IOrganization,
} from "../config/types/firebaseTypes";

// retrieves the icon associated with each membership ID
export const fetchMemberImages = async (
  orgMemberships: string[],
  firebaseHelper: IFirebaseHelper | null
): Promise<IGenericIcon[]> => {
  // loop through membership IDs
  let iconArr: IGenericIcon[] = [];
  for (const memberID of orgMemberships) {
    const memberDoc = await firebaseHelper?.members().doc(memberID).get();
    if (memberDoc !== undefined) {
      const memberData = memberDoc.data() as IMember;
      iconArr.push({ imageURL: memberData.profileImage });
    }
  }
  return iconArr;
};

export const fetchOrganizationById = async (
  orgId: string,
  firebaseHelper: IFirebaseHelper | null
): Promise<IOrganization | null> => {
  const organizationDoc = await firebaseHelper
    ?.organizations()
    .doc(orgId)
    .get();
  if (organizationDoc !== undefined && organizationDoc.exists)
    return {
      ...organizationDoc.data(),
      id: organizationDoc.id,
    } as IOrganization;
  return null;
};

export const fetchOrganizationByModuleId = async (
  moduleId: string,
  firebaseHelper: IFirebaseHelper | null
): Promise<IOrganization | null> => {
  // find organization via 'where' filter on modules array
  const organizationDocs = await firebaseHelper
    ?.organizations()
    .where("modules", "array-contains", moduleId)
    .get();
  if (organizationDocs === undefined || organizationDocs.docs.length <= 0)
    return null;
  // parse and return data
  const docRef = organizationDocs.docs[0]; // get first organization doc, since only one instance of a module can be tied to an org.
  return { ...docRef.data(), id: docRef.id } as IOrganization;
};

// checks if an organization's membership list includes any of the user's
export const isUserSubscribed = (
  userMembership: IMember[],
  organization: IOrganization
): string | undefined => {
  // loop through user's memberships
  if (userMembership.length > 0)
    for (const membership of userMembership) {
      // check if org membership array contains user membership ID
      if (organization.members.includes(membership.id)) return membership.id;
    }
  return undefined;
};
