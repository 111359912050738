import React, { CSSProperties } from "react";
import { IGenericParagraph, isDocumentType } from "../index";
import {
  SmallTitle,
  Summary,
  SummaryParagraphBody,
  AnnotationTitle,
  AnnotationSubTitle,
  HighlightTitle,
  ITextColors,
} from "../../elements";
import { richTextToReact } from "../../../boilerplate/config/contentful/richTextParser";

export const HighlightParagraph = (props: IGenericParagraph) => (
  <div style={props.style}>
    <HighlightTitle color={"highlight"}>{props.title}</HighlightTitle>
    <p style={{ color: "white" }}>{props.description}</p>
  </div>
);

export const StandardParagraph = (
  props: IGenericParagraph & { headerStyles?: CSSProperties }
) => (
  <div style={props.style}>
    <h2 style={{ ...props.headerStyles }}>{props.title}</h2>
    {isDocumentType(props.description) ? (
      richTextToReact(props.description)
    ) : (
      <p>{props.description}</p>
    )}
  </div>
);

export const AnnotatedTitle = (
  props: IGenericParagraph & { color: ITextColors }
) => (
  <div style={props.style}>
    <AnnotationTitle color={props.color} style={{ marginBottom: "0.75rem" }}>
      {props.description}
    </AnnotationTitle>
    <AnnotationSubTitle color={props.color}>{props.title}</AnnotationSubTitle>
  </div>
);

export const SummaryParagraph = (
  props: IGenericParagraph & { color: ITextColors }
) => (
  <SummaryParagraphBody style={{ ...props.style, padding: 0 }}>
    {props.icon !== undefined ? (
      <div style={{ marginBottom: "0.5rem" }}>{props.icon}</div>
    ) : null}
    <SmallTitle style={{ marginBottom: "0.5rem" }} color={props.color}>
      {props.title}
    </SmallTitle>
    <Summary color={props.color}>{props.description}</Summary>
  </SummaryParagraphBody>
);
