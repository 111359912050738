import styled from "styled-components";
import {
  CircleIcon,
  MarkedCircleIcon,
  ICardNavIcons,
  IModuleCardArrow,
  RightArrowIcon,
  LeftArrowIcon,
} from "./index";
import React from "react";

// page icons
const NavIconsBody = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardNavIcons = (props: ICardNavIcons) => (
  <NavIconsBody>
    {Array(props.totalPages)
      .fill("_")
      .map((curVal, index) => (
        <>
          {index + 1 === props.currentPage ? (
            <MarkedCircleIcon key={index} isHighlightColor={true} />
          ) : (
            <CircleIcon key={index} isHighlightColor={false} />
          )}
        </>
      ))}
  </NavIconsBody>
);
