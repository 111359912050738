import React from "react";
import {
  ActivityCardList,
  PageContainer,
  ScrolledSolidLine,
} from "../../elements";
import { StandardParagraph } from "../../components";
import { Scrollbars } from "react-custom-scrollbars";
import { RecentActivityCard } from "../cards";
import { IRecentActivity } from "../types";

export const RecentActivity = (props: IRecentActivity) => (
  <PageContainer>
    <StandardParagraph
      title={props.title}
      description={props.description || ""}
      style={{ marginBottom: "2rem" }}
    />
    <Scrollbars
      thumbMinSize={30}
      hideTracksWhenNotNeeded={true}
      renderTrackHorizontal={({ style, ...props }) => (
        <ScrolledSolidLine
          {...props}
          style={{
            ...style,
            height: "8px",
            bottom: 0,
            width: "100%",
          }}
        />
      )}
      renderTrackVertical={({ style, ...props }) => (
        <div {...props} style={{ ...style, display: "none" }} />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div {...props} style={{ ...style, display: "none" }} />
      )}
      renderThumbHorizontal={({ style, ...props }) => (
        <div
          {...props}
          style={{ ...style, height: "100%", background: "red" }}
        />
      )}
      style={{ width: "100%", minHeight: "375px" }}
    >
      <ActivityCardList>
        {props.activity.map((activity, index) => (
          <RecentActivityCard key={index} {...activity} />
        ))}
      </ActivityCardList>
    </Scrollbars>
  </PageContainer>
);
