import React, { useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  matchPath,
} from "react-router-dom";
import { IRoute, ROUTES, mappedRoutes } from "../config/routing/routes";
import AppFrame from "./AppFrame";
import { useAuthContext } from "./AuthProvider";
import { useSelector } from "react-redux";
import { RootState } from "../redux/setup";
import { WorkspaceSlice } from "../redux/reduxTypes";

export default () => {
  const authUser = useAuthContext();
  const workspace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;
  const navigate = useNavigate();
  const location = useLocation();

  const locationIsGuestRoute = (currentPath: string) => {
    return mappedRoutes
      .map(
        (route) =>
          !route.SigninRequired &&
          matchPath(route.RouteName || "", currentPath) !== null
      )
      .includes(true);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const signIn = signInRequired(currentPath);
    // concat sub routes
    const workspaceSearch = `${ROUTES.WORKSPACE_MAIN}/${ROUTES.WORKSPACE_SUB_SEARCH}`;
    const workspaceInvite = `${ROUTES.WORKSPACE_MAIN}/${ROUTES.WORKSPACE_SUB_INVITE}`;
    if (
      authUser &&
      workspace &&
      (matchPath(ROUTES.WORKSPACE_MAIN, currentPath) ||
        matchPath(ROUTES.LOGIN, currentPath) ||
        matchPath(ROUTES.SIGNUP, currentPath))
    )
      navigate(ROUTES.DASHBOARD);
    else if (
      authUser &&
      !workspace &&
      !(
        matchPath(ROUTES.WORKSPACE_MAIN, currentPath) ||
        matchPath(workspaceInvite, currentPath) ||
        matchPath(workspaceSearch, currentPath)
      )
    )
      navigate(ROUTES.WORKSPACE_MAIN);
    else if (
      signIn &&
      !authUser &&
      !(
        matchPath(ROUTES.LOGIN, currentPath) ||
        matchPath(ROUTES.SIGNUP, currentPath)
      )
    )
      navigate(ROUTES.LOGIN);
    console.log("pathname has changed:", location.pathname);
  }, [location, authUser, workspace]);

  const signInRequired = (pathname: string): boolean => {
    // find component via pathname
    const foundRoute = mappedRoutes.find(
      (route) =>
        (route.RouteName || "").toUpperCase() === pathname.toUpperCase()
    );
    if (!foundRoute) return false;
    return foundRoute.SigninRequired;
  };

  console.log("Auth user in router provider is:", authUser);

  return (
    <AppFrame>
      <Routes>
        {mappedRoutes.map((route: IRoute) => {
          const { DisplayName, RouteName, Component, ChildComponent } = route;
          return (
            <Route
              key={DisplayName}
              path={RouteName}
              element={Component as React.ReactElement}
              children={ChildComponent ? ChildComponent : null}
            />
          );
        })}
      </Routes>
    </AppFrame>
  );
};
