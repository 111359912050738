import React from "react";
import styled from "styled-components";

// special elements
export const LockMessage = styled.p`
  color: white;
  font-size: 1.2em;
`;

export const LockBody = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 100;
  background: transparent;
`;

export const LockBackground = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.86;
  position: absolute;
  z-index: 99;
  background-color: ${({ theme }) => theme.color.mainText};
`;

export const LockContent = styled.div`
  padding: 1.5rem;
  z-index: 101;
`;

// groups content at bottom of card container
export const CardBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const StarIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 1.5rem;
`;

export const IconTopContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
`;

// control positioning container
export const CardControlsContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem;
`;

export const BannerControlsContent = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;

export const BannerControlArrow = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
`;

// profile profile containers
export const ProfileMain = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
`;

export const ProfileAside = styled.aside`
  position: relative;
  width: 365px;
`;

export const ProfileStats = styled.div`
  position: absolute;
  bottom: -40%;
  right: 0;
`;
