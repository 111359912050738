import { IQuiz } from "../../../../@types/generated/contentful";
import { useContentful } from "../../providers/ContentfulProvider";
import { useEffect, useState } from "react";
import {
  ICompletionCertificate,
  IMember,
  IQuizSession,
} from "../../config/types/firebaseTypes";
import { IActivityCard } from "../../../UIComponents/components";
import { useFirebaseContext } from "../../providers/FirebaseProvider";
import {
  fetchCertificates,
  fetchQuizSession,
  generateActivityStatsByCertificate,
} from "../../utils";
import { IQuizData } from "./types";

export const useQuiz = (quizId: string): [IQuiz | null, boolean] => {
  const contentfulHelper = useContentful();
  // quiz state
  const [quiz, setQuiz] = useState<IQuiz | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    let cancelTask = false;
    if (contentfulHelper && quizId) {
      // set loading
      setLoadingStatus(true);
      (async () => {
        // fetch quiz content
        const quiz = await contentfulHelper.getQuizById(quizId);
        if (!cancelTask) {
          setQuiz(quiz);
          setLoadingStatus(false);
        }
      })();
    }

    return () => {
      cancelTask = true;
    };
  }, [contentfulHelper, quizId]);

  return [quiz, isLoading];
};

export const useRecentActivity = (
  membership: IMember | null,
  formatAsCard: boolean
): [IActivityCard[] | IQuizData[] | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  const contentfulClient = useContentful();
  // activity state
  const [activity, setActivity] = useState<
    IActivityCard[] | IQuizData[] | null
  >(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    if (firebaseHelper && contentfulClient && membership) {
      setLoadingStatus(true);
      (async () => {
        const currentDate = new Date();
        // fetch recent certificates
        const certificates = await fetchCertificates(
          firebaseHelper,
          membership.contentCertificates
          //7
        );
        if (certificates === null || certificates.length <= 0)
          setActivity(null);
        else if (!formatAsCard) {
          // fetch certificates and sessions
          const certsArr = await fetchCertificates(
            firebaseHelper,
            membership.contentCertificates
          );
          const sessionArr = await Promise.all(
            certificates?.map(
              async (cert) =>
                await Promise.all(
                  cert?.quizSessions?.map((session) =>
                    fetchQuizSession(firebaseHelper, session)
                  ) || []
                )
            ) || []
          );
          const sessions = sessionArr
            .filter((data) => data !== null)
            .map((data) =>
              data
                .filter((item) => item !== null)
                .map((item) => item as IQuizSession)
            );
          const certs = certsArr
            ? certsArr
                ?.filter((data) => data !== null)
                .map((data) => data as ICompletionCertificate)
            : [];
          const recentQuizArr = certs.map(
            (cert, indx) =>
              ({
                certificate: cert,
                sessions: sessions[indx],
              } as IQuizData)
          );
          setActivity(recentQuizArr);
        } else {
          // generate stats for each certificate
          const activityStats = await Promise.all(
            certificates.map((certificate) =>
              generateActivityStatsByCertificate(
                firebaseHelper,
                contentfulClient,
                certificate
              )
            )
          );
          setActivity(
            activityStats
              .filter((stats) => stats !== null)
              .map((stats) => stats as IActivityCard)
          );
        }
        setLoadingStatus(false);
      })();
    }
  }, [firebaseHelper, contentfulClient, membership]);

  return [activity, isLoading];
};
