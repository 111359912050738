import React from "react";
import { ItemBody } from "../../elements";
import { IGenericListItem } from "../index";

export const TLDRCategory = (props: IGenericListItem) => (
  <ItemBody style={props.style}>
    <div style={{ marginRight: "0.5rem" }}>{props.icon}</div>
    <p style={{ marginBottom: 0 }}>{props.text}</p>
  </ItemBody>
);
