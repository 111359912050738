import React from "react";
import styled, { css } from "styled-components";

// app UI frames
export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const MainFrame = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export const ContentFrame = styled.div`
  flex-grow: 2;
`;

// content containers
export const PageContainer = styled.div(
  ({ theme }) => css`
    display: block;
    margin-left: ${theme.structure.gutter};
    margin-right: ${theme.structure.gutter};
    margin-top: 3rem;
    margin-bottom: 3rem;
  `
);

export const DashboardSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
