import {
  ICompletionCertificate,
  IFirebaseHelper,
  IMember,
  IModule,
  IQuizSession,
  IRole,
} from "../config/types/firebaseTypes";
import { IRoleStats } from "../hooks/types";
import {
  fetchCertificates,
  filterCertificatesByModule,
  filterCertificatesBySections,
  getBeginEndDatesFromCertificates,
  getCompletedCertificates,
  getRequiredCertificates,
} from "./certificateHelpers";
import { IContentfulHelper } from "../config/types/contentfulTypes";
import {
  calcAverageQuizScore,
  fetchQuizResponses,
  fetchQuizSession,
  getCompletedQuizCertificates,
  highestQuizScore,
} from "./quizHelpers";
import {
  calcUserPointsBySections,
  getNonQuizzableSections,
  getQuizzableSections,
  getRequiredSections,
  getSectionsFromModules,
} from "./sectionHelpers";
import {
  fetchModulesByRole,
  getCompletedModules,
  isModuleComplete,
  isModuleRequired,
} from "./moduleHelpers";
import { parseReduxDate } from "./staticHelpers";

export const getRoleById = async (
  firebaseHelper: IFirebaseHelper,
  firebaseRoleId: string
): Promise<IRole | null> => {
  const roleDoc = await firebaseHelper.roles().doc(firebaseRoleId).get();
  if (roleDoc.exists) return { ...roleDoc.data(), id: roleDoc.id } as IRole;
  return null;
};

// returns difference in time, between two dates, as either days or hours
export const calculateTotalTime = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  timeInDays: boolean
): number => {
  // check start and end dates
  if (startDate) {
    // re-calc dates if in redux format
    const start = parseReduxDate(startDate);
    const end = parseReduxDate(endDate || new Date());
    let timeInHours = 0;
    if (endDate) timeInHours = end.getTime() - start.getTime();
    else timeInHours = new Date().getTime() - start.getTime();
    timeInHours /= 1000 / 60 / 60;
    // determine which unit of time to use
    return timeInDays ? timeInHours / 24 : timeInHours;
  }
  return 0;
};

export const generateRoleStats = async (
  contentfulHelper: IContentfulHelper,
  firebaseHelper: IFirebaseHelper,
  member: IMember,
  role: IRole
): Promise<IRoleStats | null> => {
  // fetch firebase and content modules (based on role id)
  const firebaseModules = await fetchModulesByRole(firebaseHelper, role.id);
  const contentModules = await Promise.all(
    firebaseModules.map((module) =>
      contentfulHelper.getModuleById(module.externalModuleId)
    )
  );
  const contentSections = getSectionsFromModules(contentModules);
  // fetch all certificates
  // filter certificates by role (check certificate's section id against section list)
  const certificates = filterCertificatesBySections(
    (await fetchCertificates(firebaseHelper, member.contentCertificates)) || [],
    contentSections
  );
  // filter out completed certificates with and without quizzes
  const completedCerts = getCompletedCertificates(certificates);
  const requiredCertificates = getRequiredCertificates(
    completedCerts || [],
    contentSections
  );
  const completeQuizCertificates = getCompletedQuizCertificates(certificates);
  // aggregate quiz score and user point values
  let averageQuizScore = 0;
  if (completeQuizCertificates.length > 0) {
    // fetch quiz sessions
    const sessionIds = completeQuizCertificates
      .flatMap((cert) => cert.quizSessions)
      .filter((id) => id)
      .map((id) => id as string);
    const sessions = (
      await Promise.all(
        sessionIds.map((sessionId) =>
          fetchQuizSession(firebaseHelper, sessionId)
        )
      )
    )
      .filter((session) => session)
      .map((session) => session as IQuizSession);
    // fetch quiz responses
    const quizReponses = await Promise.all(
      sessions.map((session) => fetchQuizResponses(firebaseHelper, [session]))
    );
    // average each quiz (since these sessions are part of a completed certificate, all responses are filled out)
    const averagedQuizzes = sessions.map((session, index) =>
      calcAverageQuizScore(quizReponses[index], session?.responses.length || 0)
    );
    // combine averages
    // find highest grade for each response set,
    averageQuizScore = Math.floor(
      averagedQuizzes.reduce((prev, current) => prev + current, 0) /
        averagedQuizzes.length
    );
  }
  // base content stats
  const requiredSections = getRequiredSections(contentSections);
  const requiredQuizSections = getQuizzableSections(requiredSections);
  const requiredModules = contentModules.filter((module) =>
    isModuleRequired(module)
  );
  // start/end dates (sort from start-end and select first element)
  const [startDate, endDate] = getBeginEndDatesFromCertificates(
    requiredCertificates,
    requiredSections.length
  );
  return {
    trackProgress: calcTrackProgress(
      requiredSections.length,
      completedCerts,
      requiredCertificates
    ),
    averageQuizScore: averageQuizScore,
    dateStarted: startDate,
    dateFinished: endDate,
    totalTime: calculateTotalTime(startDate, endDate, true),
    // completed if certificate is finished and quiz sessions exist
    quizzesCompleted: completeQuizCertificates.length,
    totalQuizzes: requiredQuizSections.length, // get all required sections, then quizzable
    modulesCompleted: getCompletedModules(contentModules, certificates || [])
      .length,
    modulesTotal: requiredModules.length,
    userPointsEarned: calcUserPointsByCertificates(certificates || []),
    userPointsPossible: calcUserPointsBySections(requiredSections),
  } as IRoleStats;
};

export const calcUserPointsByCertificates = (
  certificates: ICompletionCertificate[]
): number =>
  certificates
    .filter((cert) => cert.isComplete)
    .reduce((curr, prev) => curr + (prev?.pointsAwarded || 0), 0);

export const calcTrackProgress = (
  requiredSectionCount: number,
  optionalCompletedCerts: ICompletionCertificate[],
  requiredCompletedCerts: ICompletionCertificate[]
): number =>
  Math.floor(
    // check if all required certs are finished (cannot add up optional percentages otherwise)
    ((requiredCompletedCerts.length || 0) === requiredSectionCount
      ? // add up completion percentage of optional certs
        (optionalCompletedCerts.length || 0) / requiredSectionCount
      : // add up percentage of only required certs
        (requiredCompletedCerts.length || 0) / requiredSectionCount) * 100
  );
