import React from "react";
import styled, { css } from "styled-components";
import { ITextColors } from "./elementTypes";
import { Link } from "react-router-dom";

// highlight text elements
export const RedirectText = styled(Link)(
  ({ theme }) => css`
    color: ${theme.color.mainHighlight};
    text-size: 1.2em;
    &:hover {
      color: ${theme.color.mainText};
    }
  `
);

export const HighlightTitle = styled.h3<{ color: ITextColors }>(
  ({ color, theme }) => css`
    color: ${color === "dark"
      ? theme.color.mainText
      : color === "mute"
      ? theme.color.midMute
      : color === "light"
      ? "white"
      : theme.color.mainHighlight};
  `
);

// muted text elements
export const AnnotationSubTitle = styled.h3<{ color: ITextColors }>`
  color: ${({ color, theme }) =>
    color === "dark"
      ? theme.color.mainText
      : color === "mute"
      ? theme.color.midMute
      : color === "light"
      ? "white"
      : theme.color.mainHighlight};
  text-transform: capitalize;
`;
export const AnnotationTitle = styled.h4<{ color: ITextColors }>`
  color: ${({ color, theme }) =>
    color === "dark"
      ? theme.color.mainText
      : color === "mute"
      ? theme.color.midMute
      : color === "light"
      ? "white"
      : theme.color.mainHighlight};
  font-family: "OpenSans-Light";
  text-transform: capitalize;
`;

// summary text
export const SmallTitle = styled.p<{ color: ITextColors }>`
  font-family: "OpenSans-Bold";
  font-size: 1.2em;
  color: ${({ color, theme }) =>
    color === "dark"
      ? theme.color.mainText
      : color === "mute"
      ? theme.color.midMute
      : color === "light"
      ? "white"
      : theme.color.mainHighlight};
`;
export const Summary = styled.p<{ color: ITextColors }>`
  line-height: 2;
  color: ${({ color, theme }) =>
    color === "dark"
      ? theme.color.mainText
      : color === "mute"
      ? theme.color.midMute
      : color === "light"
      ? "white"
      : theme.color.mainHighlight};
`;
