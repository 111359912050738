import React, { CSSProperties } from "react";
import { IRoleCard } from "../types";
import { CardBottomContainer, RoleFrame } from "../../elements";
import { AnnotatedSlider } from "../../components/inputs";
import { StatList } from "../../components/lists";

export const RoleCard = (props: IRoleCard & { style?: CSSProperties }) => (
  <RoleFrame onClick={props.roleClick} style={props.style}>
    <h2>{props.title}</h2>
    <AnnotatedSlider {...props.slider} />
    <StatList statsArr={props.stats} />
    <CardBottomContainer>
      <p style={{ color: "light-grey", fontSize: "0.75em" }}>{props.caption}</p>
    </CardBottomContainer>
  </RoleFrame>
);
