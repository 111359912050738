import React from "react";
import { PageContainer } from "../../elements";

interface IErrorBanner {
  title: string;
  message: string;
}

export const ErrorBanner = (props: IErrorBanner) => (
  <PageContainer>
    <h3>{props.title}</h3>
    <p>{props.message}</p>
  </PageContainer>
);
