import {
  IActionableCard,
  IActivityCard,
  IContentTLDRCard,
  IGenericParagraph,
  IInteractiveCard,
  IProfileTLDRCard,
  ISimpleStat,
  ISlider,
  IDropdown,
  IStat,
} from "../components";
import { ChangeEvent, SyntheticEvent } from "react";
import {
  IMember,
  IOrganization,
  IResponse,
} from "../../boilerplate/config/types/firebaseTypes";
import { InputItem } from "./auth";
import { IGenericButton, IProgressSlider, ISliderHeader } from "../elements";
import { Document } from "@contentful/rich-text-types";
import { IQuizQuestion } from "../../../@types/generated/contentful";

export interface IRoleCard {
  title: string;
  slider: ISlider;
  stats: IStat[];
  roleClick: (e: SyntheticEvent) => void;
  caption?: string;
}

export enum ContainerTypes {
  SECTION = "Section",
  MODULE = "Module",
  QUIZ = "Quiz",
  SEARCH = "Search",
  PROFILE = "Profile",
  ORGANIZATION = "Organization",
}

export interface IQuiz {
  quizName: string;
  reviewQuiz: boolean;
  currentQuestionIndex: number;
  totalQuestionCount: number;
  responses: IResponse[];
  questions: IQuizQuestion[];
  onNextClick: (answer: {
    answerId: string;
    questionId: string;
  }) => Promise<void>;
  onPrevClick: () => void;
}

export interface IQuizOverivew {
  didPass: boolean;
  isRequired: boolean;
  isCompleted: boolean;
  title: string;
  subTitle: string;
  buttonData: IGenericButton;
  stats: ISimpleStat[];
}

export interface IStatsOverview {
  statsTitle: string;
  sliders: (IProgressSlider & ISliderHeader)[];
  stats: IStat[];
}

export interface ITextOverview {
  tags?: IGenericButton[];
  overviewText: IGenericParagraph;
}

export interface ISectionContent {
  overview: IGenericParagraph;
  content: { paragraph: Document; blockHeader?: string }[];
  metaData: {
    displayButton: boolean;
    //redirectRoute: string;
    buttonText: string;
    handleContentFinish: () => void;
  };
}

export interface INavbar {
  username: string;
  primeSearch: (searchTerm: string) => void;
  beginContentSearch: () => void;
  resetPassword: () => void;
  changeWorkspace: () => void;
  signOut: () => void;
  navbarImgSRC: string;
}

export interface IOrganizations {
  //firebaseHelper: IFirebaseHelper | null;
  userMemberships: IMember[];
  organizations: IOrganization[];
  isLoading: boolean;
  itemLimitReached: boolean;
  organizationClick: (
    organization: IOrganization,
    userSubscribed: boolean
  ) => void;
  loadMoreClick: () => void;
}

export interface IContentGrid {
  content: IInteractiveCard[];
  isLoading: boolean;
  itemLimitReached: boolean;
  loadMoreClick: () => void;
}

export interface IRecentActivity {
  title: string;
  description?: string;
  activity: IActivityCard[];
}

export interface ISearchResults {
  message: string;
  resultContent: IActionableCard[];
}

export interface ISearchControls {
  onSearchInput: (searchTerm: string) => void;
  onSearch: () => void;
  inputValue: string;
  sorts: IDropdown;
  filters?: IDropdown;
}

export interface IContentDivider {
  title: string;
  description: string;
  infoCard: IContentTLDRCard | IProfileTLDRCard | null;
}

export interface IAuthForm {
  inputFields: InputItem[];
  onSubmit: () => Promise<void>;
  onInput: (newValue: string, stateProperty: string) => void;
  formError: string | null;
  title: string;
  subHeader: string;
  redirect?: {
    text: string;
    redirectRoute: string;
  };
  buttonText: string;
}

export interface IInviteCard {
  onSubmit: () => Promise<void>;
  title: string;
  subHeader: string;
  redirect: {
    text: string;
    redirectRoute: string;
  };
  buttonText: string;
}

export interface IGenericSortItem {
  sortName: string;
  sortId: string;
}
