import React, { SyntheticEvent, useEffect } from "react";
import {
  Banner,
  Divider,
  RecentActivity,
  SearchControls,
  TextOverview,
  StatsOverview,
  SearchResults,
} from "../containers";
import { ContentGrid } from "../containers/grids/ContentGrid";
import { useParams } from "react-router-dom";
import {
  useModuleStats,
  useRecentActivity,
  useRole,
  useRoleStat,
} from "../../boilerplate/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../boilerplate/redux/setup";
import {
  ModuleGridSlice,
  UserDataSlice,
  WorkspaceSlice,
} from "../../boilerplate/redux/reduxTypes";
import { Loading } from "../components/misc";
import {
  CheckeredFlagIcon,
  ModuleIcon,
  PageContainer,
  PlayIcon,
  PointsIcon,
  QuizIcon,
  TimeIcon,
} from "../elements";
import {
  baseSorts,
  contentFilters,
  contentFiltersIds,
  sortIds,
} from "../../boilerplate/config/constants";
import {
  fetchModules,
  filterModules,
  loadMoreModules,
  sortModules,
  filterModulesByRole,
  setModuleSearchTerm,
  filterModulesBySearch,
} from "../../boilerplate/redux/module";
import { useFirebaseContext } from "../../boilerplate/providers/FirebaseProvider";
import { useContentful } from "../../boilerplate/providers/ContentfulProvider";
import { IRoleStats } from "../../boilerplate/hooks";
import { formatRoleName, parseTrackStats } from "../../boilerplate/utils";
import { IActivityCard } from "../components";

export default () => {
  // provider hooks
  const { trackId } = useParams();
  const dispatch = useDispatch();
  const firebaseHelper = useFirebaseContext();
  const contentfulHelper = useContentful();

  // redux data
  const workspace = useSelector<RootState>(
    (state) => state.workspace
  ) as WorkspaceSlice;
  const moduleData = useSelector<RootState>(
    (state) => state.moduleSearch
  ) as ModuleGridSlice;
  const userData = useSelector<RootState>(
    (state) => state.userData
  ) as UserDataSlice;

  // permission checks
  // check if workspace and user has role
  const isTrackInWorkspace =
    workspace?.organization?.roles?.includes(trackId) || false;
  const userHasRole =
    userData?.memberships
      ?.find((membership) => membership.id === workspace?.associatedMembership)
      ?.roles?.includes(trackId) || false;
  const membershipWithRole = userHasRole
    ? userData?.memberships?.find(
        (membership) => membership.id === workspace?.associatedMembership
      ) || null
    : null;

  // custom hooks: fetch role data, stats, and quiz activity
  const [track, trackLoading] = useRole(trackId);
  const [trackStats, statsLoading] = useRoleStat(track, membershipWithRole);
  const [moduleStats, moduleStatsLoading] = useModuleStats(
    workspace?.organization.externalSpaceID || "",
    moduleData.displayedModules.length === 0
      ? null
      : moduleData.displayedModules,
    membershipWithRole
  );
  const [recentActivity, activityLoading] = useRecentActivity(
    membershipWithRole,
    true
  );

  useEffect(() => {
    // fetch all modules from organization (fills content grid)
    if (firebaseHelper && workspace)
      (async () => {
        await dispatch(
          fetchModules({
            firebaseHelper,
            organizationId: workspace.organization.id,
          })
        );
        dispatch(filterModulesByRole(trackId));
      })();
  }, [firebaseHelper, workspace]);

  useEffect(() => {
    console.group("Track Page Data");
    console.log("Role in Workspace", isTrackInWorkspace);
    console.log("Role in Membership", userHasRole);
    console.log("Membership with Role", membershipWithRole);
    console.log("Track", track);
    console.log("Track Stats", trackStats);
    console.log("Module Stats", moduleStats);
    console.log("Recent Activity", recentActivity);
    console.log("Track Loading", trackLoading);
    console.log("Track Stats Loading", statsLoading);
    console.log("Module Stats Loading", moduleStatsLoading);
    console.groupEnd();
  }, [
    trackLoading,
    statsLoading,
    moduleStatsLoading,
    activityLoading,
    moduleStats,
    trackStats,
    track,
    recentActivity,
    userHasRole,
    membershipWithRole,
    isTrackInWorkspace,
  ]);

  const parseTrackSliders = (stats: IRoleStats) => [
    {
      sliderName: "Track Progress",
      progressPercent: stats?.trackProgress || 0,
    },
    {
      sliderName: "Average Quiz Score",
      progressPercent: stats?.averageQuizScore || 0,
    },
  ];

  // check role id against existing membership data, reject if does not exist
  return (
    <>
      {trackLoading || statsLoading ? (
        <PageContainer
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading message={"Loading Track"} />
        </PageContainer>
      ) : track !== null && workspace !== null ? (
        isTrackInWorkspace && userHasRole ? (
          <>
            <Banner
              bannerArr={[
                {
                  imageData: {
                    imageURL:
                      "https://www.topmarkfunding.com/wp-content/uploads/2019/10/what-is-a-semi-truck.jpg",
                  },
                  textData: {
                    title: "Intro to Wide Turns",
                    subTitle: `${track.roleName} Module`,
                  },
                },
              ]}
            />
            <TextOverview
              overviewText={{
                title: `${formatRoleName(track.roleName)} Learning Track`,
                description:
                  "Welcome to the track home, where you can view statistical data " +
                  "that measures your performance. Remember, performance is based on completing " +
                  "required readings, quizzes, and earning passing grade on section content.",
              }}
            />
            <StatsOverview
              statsTitle={"Track Stats"}
              sliders={trackStats ? parseTrackSliders(trackStats) : []}
              stats={trackStats ? parseTrackStats(trackStats) : []}
            />
            {!activityLoading && recentActivity ? (
              <RecentActivity
                title={"Quiz Activity"}
                activity={(recentActivity || []) as IActivityCard[]}
              />
            ) : null}
            <Divider
              title={"Select a Module to View"}
              description={
                "Below are all of the modules that are required, as per your organization's " +
                "role requirements, or any that you have chosen to track. "
              }
              infoCard={null}
            />
            <SearchControls
              inputValue={moduleData.searchTerm}
              onSearchInput={(searchTerm) => {
                dispatch(setModuleSearchTerm(searchTerm));
              }}
              onSearch={() => {
                if (firebaseHelper && contentfulHelper && workspace)
                  dispatch(
                    filterModulesBySearch({
                      contentfulClient: contentfulHelper,
                    })
                  );
              }}
              sorts={{
                title:
                  baseSorts.find((sort) => sort.id === moduleData.itemSortID)
                    ?.displayName || "",
                items: baseSorts.map((item) => ({
                  text: item.displayName,
                  onClick: (e: SyntheticEvent) => {
                    if (firebaseHelper) dispatch(sortModules(item.id));
                  },
                })),
              }}
              filters={{
                title:
                  contentFilters.find(
                    (filter) => filter.id === moduleData.itemFilterID
                  )?.displayName || "",
                items: contentFilters.map((item) => ({
                  text: item.displayName,
                  onClick: (e: SyntheticEvent) => {
                    if (contentfulHelper)
                      dispatch(
                        filterModules({
                          contentfulClient: contentfulHelper,
                          filterId: item.id,
                        })
                      );
                  },
                })),
              }}
            />
            {(!moduleStats || moduleStats?.length === 0) &&
            !moduleData.isLoading &&
            !moduleStatsLoading ? (
              <SearchResults
                message={
                  moduleData.searchTerm === ""
                    ? "It Appears You Have not Made a Search Yet"
                    : `0 Results Found For ${moduleData.searchTerm}`
                }
                resultContent={[
                  {
                    title: "Most Popular",
                    description:
                      "View all of the most trending module content in your track.",
                    onButtonClick: (e) => {
                      e.preventDefault();
                      if (contentfulHelper) {
                        // reset search term
                        // set sort and filter back to default
                        dispatch(setModuleSearchTerm(""));
                        dispatch(sortModules(sortIds.POPULAR));
                        dispatch(
                          filterModules({
                            contentfulClient: contentfulHelper,
                            filterId: contentFiltersIds.ALL,
                          })
                        );
                      }
                    },
                    buttonText: "Reset",
                    redirectLink: "",
                  },
                ]}
              />
            ) : (
              <ContentGrid
                content={moduleStats ? moduleStats : []}
                isLoading={moduleData.isLoading || moduleStatsLoading}
                itemLimitReached={moduleData.itemLimitReached}
                loadMoreClick={() => {
                  if (contentfulHelper && firebaseHelper && workspace)
                    dispatch(
                      loadMoreModules({
                        contentfulClient: contentfulHelper,
                      })
                    );
                }}
              />
            )}
          </>
        ) : (
          <h2>
            Error: Track with ID {trackId} unavailable for viewing (you don't
            have access).
          </h2>
        )
      ) : (
        <h2>Error: Track with ID {trackId} unavailable.</h2>
      )}
    </>
  );
};
