import { IOrganizationContent } from "../types";
import { useFirebaseContext } from "../../providers/FirebaseProvider";
import { useEffect, useState } from "react";
import {
  fetchMemberImages,
  fetchModulesByOrganization,
  fetchOrganizationById,
  generateModuleStats,
} from "../../utils";
import { ContentfulHelper } from "../../clients/contentful";
import { IOrganization } from "../../config";
import { IProfileTLDRCard } from "../../../UIComponents/components";
import { IGenericIcon } from "../../../UIComponents/elements";
import { isFetchError } from "../../redux/reduxTypes";

export const useOrganization = (
  firebaseOrganizationId: string
): [IOrganizationContent | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  // organization state
  const [organization, setOrganization] = useState<IOrganizationContent | null>(
    null
  );
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    // fetch organization by id
    setLoadingStatus(true);
    if (firebaseOrganizationId !== "") {
      (async () => {
        let tags: string[] = [];
        const org = await fetchOrganizationById(
          firebaseOrganizationId,
          firebaseHelper
        );
        if (org !== null) {
          const contentfulClient = new ContentfulHelper(
            org?.externalSpaceID,
            process.env.REACT_APP_CDA_TOKEN || ""
          );
          const contentTags = await contentfulClient.sectionTags();
          // content tags
          if (contentTags.items.length > 0)
            tags = contentTags.items.map((tag) => tag.fields.tagline);
          // set state
          setOrganization({
            ...org,
            tags,
          });
        }
        setLoadingStatus(false);
      })();
    } else {
      setLoadingStatus(false);
      setOrganization(null);
    }
  }, [firebaseOrganizationId, firebaseHelper]);

  // returns organization data and loading flag
  return [organization, isLoading];
};

export const useOrganizationStats = (
  organization: IOrganization | null
): [IProfileTLDRCard | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  // stat state
  const [stats, setState] = useState<IProfileTLDRCard | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    // fetch organization stats
    setLoadingStatus(true);
    if (firebaseHelper !== null && organization !== null) {
      (async () => {
        // create contentful client
        const contentfulClient = new ContentfulHelper(
          organization.externalSpaceID,
          process.env.REACT_APP_CDA_TOKEN || ""
        );
        // fetch and parse modules related to organization
        const modules = await fetchModulesByOrganization(
          firebaseHelper,
          organization.id,
          0
        );
        if (!isFetchError(modules)) {
          // fetch contentful modules (necessary for quiz count)
          // generate stats to pull out data
          /*const contentfulModules = await Promise.all(modules.map(module => contentfulClient.getModuleById(module.externalModuleId)))
                    const allSections = contentfulModules
                        .map(module => module.fields.sections)
                        .filter(sections => sections !== undefined)
                        .flatMap(sections => sections as ISection[])*/
          const moduleStats = await Promise.all(
            modules.map((module) =>
              generateModuleStats(module, firebaseHelper, contentfulClient)
            )
          );
          // get quiz count
          //const quizSections = getQuizzableSections(allSections)
          const stats: IProfileTLDRCard = {
            title: "At A Glance",
            joinDate: new Date((organization.joinDate as any).seconds * 1000),
            quizCount:
              moduleStats.length === 0
                ? 0
                : moduleStats
                    .map((stats) => stats.quizCount)
                    .reduce((prev, curr) => prev + curr),
            userPointCount:
              moduleStats.length === 0
                ? 0
                : moduleStats
                    .map((stats) => stats.earnableUserPoints)
                    .reduce((prev, curr) => prev + curr),
            contentRating:
              moduleStats.length === 0
                ? undefined
                : moduleStats
                    .map((stats) => stats.userReviewRate || 0)
                    .reduce((prev, curr) => prev + curr),
          };
          setState(stats);
        }
        setLoadingStatus(false);
      })();
    }
  }, [organization, firebaseHelper]);

  return [stats, isLoading];
};

export const useMembershipImages = (
  firebaseOrganizations: IOrganization[] | null
): [IGenericIcon[][] | null, boolean] => {
  const firebaseHelper = useFirebaseContext();
  // membership state
  const [memberArr, setMemberArr] = useState<IGenericIcon[][] | null>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    // fetch member images
    if (
      firebaseOrganizations !== null &&
      firebaseOrganizations.length > 0 &&
      firebaseHelper !== null
    ) {
      setLoadingStatus(true);
      (async () => {
        const results = await Promise.all(
          firebaseOrganizations.map((org) =>
            fetchMemberImages(org.members, firebaseHelper)
          )
        );
        setMemberArr(results);
        setLoadingStatus(false);
      })();
    }
  }, [firebaseOrganizations, firebaseHelper]);

  // returns 2D array of membership images (in order of orgs) and loading flag
  return [memberArr, isLoading];
};
