import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AddCertificateParams,
  FetchError,
  FetchParams,
  FinishCertificateParams,
  ICompletionCertificate,
  IFirebaseHelper,
  IMember,
  IOrganization,
  IQuizSession,
  IRole,
  IUserData,
  RootState,
  validator,
} from "./internal";
import { IInvite } from "../../config/types";
import { daysToUnix } from "../../utils";

export const createAnInvite = createAsyncThunk(
  "userDataSlice/createAnInvite",
  async (
    params: {
      firebaseHelper: IFirebaseHelper;
      email: string;
      roleId: string;
    },
    thunkAPI
  ) => {
    const { firebaseHelper, email, roleId } = params;
    const store = thunkAPI.getState() as RootState;
    const activeMembership = store?.userData?.memberships?.find(
      (member) => member.id === store.workspace?.associatedMembership || ""
    );

    // check email address format
    if (!validator.isEmail(email))
      return thunkAPI.rejectWithValue({
        error: "Email invalid",
        message: `'${email}' is an invalid email, ensure correct format`,
      });
    if (store.workspace === null || !store.workspace.organization)
      return thunkAPI.rejectWithValue({
        error: "Workspace Inactive",
        message: "To send an invite, workspace must be active and available",
      });
    if (!store.workspace.associatedMembership)
      return thunkAPI.rejectWithValue({
        error: "Membership Inactive",
        message:
          "To send an invite, workspace must be active and associated membership must be available",
      });
    if (!store.userData?.memberships || !activeMembership)
      return thunkAPI.rejectWithValue({
        error: "User Membership Unavailable",
        message: "Cannot send an invite without user membership data loaded",
      });
    if (!activeMembership.isAdmin)
      return thunkAPI.rejectWithValue({
        error: "Missing Permissions",
        message: "Cannot create/send invite without admin-privileges",
      });

    // ensure invite not being duplicated
    const invites = await firebaseHelper
      .invites()
      .where("userEmail", "==", email)
      //.where("workspaceId", "==", store.workspace.organization.id)
      .get();
    if (invites.docs.length > 0) {
      for (const doc of invites.docs) {
        const invite = { ...doc.data(), id: doc.id } as IInvite;
        if (invite.workspaceId === store.workspace.organization.id)
          return thunkAPI.rejectWithValue({
            error: "Duplicate Invites",
            message: `It appears an invite associated with ${invite.userEmail} has already been sent`,
          });
      }
    }

    const inviteDoc = firebaseHelper.invites().doc();
    const roleDoc = firebaseHelper.roles().doc(roleId);
    const memberDoc = firebaseHelper
      .members()
      .doc(store.workspace.associatedMembership || "");
    const transaction = await firebaseHelper.firestore.runTransaction(
      async (trans) => {
        const [role, member] = await Promise.all([
          trans.get(roleDoc),
          trans.get(memberDoc),
        ]);

        if (!role.exists)
          return {
            success: false,
            error: "Role invalid",
            message: "Role assigned to invite does not exist in firestore",
          };

        // create invite
        const fiveDays = daysToUnix(5) + new Date().getTime();
        const invite: IInvite = {
          id: inviteDoc.id,
          userEmail: email,
          assignedRoleId: role.id,
          workspaceId: store?.workspace?.organization.id || "",
          inviteDate: new Date(),
          expirationDate: new Date(fiveDays),
        };
        const updatedMember: IMember = {
          ...activeMembership,
          createdInvites: [...activeMembership.createdInvites, inviteDoc.id],
        };
        trans.set(inviteDoc, invite);
        trans.set(memberDoc, updatedMember);

        return {
          success: true,
          updatedMembership: updatedMember,
          updatedInvite: invite,
        };
      }
    );

    // send out data to reducer
    const {
      success,
      updatedInvite,
      updatedMembership,
      message,
      error,
    } = transaction;
    if (success) return { updatedMembership };
    else return { message, error } as FetchError;
  }
);

export const addMembershipToUser = createAsyncThunk(
  "userDataSlice/addMembershipToUser",
  async (
    params: {
      firebaseHelper: IFirebaseHelper;
      organizationId: string;
      userId: string;
    },
    thunkAPI
  ) => {
    const { firebaseHelper, organizationId, userId } = params;
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { appData } = userData;
    // retrieve references to appropriate documents
    const userRef = firebaseHelper.users().doc(appData.id);
    const organizationRef = firebaseHelper.organizations().doc(organizationId);
    const membershipRef = firebaseHelper.members().doc();
    try {
      // create transaction
      const membershipTransaction = await firebaseHelper.firestore.runTransaction(
        async (transaction) => {
          // get existing organization data
          const orgDoc = await transaction.get(organizationRef);
          const organizationData = {
            ...orgDoc.data(),
            id: orgDoc.id,
          } as IOrganization;

          // get role data associated with organization, find default role
          const roleDocs = await Promise.all(
            organizationData.roles.map((roleId) =>
              transaction.get(firebaseHelper.roles().doc(roleId))
            )
          );
          const roles = roleDocs.map(
            (doc) => ({ ...doc.data(), id: doc.id } as IRole)
          );
          let defaultRole = roles.find((role) => role.isDefault);

          // throw error if default role doesn't exist
          if (defaultRole === undefined)
            throw {
              error: "Membership Role Error",
              message:
                "Organization does not specify default role for new users",
            };

          // create new membership object, add to collection
          const newMembership: IMember = {
            id: membershipRef.id,
            isOwner: false,
            isAdmin: false,
            registryDate: new Date(),
            profileImage: "",
            userPoints: 0,
            contentVisits: [],
            createdInvites: [],
            roles: [defaultRole.id],
            moduleReviews: [],
            // quizSessions: [],
            contentCertificates: [],
            likedContent: [],
          };
          transaction.set(membershipRef, newMembership);

          // update organization
          const updatedOrganization: IOrganization = {
            ...organizationData,
            // add new membership ID
            members: [...organizationData.members, membershipRef.id],
          };
          transaction.update(organizationRef, updatedOrganization);

          // update base user data
          const updatedUser: IUserData = {
            ...appData,
            memberships: [...appData.memberships, newMembership.id],
          };
          transaction.update(userRef, updatedUser);

          // return updated data
          return {
            updatedOrganization,
            newMembership,
            updatedUser,
          };
        }
      );
      return membershipTransaction;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchUserMemberships = createAsyncThunk(
  "userDataSlice/fetchUserMemberships",
  async (firebaseHelper: IFirebaseHelper, thunkAPI) => {
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { appData } = userData;
    // fetch associated user memberships
    const membershipDocs = await Promise.all(
      appData.memberships.map((memberId) =>
        firebaseHelper?.members().doc(memberId).get()
      )
    );
    const parsedMemberships = membershipDocs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as IMember)
    );
    // check for results (payload CAN return null as valid data)
    if (parsedMemberships.length > 0) return parsedMemberships;
    return null;
  }
);

export const fetchUserAppData = createAsyncThunk(
  "userDataSlice/fetchUserAppData",
  async ({ requestedEntityId, firebaseHelper }: FetchParams, thunkAPI) => {
    // fetch user app data
    const userDataDoc = await firebaseHelper
      ?.users()
      .doc(requestedEntityId)
      .get();
    // check results
    if (userDataDoc !== undefined && userDataDoc.exists)
      return { ...userDataDoc.data(), id: userDataDoc.id } as IUserData;
    // throw error if user app data doesn't exist
    return thunkAPI.rejectWithValue({
      error: "User Data fetching",
      message: "Failure to retrieve user app data",
    });
  }
);

export const addSiginLogToUser = createAsyncThunk(
  "userDataSlice/addSiginLogToUser",
  async (params: { firebaseHelper: IFirebaseHelper }, thunkAPI) => {
    const { firebaseHelper } = params;
    const userData = (thunkAPI.getState() as RootState).userData;
    if (!userData)
      return thunkAPI.rejectWithValue({
        error: "User Data",
        message:
          "User information must be fetched and initialized before selecting an organization",
      });
    const { appData } = userData;
    // create new user signin log
    try {
      // get ref to user doc in firebase
      const appUserDoc = firebaseHelper?.users().doc(appData.id);
      // run transaction, add sign in log, return new app data
      const updatedUserData = await firebaseHelper?.firestore.runTransaction(
        async (transaction) => {
          // setup new user data
          const newUserData: IUserData = { ...appData };
          newUserData.loginLog = [...newUserData.loginLog, new Date()];
          // update user data in firebase
          transaction.update(appUserDoc, newUserData);
          return newUserData;
        }
      );
      return updatedUserData;
    } catch (err) {
      // throw error that causes thunk to fire fail action
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const addCompletionCertificate = createAsyncThunk(
  "userDataSlice/addCompletionCertificate",
  async (params: AddCertificateParams, thunkAPI) => {
    const { firebaseHelper, quizId, sectionId, moduleId } = params;
    const { workspace, userData } = thunkAPI.getState() as RootState;

    // ensure workspace is selected
    if (workspace && userData) {
      // get active membership ID
      const membership = userData?.memberships?.find(
        (membership) => membership.id === workspace.associatedMembership
      );
      if (membership) {
        // run transaction
        const transaction = await firebaseHelper.firestore.runTransaction(
          async (transaction) => {
            const { id: memberId, ...oldMembership } = membership;
            // setup firebase docs
            const certificateDoc = firebaseHelper
              .completionCertificates()
              .doc();
            const memberDoc = firebaseHelper.members().doc(memberId);
            const sessionDoc = quizId
              ? firebaseHelper.quizSessions().doc()
              : undefined;
            // setup document data
            const newSession = {
              startDate: new Date(),
              externalQuizId: quizId || "",
              responses: [] as string[],
            } as IQuizSession;
            const newCertificate = {
              externalSectionId: sectionId,
              firebaseModuleId: moduleId,
              isComplete: false,
              startDate: new Date(),
              quizSessions: sessionDoc ? [sessionDoc.id] : null,
            } as ICompletionCertificate;
            const updatedMembership: IMember = {
              ...oldMembership,
              contentCertificates: [
                ...oldMembership.contentCertificates,
                certificateDoc.id,
              ],
            } as IMember;
            // create new session (if applicable)
            // create new certificate
            // update user data
            if (sessionDoc) transaction.set(sessionDoc, newSession);
            transaction.set(certificateDoc, newCertificate);
            transaction.update(memberDoc, updatedMembership);

            return {
              membershipId: membership.id,
              certificate: { ...newCertificate, id: certificateDoc.id },
            };
          }
        );
        // return certificate data
        return transaction;
      } else {
        return thunkAPI.rejectWithValue({
          error: "Membership Unavailable",
          message:
            "User membership associated with workspace cannot be retrieved",
        } as FetchError);
      }
    } else {
      return thunkAPI.rejectWithValue({
        error: "Workspace Unavailable",
        message: "Workspace must be selected to add a certificate",
      } as FetchError);
    }
  }
);

// called when a non-quizzable section, required, section needs to be marked as read
export const finishCompletionCertificate = createAsyncThunk(
  "userDataSlice/finishCompletionCertificate",
  async (params: FinishCertificateParams, thunkAPI) => {
    const { firebaseHelper, certificateId, pointsAwarded } = params;
    const { workspace, userData } = thunkAPI.getState() as RootState;
    if (workspace && userData) {
      // setup firebase doc
      const certificateRef = firebaseHelper
        .completionCertificates()
        .doc(certificateId);
      const certificateDoc = await certificateRef.get();
      // retrieve certificate data
      // update data in firebase
      const updatedData = {
        ...certificateDoc.data(),
        pointsAwarded,
        completionDate: new Date(),
        isComplete: true,
      } as ICompletionCertificate;
      await certificateRef.update(updatedData);
    } else {
      return thunkAPI.rejectWithValue({
        error: "Workspace Unavailable",
        message: "Workspace must be selected to finish a certificate",
      } as FetchError);
    }
  }
);
