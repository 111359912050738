// auth provider type
import { User } from "@firebase/auth-types";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
} from "@firebase/firestore-types";

export interface ParentDocRef {
  parentDocRef: DocumentReference<DocumentData>;
}

export interface IAuthProvider {
  user: User;
  appData: IUserData;
  memberships: IMember[];
}

export interface IUserCreationArgs {
  username: string;
  password: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
}

export interface IAuthResult {
  success: boolean;
  error?: string;
}

// helper class types
export interface IFirebaseHelper {
  // properties
  readonly firestore: firebase.firestore.Firestore;
  readonly auth: firebase.auth.Auth;
  readonly functions: firebase.functions.Functions;
  // methods
  init: () => Promise<void>;
  moduleReviews: () => CollectionReference;
  organizations: () => CollectionReference;
  modules: () => CollectionReference;
  roles: () => CollectionReference;
  permissions: () => CollectionReference;
  contentVisits: () => CollectionReference;
  users: () => CollectionReference;
  invites: () => CollectionReference;
  members: () => CollectionReference;
  responses: () => CollectionReference;
  quizSessions: () => CollectionReference;
  completionCertificates: () => CollectionReference;
  standardSignIn: (email: string, password: string) => Promise<IAuthResult>;
  createNewUser: (args: IUserCreationArgs) => Promise<IAuthResult>;
}

// permission system types
export interface IPermission {
  id: string;
  permissionName: string;
}

export interface IRole {
  id: string;
  roleName: string;
  isDefault: boolean;
  permissions: string[]; // object ids
}

// content system types
export interface IModule {
  id: string;
  memberCreatorId: string;
  externalModuleId: string;
  associatedRoles: string[]; // object ids
  isSample: boolean;
  isVisible: boolean;
  addedOn: Date;
  viewCount: number;
}

export interface IModuleReview {
  id: string;
  moduleId: string;
  reviewHeading: string;
  reviewBody: string;
  gradeRating:
    | "A"
    | "B"
    | "C"
    | "D"
    | "F"
    | "-A"
    | "-B"
    | "-C"
    | "-D"
    | "+A"
    | "+B"
    | "+C"
    | "+D";
  date: Date;
}

export interface IContentVisit {
  id: string;
  moduleId: string;
  visitDate: Date;
  externalSectionId?: string;
  externalModuleId?: string;
}

// organization / membership system types
export interface IOrganization {
  id: string;
  name: string;
  description: string;
  isPublic: boolean;
  allowRequest: boolean;
  requireInvite: boolean;
  logo: string;
  joinDate: Date;
  externalSpaceID: string;
  members: string[]; // object ids
  roles: string[]; // object ids
  modules: string[]; // object ids
}

export interface IMember {
  id: string;
  isOwner: boolean;
  isAdmin: boolean;
  registryDate: Date;
  membershipEndDate?: Date;
  contentAccessToken?: string;
  profileImage: string;
  userPoints: number;
  contentVisits: string[]; // object ids
  createdInvites: string[]; // object ids
  roles: string[]; // object ids
  moduleReviews: string[]; // object ids
  contentCertificates: string[]; // object ids
  likedContent: ILikedContent[]; // embedded objects
}

// user system types
export interface IUserData {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  signupDate: Date;
  memberships: string[]; // object ids
  loginLog: Date[]; // embedded objects
}

export interface IInvite {
  id: string;
  userEmail: string;
  userReceiverId?: string;
  assignedRoleId: string;
  workspaceId: string;
  acceptedDate?: Date;
  inviteDate: Date;
  expirationDate: Date;
}

export interface ILikedContent {
  id: string;
  likeDate: Date;
  like: boolean;
  contentType: "content" | "section";
  externalSectionId?: string;
  externalModuleId?: string;
}

export interface ILoginLog {
  logDate: Date;
}

// quiz system types
export interface ICompletionCertificate {
  id: string;
  isComplete: boolean;
  startDate: Date;
  externalSectionId: string;
  firebaseModuleId: string;
  pointsAwarded?: number;
  completionDate?: Date;
  quizSessions?: string[]; // object ids
}

export interface IQuizSession {
  id: string;
  startDate: Date;
  endDate?: Date;
  //passingGrade: number;
  externalQuizId: string;
  responses: string[]; // object ids
}

export interface IResponse {
  id: string;
  responseDate: Date;
  isCorrect: boolean;
  questionText: string;
  answerText: string;
  externalQuestionId: string;
  externalAnswerId: string;
}

// document types
export enum CollectionTypes {
  DEV_ONLY = "devOnlyAuthIDs",
  MODULE_REVIEW = "moduleReviews",
  ORGANIZATION = "organizations",
  MODULE = "modules",
  ROLE = "roles",
  PERMISSION = "rolePermissions",
  CONTENT_VISIT = "contentVisit",
  USER = "users",
  INVITE = "invites",
  MEMBER = "members",
  RESPONSE = "responses",
  QUIZ_SESSION = "quizSessions",
}
