export function trimInput<T>(input: T): T {
  // get object keys, loop through object, condense each value
  const inputAsArr = Object.entries(input).map((item) => {
    const [key, val] = item;
    if (val && val instanceof String) return [key, val.trim()];
    return item;
  });
  return Object.fromEntries(inputAsArr);
}

export function handleInput<T>(
  newValue: string,
  stateProp: string,
  setInputState: React.Dispatch<React.SetStateAction<T>>
): void {
  const key = stateProp as keyof T;
  setInputState((newState: T) => ({
    ...newState,
    [key]: newValue,
  }));
}
