import React from "react";
import ReactDOM from "react-dom";
import AppTheme from "./boilerplate/providers/ThemeProvider";
import ReduxStore from "./boilerplate/providers/StoreProvider";
import AppRoutes from "./boilerplate/providers/RouterProvider";
import AuthConfig from "./boilerplate/providers/AuthProvider";
import FirebaseProvider from "./boilerplate/providers/FirebaseProvider";
import ContentfulProvider from "./boilerplate/providers/ContentfulProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./boilerplate/providers/StoreProvider";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ReduxStore>
        <PersistGate loading={null} persistor={persistor}>
          <ContentfulProvider>
            <FirebaseProvider>
              <AuthConfig>
                <AppTheme>
                  <AppRoutes />
                </AppTheme>
              </AuthConfig>
            </FirebaseProvider>
          </ContentfulProvider>
        </PersistGate>
      </ReduxStore>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
