import React, { useState } from "react";
import { BannerBody, SolidBoxButton } from "../../elements";
import { BannerControls, BannerCardHeader, IBanner } from "../../components";
import styled from "styled-components";
import { BannerProps } from "./types";

const TagInfo = styled.div`
  display: flex;
  color: white;
  justify-content: flex-end;
  align-items: center;
`;
const TagIcon = styled.div`
  margin-right: 0.5rem;
`;
const Tag = styled.p`
  margin: 0;
  font-size: 1.2em;
`;

export const Banner = (props: BannerProps) => {
  const [bannerIndex, setBannerIndex] = useState<number>(0);
  const currentBanner = props.bannerArr[bannerIndex];

  const handleControlClick = (incrementValue: number): void => {
    let potentialValue = bannerIndex + incrementValue;
    // user is going backwards (out of bounds)
    if (potentialValue < 0) potentialValue = props.bannerArr.length - 1;
    // user is going forwards (out of bounds)
    else if (potentialValue >= props.bannerArr.length) potentialValue = 0;
    // otherwise value is in bounds
    setBannerIndex(potentialValue);
  };

  return (
    <BannerBody {...currentBanner.imageData}>
      <BannerControls
        clickRight={() => handleControlClick(1)}
        clickLeft={() => handleControlClick(-1)}
        staticOnlyControls={props.bannerArr.length <= 1}
      >
        <div style={{ textAlign: "right" }}>
          <TagInfo>
            <TagIcon>{currentBanner.textData.tagIcon}</TagIcon>
            <Tag>{currentBanner.textData.tagLine}</Tag>
          </TagInfo>
          <BannerCardHeader
            {...currentBanner.textData}
            style={{ marginBottom: "1rem" }}
          />
          {currentBanner.buttonData !== undefined ? (
            <SolidBoxButton {...currentBanner.buttonData} />
          ) : null}
        </div>
      </BannerControls>
    </BannerBody>
  );
};
