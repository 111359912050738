import {
  ICompletionCertificate,
  IFirebaseHelper,
  IModule,
  IModuleReview,
} from "../config/types/firebaseTypes";
import { IContentfulHelper } from "../config/types/contentfulTypes";
import { ModuleStats } from "./types";
import {
  calcTimeToComplete,
  calcUserPointsBySections,
  fetchOrganizationById,
  filterCertificatesByModule,
  getQuizzableSections,
  getRequiredSections,
} from "./";
import {
  IModule as IContentfulModule,
  IQuiz,
  IQuizQuestion,
} from "../../../@types/generated/contentful";
import { FetchError, isFetchError } from "../redux/reduxTypes";

export const fetchModuleById = async (
  firebaseHelper: IFirebaseHelper,
  moduleId: string
): Promise<IModule | null> => {
  const moduleDoc = await firebaseHelper.modules().doc(moduleId).get();
  if (!moduleDoc.exists) return null;
  return { ...moduleDoc.data(), id: moduleDoc.id } as IModule;
};

export const generateModuleStats = async (
  module: IModule,
  firebaseHelper: IFirebaseHelper,
  contentfulClient: IContentfulHelper
): Promise<ModuleStats> => {
  // fetch module from contentful
  const moduleContent = await contentfulClient.getModuleById(
    module.externalModuleId
  );
  // check if sections are available
  if (moduleContent.fields?.sections) {
    // find all of the sections with quizzes
    const allSections = moduleContent.fields.sections;
    const requiredSections = getRequiredSections(allSections);
    const quizzableSections = getQuizzableSections(allSections);
    const estimateTime = calcTimeToComplete(allSections);
    // set stat data
    return {
      title: moduleContent.fields.moduleHeading,
      subTitle: `${/*role.roleName*/ "FIX LATER"} Module`,
      summary: moduleContent.fields.contentSummary,
      isLocked: false,
      quizCount: quizzableSections.length,
      questionCount: quizzableSections
        .flatMap((section) =>
          section.fields.quizzes
            ? section.fields.quizzes[0].fields.quizQuestions
            : undefined
        )
        .filter((question) => question !== undefined)
        .map((question) => question as IQuizQuestion).length,
      sectionCount: allSections.length,
      earnableUserPoints: calcUserPointsBySections(allSections),
      timeToComplete: estimateTime.time,
      TTCUnits: estimateTime.units,
      isRequired: isModuleRequired(moduleContent),
      userReviewRate: await getModuleReviewRating(
        firebaseHelper,
        module.externalModuleId
      ),
    };
  }
  return {
    title: "",
    subTitle: "",
    summary: "",
    isLocked: false,
    quizCount: 0,
    questionCount: 0,
    sectionCount: 0,
    earnableUserPoints: 0,
    timeToComplete: 0,
    TTCUnits: "hrs",
    isRequired: false,
  };
};

export const isModuleRequired = (
  contentfulModule: IContentfulModule
): boolean => {
  if (contentfulModule.fields.sections) {
    for (const section of contentfulModule.fields.sections) {
      if (section.fields.isRequired) return true;
    }
  }
  return false;
};

export const isModuleComplete = (
  contentfulModule: IContentfulModule,
  moduleCertificates: ICompletionCertificate[]
): boolean => {
  // get list of required section ids
  const requiredSections =
    contentfulModule.fields.sections
      ?.filter((section) => section.fields.isRequired)
      .map((section) => section.sys.id).length || 0;
  // get number of completed certificates
  const completedCertificates = moduleCertificates.filter(
    (cert) => cert.completionDate
  ).length;
  // module is completed if certificate count is greater than required section count
  return completedCertificates >= requiredSections;
};

export const getModuleReviewRating = async (
  firebaseHelper: IFirebaseHelper,
  firebaseModuleId: string
): Promise<number | undefined> => {
  const reviewDocs = await firebaseHelper
    .moduleReviews()
    .where("moduleId", "==", firebaseModuleId)
    .get();
  if (reviewDocs.docs.length <= 0) {
    const parsedReviews = reviewDocs.docs
      .map((doc) => ({ ...doc.data(), id: doc.id } as IModuleReview))
      .map((review) => {
        const { gradeRating } = review;
        switch (gradeRating) {
          case "+A":
            return 100 as number;
          case "A":
            return 95 as number;
          case "-A":
            return 90 as number;
          case "+B":
            return 89 as number;
          case "B":
            return 85 as number;
          case "-B":
            return 80 as number;
          case "+C":
            return 79 as number;
          case "C":
            return 75 as number;
          case "-C":
            return 70 as number;
          case "+D":
            return 69 as number;
          case "D":
            return 65 as number;
          case "-D":
            return 60 as number;
          case "F":
            return 50 as number;
        }
      });
    // reduce and average reviews
    return parsedReviews.length <= 0
      ? undefined
      : Math.floor(
          parsedReviews.reduce((prev, current) => prev + current) /
            parsedReviews.length
        );
  }
  return undefined;
};

export const fetchModulesByRole = async (
  firebaseHelper: IFirebaseHelper,
  roleId: string
): Promise<IModule[]> => {
  const moduleDocs = await firebaseHelper
    .modules()
    .where("associatedRoles", "array-contains", roleId)
    .get();
  return moduleDocs.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as IModule)
  );
};

export const fetchModulesByOrganization = async (
  firebaseHelper: IFirebaseHelper,
  organizationId: string,
  startAt: number,
  limit?: number
): Promise<IModule[] | FetchError> => {
  try {
    // fetch organization
    const organization = await fetchOrganizationById(
      organizationId,
      firebaseHelper
    );
    if (organization === null)
      return {
        error: "Organization Error",
        message: `Organization with id ${organizationId} unavailable`,
      };
    // check limit/start at boundaries
    const moduleCount = organization.modules.length;
    if (moduleCount === 0) return [];
    else if (startAt >= moduleCount)
      return {
        error: "Startin Index Error",
        message: `Start at index ${startAt} greater than module count`,
      };
    else if (limit && limit < 0)
      return {
        error: "Limit Items Error",
        message: `Item limiter must be greater than 0`,
      };
    // fetch all modules based on organization
    const moduleDocs = await Promise.all(
      organization?.modules.map((moduleId) =>
        firebaseHelper.modules().doc(moduleId).get()
      )
    );
    const modules = moduleDocs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as IModule)
    );
    // return modules
    return modules.slice(startAt, limit ? startAt + limit : modules.length);
  } catch (err) {
    return {
      error: err.error,
      message: err.message,
    };
  }
};

// todo
export const searchExistingModules = async (
  modules: IModule[],
  contentfulClient: IContentfulHelper,
  searchTerm: string
): Promise<IModule[]> => {
  const normalizedTerm = searchTerm.toUpperCase();
  const contentfulModules = await Promise.all(
    modules.map((module) =>
      contentfulClient.getModuleById(module.externalModuleId)
    )
  );
  // filter out modules without keyword matches
  const searchResultIds = contentfulModules
    .filter((module) => {
      const {
        contentSummary,
        moduleHeading,
        //moduleOverview,
        moduleTags,
      } = module.fields;
      // check against module content
      const tags = moduleTags?.map((tag) => tag.fields.tagline.toUpperCase());
      if (tags?.includes(normalizedTerm)) return true;
      else if (contentSummary.toUpperCase().includes(normalizedTerm))
        return true;
      else if (moduleHeading.toUpperCase().includes(normalizedTerm))
        return true;
      // filter out since no match
      return false;
    })
    .map((module) => module.sys.id);
  return modules.filter((module) =>
    searchResultIds.includes(module.externalModuleId)
  );
};

export const getCompletedModules = (
  modules: IContentfulModule[],
  membershipCertificates: ICompletionCertificate[]
): IContentfulModule[] =>
  modules.filter((module) =>
    isModuleComplete(
      module,
      filterCertificatesByModule(module, membershipCertificates || [])
    )
  );

export const eliminateModuleRepeats = (modules: IModule[]): IModule[] =>
  modules.filter((module, index, array) => array.indexOf(module) === index);
